<form [formGroup]="periodForm">
  <div class="container-fluid">

    <div class="row period-container justify-content-end">
      <button mat-icon-button (click)="periodPrevious()" class="previous">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="datePicker">
        <mat-form-field>
          <mat-label>From [YYYY-MM-DD]</mat-label>
          <input matInput [matDatepicker]="pickerstart" formControlName="start"
                 (dateChange)="onDateInputChange($event, true)">
          <mat-datepicker-toggle matSuffix [for]="pickerstart"></mat-datepicker-toggle>
          <mat-datepicker #pickerstart></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>To [YYYY-MM-DD]</mat-label>
          <input matInput [matDatepicker]="pickerstop" formControlName="stop"
                 (dateChange)="onDateInputChange($event, false)">
          <mat-datepicker-toggle matSuffix [for]="pickerstop"></mat-datepicker-toggle>
          <mat-datepicker #pickerstop></mat-datepicker>
        </mat-form-field>
      </div>
      <button mat-icon-button (click)="periodNext()" class="next">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>

    <div class="row predefined-periods justify-content-end">
      <button mat-button (click)="onPredefinedPeriodClicked(DurationMode.SIXMONTHS)"
              title="Last 6 months">
        Last 6 months
      </button>
      <button mat-button (click)="onPredefinedPeriodClicked(DurationMode.MONTH)"
              title="Last month">
        Last Month
      </button>
      <button mat-button (click)="onPredefinedPeriodClicked(DurationMode.WEEK)"
              title="Last week">
        Last Week
      </button>
      <button mat-button (click)="onPredefinedPeriodClicked(DurationMode.DAY)"
              title="From today 00h00m00 to now">
        Today
      </button>
    </div>

  </div>
</form>
