import { Injectable } from '@angular/core';
import {ArfangWsService} from './arfang-ws.service';
import {ArfangEvent, ArfangEventSummary} from '../../common/events/arfang-event-model';
import {ArfangObservation} from '../../pages/event/arfang-observation';
import {EventWsFilter} from '../../common/events-filter/filters/event-ws-filter';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventWsService {

  private readonly ENDPOINT: string = 'event';

  constructor(private arfangWsService: ArfangWsService) { }

  getEvents(filters: EventWsFilter): Promise<ArfangEventSummary[]> {
    return this.arfangWsService.get<ArfangEventSummary[]>(this.ENDPOINT, filters);
  }

  getEventsAsCsv(filters: EventWsFilter): Promise<HttpResponse<Blob>> {
    return this.arfangWsService.getBlob(this.ENDPOINT + '/csv', filters);
  }

  postObservation(eventID: number, observation: ArfangObservation): Promise<void> {
    return this.arfangWsService.post<void>(this.ENDPOINT + '/' + eventID + '/observation', observation);
  }

  getEventDetails(eventID: number): Promise<ArfangEvent> {
    return this.arfangWsService.get<ArfangEvent>(this.ENDPOINT + '/' + eventID, {});
  }

}
