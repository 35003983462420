import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {LngLatUtils} from 'src/app/pages/shot-detection/LngLatUtils';
import {DtSwMb} from 'src/app/pages/shot-detection/DtSwMb';
import {DtSwMbColors} from 'src/app/pages/shot-detection/DtSwMbColors';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {ExovaMapComponent} from 'src/app/common/exova-map/exova-map/exova-map.component';
import {Point} from 'mapbox-gl';
import {ShotDetectionWsService} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws.service';
import {ExovaColors} from 'src/app/pages/shot-detection/ExovaColors';
import {BallisticUtils} from 'src/app/pages/shot-detection/BallisticUtils';
import {
  FieldTrial,
  RealShot,
  ShotDetection,
  ShotDetectionDetail,
  Weapon
} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';
import {ShotDetectionsFilters} from 'src/app/pages/list/shot-detections-filters';
import {Subscription} from 'rxjs';
import {formatNumber} from '@angular/common';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'shot-detection',
  templateUrl: './shot-detection.component.html',
  styleUrls: ['./shot-detection.component.scss']
})
export class ShotDetectionComponent implements AfterViewInit {

  @ViewChild(ExovaMapComponent)
  exovaMap: ExovaMapComponent;

  shotDetectionId?: string;
  shotDetectionIndex = 0;
  skipNextQueryParamsChange = false;
  rawDataVisible = false;
  reportUrl?: SafeUrl;
  reportVisible = false;
  ballisticModelImage = '';
  ballisticModelImageVisible = false;
  swDoaImage = '';
  mbDoaImage = '';
  refDoaImage = '';
  micsImageVisible = false;
  micsImage = '';
  normalized10sWavUrl?: string;
  normalized10sWavForPlayingUrl?: string;
  rawFrameWavUrl?: string;
  rawFrameWavForPlayingUrl?: string;

  private shotDetectionsFilters: ShotDetectionsFilters;
  shotDetectionDetail: ShotDetectionDetail;
  private otherDevicesShotDetections: ShotDetection[];
  scopeEpochSecond = 0;
  private eventsLoadedSinceMapReloaded = 0;
  private loadingEvent = false;
  currentView: 'SAFE_INFO' | 'SHOOTER_TRAJ' | 'NETWORK' = 'NETWORK';

  private subscriptions: Subscription[] = [];

  constructor(private shotDetectionWsService: ShotDetectionWsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private configurationService: ConfigurationService,
              private elementRef: ElementRef,
              private domSanitizer: DomSanitizer) {
  }

  async ngAfterViewInit(): Promise<void> {
    const filtersData = await this.shotDetectionWsService.getFiltersData();
    ExovaColors.addCssVariablesToHtmlElement(this.elementRef.nativeElement);
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(queryParams => {
      if (this.skipNextQueryParamsChange) {
        this.skipNextQueryParamsChange = false;
        return;
      }
      this.shotDetectionsFilters = new ShotDetectionsFilters(filtersData);
      this.shotDetectionsFilters.loadFromQueryParams(queryParams);
      this.shotDetectionId = queryParams.shotDetectionId;
      if (queryParams.shotDetectionIndex) {
        this.shotDetectionIndex = parseInt(queryParams.shotDetectionIndex, 10);
      } else {
        this.shotDetectionIndex = 0;
      }
      if (queryParams.view === 'SAFE_INFO' || queryParams.view === 'SHOOTER_TRAJ' || queryParams.view === 'NETWORK') {
        this.currentView = queryParams.view;
      } else {
        this.currentView = 'SAFE_INFO';
      }
      this.loadEvent();
    }));
  }

  previousEvent() {
    if (this.loadingEvent) {
      return;
    }
    this.shotDetectionIndex--;
    if (this.shotDetectionIndex < 0) {
      this.shotDetectionIndex = this.shotDetectionDetail.maxResults - 1;
    }
    this.navigateToEventIndex();
  }

  nextEvent() {
    if (this.loadingEvent) {
      return;
    }
    this.shotDetectionIndex++;
    if (this.shotDetectionIndex >= this.shotDetectionDetail.maxResults) {
      this.shotDetectionIndex = 0;
    }
    this.navigateToEventIndex();
  }

  private navigateToEventIndex() {
    this.shotDetectionId = undefined;
    const queryParams = {
      ...this.activatedRoute.snapshot.queryParams
    };
    delete queryParams.shotDetectionId;
    queryParams.shotDetectionIndex = this.shotDetectionIndex;
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams,
      replaceUrl: true
    });
  }

  viewSelected(view: 'SAFE_INFO' | 'SHOOTER_TRAJ' | 'NETWORK') {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {view},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private async loadEvent() {
    this.loadingEvent = true;
    try {
      this.exovaMap.clearMap();
      this.eventsLoadedSinceMapReloaded++;
      if (this.eventsLoadedSinceMapReloaded > 10) {
        this.eventsLoadedSinceMapReloaded = 0;
        this.exovaMap.reloadMap();
      }
      const detailParams = {
        index: this.shotDetectionIndex,
        ...this.shotDetectionsFilters.buildQueryParams()
      };
      if (this.shotDetectionId !== undefined) {
        detailParams.id = this.shotDetectionId;
      }
      this.otherDevicesShotDetections = [];
      this.shotDetectionDetail = await this.shotDetectionWsService.getShotDetection(detailParams);
      this.otherDevicesShotDetections = await this.shotDetectionWsService.getShotDetectionsOfOtherDevices(this.shotDetectionDetail.shotDetection.id);
      if (this.shotDetectionId !== this.shotDetectionDetail.shotDetection.id
        || this.shotDetectionIndex !== this.shotDetectionDetail.index) {
        this.skipNextQueryParamsChange = true;
        this.router.navigate(['.'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge',
          queryParams: {
            shotDetectionId: this.shotDetectionDetail.shotDetection.id,
            shotDetectionIndex: this.shotDetectionDetail.index
          },
          replaceUrl: true
        });
      }
      this.shotDetectionId = this.shotDetectionDetail.shotDetection.id;
      this.shotDetectionIndex = this.shotDetectionDetail.index;
      this.reportUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.configurationService.configuration.serverUrl}/shot-detection/report?shotDetectionId=${this.shotDetection.id}`);
      this.ballisticModelImage = `${this.configurationService.configuration.serverUrl}/ballistic-model/${this.fieldTrial.weapon.ballisticModel.shortName}/image`;
      this.swDoaImage = `${this.configurationService.configuration.serverUrl}/shot-detection/doaImage?shotDetectionId=${this.shotDetection.id}&doaType=sw`;
      this.mbDoaImage = `${this.configurationService.configuration.serverUrl}/shot-detection/doaImage?shotDetectionId=${this.shotDetection.id}&doaType=mb`;
      this.refDoaImage = `${this.configurationService.configuration.serverUrl}/shot-detection/doaImage?shotDetectionId=${this.shotDetection.id}&doaType=ref`;
      this.micsImage = `${this.configurationService.configuration.serverUrl}/shot-detection/micsImage?shotDetectionId=${this.shotDetection.id}`;
      this.normalized10sWavUrl = `${this.configurationService.configuration.serverUrl}/shot-detection/normalized10sWav?shotDetectionId=${this.shotDetection.id}`;
      this.normalized10sWavForPlayingUrl = `${this.configurationService.configuration.serverUrl}/shot-detection/normalized10sWavForPlaying?shotDetectionId=${this.shotDetection.id}`;
      this.rawFrameWavUrl = `${this.configurationService.configuration.serverUrl}/shot-detection/rawFrameWav?shotDetectionId=${this.shotDetection.id}`;
      this.rawFrameWavForPlayingUrl = `${this.configurationService.configuration.serverUrl}/shot-detection/rawFrameWavForPlaying?shotDetectionId=${this.shotDetection.id}`;
      this.scopeEpochSecond = Math.round(Date.parse(this.shotDetection.shotInstant) / 1000);
      await this.exovaMap.waitMapLoad();
      this.exovaMap.clearMap();
      this.drawSensor(this.shotDetection, true);
      if (this.currentView === 'SAFE_INFO') {
        this.drawEscapeImage();
        this.drawEscapeArrow();
      }
      if (this.currentView === 'NETWORK') {
        this.drawNetworkDangerImage();
        this.drawOtherDevicesSensors();
      }
      if (this.currentView === 'SHOOTER_TRAJ') {
        this.drawShooterAndTraj();
      }
      this.drawScenarioData();
    } catch (error) {
      console.log(error);
      this.shotDetectionDetail = undefined;
    }
    this.loadingEvent = false;
  }

  drawShooterAndTraj() {
    const sensorLngLat: [number, number] = [this.shotDetection.sensorLng, this.shotDetection.sensorLat];
    this.exovaMap.addMarker(sensorLngLat, {
      image: 'direction-of-arrival-foa.svg',
      color: ExovaColors.ref,
      width: 70,
      rotation: this.shotDetection.refDoa + this.shotDetection.sensorHeading,
      offset: new Point(0, -80),
    });
    this.exovaMap.addMarker(sensorLngLat, {
      image: 'direction-of-arrival-mb.svg',
      color: ExovaColors.mb,
      width: 70,
      rotation: this.shotDetection.mbDoa + this.shotDetection.sensorHeading,
      offset: new Point(0, -80),
    });
    this.exovaMap.addMarker(sensorLngLat, {
      image: 'direction-of-arrival-sw.svg',
      color: ExovaColors.sw,
      width: 70,
      rotation: this.shotDetection.swDoa + this.shotDetection.sensorHeading,
      offset: new Point(0, -80),
    });
    if (this.shotDetection.shooterAngle !== null) {
      this.exovaMap.addMarker(sensorLngLat, {
        image: 'direction-of-arrival-poo.svg',
        color: ExovaColors.poo,
        width: 70,
        rotation: this.shotDetection.shooterAngle + this.shotDetection.sensorHeading,
        offset: new Point(0, -160),
      });
    }
    if (this.shotDetection.shooterDistance !== null && Number.isFinite(this.shotDetection.shooterDistance)
      && this.shotDetection.shooterToDangerAngle !== null && Number.isFinite(this.shotDetection.shooterToDangerAngle)) {
      const detectedShooterLngLAt = LngLatUtils.lngLatAtAngleAndDistance(
        sensorLngLat,
        this.shotDetection.shooterAngle + this.shotDetection.sensorHeading,
        this.shotDetection.shooterDistance);
      const trajectoryEndLngLat = LngLatUtils.lngLatAtAngleAndDistance(
        detectedShooterLngLAt,
        this.shotDetection.shooterToTargetAngle + this.shotDetection.sensorHeading,
        1000);
      const detectedFermatLngLat = LngLatUtils.lngLatAtAngleAndDistance(
        detectedShooterLngLAt,
        this.shotDetection.shooterToFermatAngle + this.shotDetection.sensorHeading,
        this.shotDetection.shooterToFermatDistance);
      const detectedDangerLngLat = LngLatUtils.lngLatAtAngleAndDistance(
        detectedShooterLngLAt,
        this.shotDetection.shooterToTargetAngle + this.shotDetection.sensorHeading,
        this.shotDetection.shooterToDangerDistance);
      this.exovaMap.drawLine([sensorLngLat, detectedShooterLngLAt], {
        color: ExovaColors.sensor,
        width: 1.5,
        linePaint: {'line-dasharray': [4, 4]}
      });
      this.exovaMap.drawLine([detectedShooterLngLAt, trajectoryEndLngLat], {
        color: ExovaColors.detectedShot,
        linePaint: {'line-dasharray': [4, 4]},
        hoverText: this.shotDetection.stationName + '<br>Detected shot trajectory'
      });
      this.exovaMap.addMarker(detectedFermatLngLat, {
        image: 'shooter.svg',
        color: ExovaColors.detectedShot,
        hoverHtml: this.shotDetection.stationName + '<br>Detected Fermat point'
      });
      this.exovaMap.drawLine([sensorLngLat, detectedFermatLngLat], {
        color: ExovaColors.sensor,
        width: 1.5,
        linePaint: {'line-dasharray': [4, 4]},
      });
      this.exovaMap.addMarker(detectedShooterLngLAt, {
        image: 'shooter.svg',
        color: ExovaColors.shooter,
        hoverHtml: this.shotDetection.stationName + '<br>Detected shooter position'
      });
      this.exovaMap.drawLine([sensorLngLat, detectedDangerLngLat], {
        color: ExovaColors.sensor,
        hoverText: this.shotDetection.stationName + '<br>Detected Danger'
      });
      this.exovaMap.addMarker(detectedDangerLngLat, {
        image: 'target.svg',
        color: ExovaColors.detectedShot,
        hoverHtml: this.shotDetection.stationName + '<br>Detected danger point',
      });
    }
  }

  drawEscapeImage() {
    const imageUrl = this.configurationService.configuration.serverUrl
      + '/shot-detection/escapeZone?shotDetectionId='
      + this.shotDetectionId;
    this.exovaMap.drawImage(imageUrl);
  }

  drawEscapeArrow() {
    if (this.shotDetection.safeAngle !== null) {
      this.exovaMap.addMarker([this.shotDetection.sensorLng, this.shotDetection.sensorLat], {
        image: 'arrow.svg',
        color: ExovaColors.safe,
        width: 140,
        rotation: this.shotDetection.refDoa + this.shotDetection.sensorHeading + 180,
        offset: new Point(0, -200),
        hoverHtml: this.shotDetection.stationName + '<br>Safe direction'
      });
    }
  }

  drawNetworkDangerImage() {
    const imageUrl = this.configurationService.configuration.serverUrl
      + '/shot-detection/networkDangerZone?shotDetectionId='
      + this.shotDetectionId;
    this.exovaMap.drawImage(imageUrl);
  }

  drawOtherDevicesSensors() {
    this.otherDevicesShotDetections.forEach(sd => {
      this.drawSensor(sd, false);
    });
  }

  drawSensor(sd: ShotDetection, main: boolean) {
    this.exovaMap.addMarker([sd.sensorLng, sd.sensorLat], {
      image: sd.stationName === 'TETRA' ? 'eagle.png' : 'an16.png',
      coloredImage: true,
      width: main ? 55 : 35,
      rotation: sd.sensorHeading,
      hoverHtml: sd.stationName,
    });
  }

  drawScenarioData() {
    const realShot = this.fieldTrial.realShot;
    if (this.currentView === 'SHOOTER_TRAJ') {
      this.drawDtSwMbLines(realShot);
    }
    this.drawRealShot(realShot);
  }

  drawRealShot(realShot: RealShot) {
    const shooterLngLat: [number, number] = [realShot.shooterLng, realShot.shooterLat];
    const targetLngLat: [number, number] = [realShot.targetLng, realShot.targetLat];
    this.exovaMap.addMarker(shooterLngLat, {
      image: 'shooter.svg',
      color: ExovaColors.realShot,
      hoverHtml: 'Shooter position from scenario data'
    });
    this.exovaMap.addMarker(targetLngLat, {
      image: 'target.svg',
      color: ExovaColors.realShot,
      hoverHtml: 'Target position from scenario data'
    });
    this.exovaMap.drawLine([shooterLngLat, targetLngLat], {
      color: ExovaColors.realShot,
      hoverText: 'Shot trajectory from scenario data'
    });
  }

  drawDtSwMbLines(realShot: RealShot) {
    const distanceForDt = 200;
    const ballisticModel = this.weapon.ballisticModel;
    const vForDt = BallisticUtils.v(
      ballisticModel.initialSpeed,
      ballisticModel.decelerationCoeff,
      ballisticModel.decelerationCoeffSubsonic,
      distanceForDt);
    const spaceBetweenLinesM = 10;
    const dtLines = DtSwMb.dtLines(realShot, vForDt, spaceBetweenLinesM);
    dtLines.forEach(dtLine => {
      const color = DtSwMbColors.dtColor(dtLine.dtSwMbMs);
      const hoverText = 'Δt (MB-SW)'
        + '<br>'
        + '(Calculated from scenario data)'
        + '<br>'
        + dtLine.dtSwMbMs + ' ms';
      this.exovaMap.drawLine(dtLine.lngLats, {color, hoverText, width: 2});
    });
  }

  viewOnSignalViewer() {
    this.router.navigate(['/livescope'], {
      queryParams: {
        station: this.shotDetection.stationName,
        dateTime: new Date(this.shotDetection.shotInstant).toISOString()
      }
    });
  }

  get shotDetection(): ShotDetection {
    return this.shotDetectionDetail?.shotDetection;
  }

  get fieldTrial(): FieldTrial {
    return this.shotDetection.fieldTrial;
  }

  get weapon(): Weapon {
    return this.shotDetection.fieldTrial.weapon;
  }

  get realShot(): RealShot {
    return this.shotDetection.fieldTrial.realShot;
  }

  get deltaSwMb(): number | null {
    if (this.shotDetection.deltaSwMb === null) {
      return null;
    }
    return this.shotDetection.deltaSwMb * 1000;
  }

  get expectedDeltaSwMb(): number | null {
    if (this.shotDetection.expectedDeltaSwMb === null) {
      return null;
    }
    return this.shotDetection.expectedDeltaSwMb * 1000;
  }

  get label(): string {
    const s = formatNumber(this.fieldTrial.s, 'en-us', '2.0-0');
    const v = formatNumber(this.fieldTrial.v, 'en-us', '2.0-0');
    const r = formatNumber(this.fieldTrial.r, 'en-us', '2.0-0');
    return `s${s}v${v}r${r}`;
  }

}
