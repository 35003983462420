export class ExovaColors {

  static readonly realShot = 'black';
  static readonly detectedShot = 'red';
  static readonly shooter = 'cyan';
  static readonly sw = 'red';
  static readonly mb = 'cyan';
  static readonly ref = 'white';
  static readonly poo = 'cyan';
  static readonly sensor = 'blue';
  static readonly safe = '#00ff00';

  static addCssVariablesToHtmlElement(htmlElement: HTMLElement): void {
    const colors = {
      realShot: ExovaColors.realShot,
      detectedShot: ExovaColors.detectedShot,
      shooter: ExovaColors.shooter,
      sw: ExovaColors.sw,
      mb: ExovaColors.mb,
      ref: ExovaColors.ref,
      poo: ExovaColors.poo,
      sensor: ExovaColors.sensor,
      safe: ExovaColors.safe,
    };
    for (const colorKey of Object.keys(colors)) {
      htmlElement.style.setProperty(`--${colorKey}-color`, colors[colorKey]);
    }
  }

}
