<app-nav-bar></app-nav-bar>

<div class="page-container container">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>My account</mat-card-title>
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <h4>Name</h4>
              {{authService.arfangToken.full_name}}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h4>Email</h4>
              {{authService.email}}
            </div>
          </div>
          <div class="row">
            <h4 class="col">Update password</h4>
          </div>
          <form [formGroup]="passwordForm" (ngSubmit)="save()">
            <div class="row">
              <mat-form-field class="col3">
                <mat-label>Current password</mat-label>
                <input matInput formControlName="currentPassword" type="password">
              </mat-form-field>
              <mat-form-field class="col3">
                <mat-label>New password</mat-label>
                <input matInput formControlName="newPassword1" type="password">
              </mat-form-field>
              <mat-form-field class="col3">
                <mat-label>Confirm password</mat-label>
                <input matInput formControlName="newPassword2" type="password">
              </mat-form-field>
              <div class="col3">
                <button mat-raised-button color="primary" type="submit">Update Password</button>
              </div>
            </div>
          </form>
        </div>
      </mat-card>
    </div>
  </div>
</div>
