<div class="shot-detections">
  <div class="shot-detection"
       *ngFor="let shotDetectionWithPosition of shotDetectionsWithPositions"
       [style.left]="(shotDetectionWithPosition.position * 100) + '%'"
       [pTooltip]="shotDetectionTooltipContent"
       tooltipPosition="bottom"
       (click)="shotDetectionClicked(shotDetectionWithPosition.shotDetection)"
  >
    V
    <ng-template #shotDetectionTooltipContent>
      <strong>Shot detection :</strong>
      <br>
      <br>
      {{shotDetectionWithPosition.shotDetection.shotInstant | appDateTime}}
      <br>
      {{shotDetectionWithPosition.shotDetection.shotType}},
      {{shotDetectionWithPosition.shotDetection.swMbInfo}}
      <br>
      <br>
      Scenario :
      <br>
      {{shotDetectionWithPosition.shotDetection.scenario}}
      <br>
      {{shotDetectionWithPosition.shotDetection.vignette}}
    </ng-template>
  </div>

</div>
<plotly-plot *ngIf="graphConfig"
             [data]="graphConfig.data"
             [layout]="graphConfig.layout"
             [config]="{displayModeBar: false, responsive: true}">
</plotly-plot>
<p class="activityWarning" *ngIf="this.secondsSinceLastActivity >= 10 && !infrasonicEventExampleMode && !staticMode">
  No data received since {{scopeService.displayElapsedTime(secondsSinceLastActivity)}} !
</p>
