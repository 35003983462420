import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';

@Pipe({
  name: 'appNumber',
})
export class AppNumberPipe implements PipeTransform {

  transform(value: number | null, digitsInfo?: string): string {
    if (value === null) {
      return '-';
    }
    return formatNumber(value, 'en-us', digitsInfo)
      .replace(',', '');
  }

}
