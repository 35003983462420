<div class="main-wrapper">
  <div class="container">

    <div class="row">
      <div class="col main-visual">
        <img class="isr_logo" src="/assets/home/ISR_red_white.png"/>
        <img class="varia" src="/assets/home/varia.png"/>
        <img class="an16" src="/assets/home/an16.png"/>
        <img class="tetra" src="/assets/home/tetra.png"/>
        <h1>Legal Notice</h1>
        <a class="home" href="/">
          <span>Home</span>
        </a>
        <a class="login" href="/list">
          <span>Login</span>
        </a>
      </div>
    </div>

    <div class="row legal-notice">
      <div class="col block-padding">
        <h2>General information</h2>

        <h2>This website is the property of:</h2>
        IAV Technologies SARL
        <br>
        Chemin des Couleuvres 4A
        <br>
        1295 Tannay
        <br>
        SWITZERLAND
        <br>
        Tel +41 (0)22 960 11 04
        <br>
        Email : <a href="mailto:isr@iav.ch">isr@iav.ch</a>
        <br>
        IDE\UID CHE-197.530.466
        <br>
        <br>

        This website was created by : Plaine d’Idées
        <br>
        This website is maintained by : IAV Technologies SARL
        <br>
        This website is hosted by : OVH – 2 rue Kellermann – 59100 Roubaix – France – Tel : +33 9 72 10 10 07 –
        www.ovh.com

        <h2>Applicable Law</h2>
        The website exova.iav.ch as well as the following terms and conditions are subject to Swiss law.

        <h2>Terms and conditions</h2>
        Sales on this website are subject to the following terms and conditions.

        <h2>© Copyright</h2>
        All rights reserved. Text, images, graphics, sound, animations and videos as well as the arrangement of the same
        on this website are protected by copyright and other commercial protective rights. The content of this website
        may not be copied, disseminated, altered or made accessible to third parties for commercial purposes.

        <h2>Trademark information</h2>
        Unless specified otherwise, all trademarks on this website are protected by trademark law. The brands and design
        elements used on our pages are the intellectual property of IAV Technologies SARL

        <h2>Warranty information</h2>
        This website was compiled with utmost care. Nonetheless, the accuracy or correctness of the information
        contained cannot be guaranteed. Any liability for damage resulting directly or indirectly from use of this
        website is precluded, unless caused intentionally or in gross negligence by IAV Technologies SARL

        <h2>Licence information</h2>
        The intellectual property contained in this website such as patents, marks and copyrights, is protected. This
        website does not grant a licence for utilising the intellectual property of IAV Technologies SARL or third
        parties.

        <h2>Safety</h2>
        We take every necessary precaution to ensure the integrity of the data, their confidentiality and prevent any
        leaks to unauthorized third-parties.

        <h2>Hyperlinks</h2>
        Creating a hyperlink leading to the website exova.iav.ch doesn’t require any written authorization from IAV
        Technologies SARL.
        Nonetheless, IAV Technologies SARL must be informed within 30 days after the creation of this link.
        In any case, IAV Technologies SARL cannot be held responsible for the contents or the products or services
        offered on websites with which the website exova.iav.ch might find itself linked by hyperlinks or every other
        kind of links.

        <h2>Update</h2>
        IAV Technologies SARL reserves the right to modify and to update without notice these legal notices, contents
        and any product presented on the website.
        All these changes are binding to users who should check this Legal Notice at each connection.

      </div>
    </div>

    <div class="row footer justify-content-around">
      <div class="col-12 block-padding">
        <h4>CONTACT US</h4>
        IAV Technologies SARL
        <br>
        ISR Products Division
        <br>
        Chemin des Couleuvres 4A
        <br>
        1295 Tannay
        <br>
        SWITZERLAND
        <br>
        Tel: <a href="tel:+41229601104">+41 (0)22 960 11 04</a>
        <br>
        Email: <a href="mailto:isr@iav.ch">isr@iav.ch</a>
      </div>
      <div class="col-12 block-padding copyright">
        Copyright IAV Engineering SARL 2013 | <a href="/legal-notice">Legal Notice</a>
      </div>
    </div>

  </div>
</div>
