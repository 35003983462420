import {ConfigurationService} from './services/configuration.service';
import {HttpClient} from '@angular/common/http';
import {Configuration} from './configuration';

export const appInitializer = (
  configurationService: ConfigurationService,
  httpClient: HttpClient): () => Promise<any> => (): Promise<any> => httpClient.get('/assets/configuration.json?time=' + Date.now())
  .toPromise()
  .then(response => {
    configurationService.configuration = response as Configuration;
  });

