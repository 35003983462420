import {Component} from '@angular/core';
import {ArfangEventSummary} from '../../common/events/arfang-event-model';
import {BehaviorSubject, Subject} from 'rxjs';
import {EventWsService} from '../../services/arfang-ws/event-ws.service';
import {EventWsFilter} from '../../common/events-filter/filters/event-ws-filter';
import {AlertService} from '../../services/alert.service';
import moment from 'moment';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss']
})
export class GraphsComponent {

  eventsFilters?: EventWsFilter;
  xAxisRangeSubject = new BehaviorSubject<moment.Moment[]>([]);
  eventsSubject = new BehaviorSubject<ArfangEventSummary[]>([]);
  eventToFocusSubject = new Subject<number>();

  constructor(private alertService: AlertService,
              private eventWsService: EventWsService) {
  }

  filtersUpdated(eventsFilters: EventWsFilter) {
    this.eventsFilters = eventsFilters;
    this.xAxisRangeSubject.next([
      moment(eventsFilters.minDate).startOf('day'),
      moment(eventsFilters.maxDate).endOf('day')
    ]);
    this.loadEvents();
  }

  onEventSimpleClick($event: number) {
    this.eventToFocusSubject.next($event);
  }

  async loadEvents(): Promise<void> {
    try {
      const events = await this.eventWsService.getEvents(this.eventsFilters);
      this.eventsSubject.next(events);
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
