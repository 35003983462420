import {Injectable} from '@angular/core';
import {ArfangWsService} from './arfang-ws.service';
import {WavReplayStation} from './wav-replay-model';

@Injectable({
  providedIn: 'root'
})
export class WavReplayWsService {

  private readonly ENDPOINT: string = 'wavreplay';

  constructor(private arfangWsService: ArfangWsService) {
  }

  getReplayStations(): Promise<WavReplayStation[]> {
    return this.arfangWsService.get<WavReplayStation[]>(this.ENDPOINT + '/replayStations', {});
  }

}
