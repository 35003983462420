import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value)  {
      return  moment.utc(value).tz('Asia/Tbilisi').format('YYYY/MM/DD HH:mm:ss zz');
    } else {
      return '';
    }
  }

}
