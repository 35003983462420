import {Component, Input} from '@angular/core';
import {SVGConfigs} from '../../../pages/list/events-table/SVG-configs';
import {ArfangEventSummary} from '../../events/arfang-event-model';

@Component({
  selector: 'app-svg-magnitude',
  templateUrl: './svg-magnitude.component.html',
  styleUrls: ['./svg-magnitude.component.scss']
})
export class SvgMagnitudeComponent {

  @Input() event: ArfangEventSummary;

  magnitudeConfig = SVGConfigs.magnitudeSmall;

}
