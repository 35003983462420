import { Injectable } from '@angular/core';
import {AuthResponse} from './auth-ws-model';
import {ArfangWsService} from './arfang-ws.service';

@Injectable({
  providedIn: 'root'
})
export class AuthWsService {

  private readonly ENDPOINT: string = 'auth';

  constructor(private arfangWsService: ArfangWsService) {
  }

  login(username: string, password: string): Promise<AuthResponse> {
    return this.arfangWsService.post<AuthResponse>(this.ENDPOINT, {username, password});
  }

}
