import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ArfangStation} from '../../../../../common/stations/arfang-station';
import {interval, Observable, Subscription} from 'rxjs';
import {SpectrogramData} from '../../websocket-service/packets/SpectrogramData';
import {ScopeService} from '../scope.service';
import {SpectrogramPacket} from '../../websocket-service/packets/SpectrogramPacket';

@Component({
  selector: 'app-azimuth-correlogram',
  templateUrl: './azimuth-correlogram.component.html',
  styleUrls: ['./azimuth-correlogram.component.scss']
})
export class AzimuthCorrelogramComponent implements OnInit, OnDestroy, OnChanges {

  @Input() station: ArfangStation;
  @Input() dataReceived: Observable<SpectrogramPacket>;
  @Input() infrasonicEventExampleMode = false;
  @Input() staticMode = false;
  @Input() autoscale: boolean;

  private dataReceivedSubscription: Subscription;
  private buffer: SpectrogramData[] = [];
  private activityTimerSubscription: Subscription;
  secondsSinceLastActivity = 0;
  graphConfig: { layout: any, data: any[], config: any };

  constructor(readonly scopeService: ScopeService) {
    this.initGraph();
    this.activityTimerSubscription = interval(1000)
      .subscribe(() => this.activityTimer());
  }

  ngOnInit() {
    this.dataReceivedSubscription = this.dataReceived.subscribe(data => {
      if (this.staticMode) {
        this.clear();
        this.updateXAxis(data.staticModeEpochSecond);
      }
      data.azimuthCorrelogramData.forEach(spectroData => this.refresh(spectroData));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('station') && !this.staticMode) {
      this.clear();
    } else if (changes.hasOwnProperty('autoscale')) {
      this.graphConfig.data[0].zauto = this.autoscale;
    }
  }

  ngOnDestroy() {
    this.dataReceivedSubscription.unsubscribe();
    this.activityTimerSubscription.unsubscribe();
  }

  private activityTimer() {
    this.secondsSinceLastActivity++;
  }

  private initGraph() {
    this.graphConfig = {
      layout: {},
      data: [],
      config: this.scopeService.getPlotlyConfig()
    };
    const font = {
      size: 13
    };
    this.graphConfig.layout = {
      showlegend: false,
      showscale: false,
      // height: 198,
      height: 395,
      margin: {l: 70, r: 25, t: 10, b: 25, autoexpand: false},
      xaxis: {
        fixedrange: true,
        autorange: false,
        range: [ScopeService.WATERFALL_FRAMES_BUFFER - 1, 0],
        type: 'date',
        tickvals: [],
        ticktext: [],
        showticklabels: true
      },
      yaxis: {
        fixedrange: true,
        autorange: false,
        title: {
          text: `Direction of event`,
          font
        },
        range: [0, 360],
        tickvals: [0, 90, 180, 270, 360],
        ticktext: ['FWD', 'L', 'BWD', 'R', 'FWD'],
        ticklen: 20,
        tickcolor: 'transparent',
      },
      font
    };
    this.graphConfig.data.push({
      y: this.scopeService.generateAzimuths(),
      z: [this.scopeService.generateAzimuths()],
      type: 'heatmapgl',
      zauto: this.autoscale,
      zmin: 12, // 0 + 20%
      zmax: 52, // 64 - 20%
      transpose: true,
      hoverinfo: 'skip',
      zsmooth: false,
      showscale: false,
      // colorscale: [
      //   [0.000, '#440154'],
      //   [0.016, '#46075a'],
      //   [0.031, '#470d60'],
      //   [0.047, '#471365'],
      //   [0.063, '#48186b'],
      //   [0.078, '#481e6f'],
      //   [0.094, '#482374'],
      //   [0.109, '#482878'],
      //   [0.125, '#472d7c'],
      //   [0.141, '#46327f'],
      //   [0.156, '#453782'],
      //   [0.172, '#433c84'],
      //   [0.188, '#424186'],
      //   [0.203, '#404688'],
      //   [0.219, '#3e4a89'],
      //   [0.234, '#3c4f8a'],
      //   [0.250, '#3a538b'],
      //   [0.266, '#38578c'],
      //   [0.281, '#365c8d'],
      //   [0.297, '#34608d'],
      //   [0.313, '#32648e'],
      //   [0.328, '#31688e'],
      //   [0.344, '#2f6c8e'],
      //   [0.359, '#2d708e'],
      //   [0.375, '#2c748e'],
      //   [0.391, '#2a778e'],
      //   [0.406, '#297b8e'],
      //   [0.422, '#277f8e'],
      //   [0.438, '#26838e'],
      //   [0.453, '#24878e'],
      //   [0.469, '#238a8d'],
      //   [0.484, '#218e8d'],
      //   [0.500, '#20928c'],
      //   [0.516, '#1f968b'],
      //   [0.531, '#1f9a8a'],
      //   [0.547, '#1f9d89'],
      //   [0.563, '#1fa187'],
      //   [0.578, '#21a586'],
      //   [0.594, '#23a984'],
      //   [0.609, '#26ac81'],
      //   [0.625, '#2ab07f'],
      //   [0.641, '#2fb47c'],
      //   [0.656, '#35b779'],
      //   [0.672, '#3bbb75'],
      //   [0.688, '#42be71'],
      //   [0.703, '#4ac26d'],
      //   [0.719, '#52c569'],
      //   [0.734, '#5ac864'],
      //   [0.750, '#63cb5f'],
      //   [0.766, '#6cce59'],
      //   [0.781, '#76d054'],
      //   [0.797, '#80d34d'],
      //   [0.813, '#8ad547'],
      //   [0.828, '#94d841'],
      //   [0.844, '#9fda3a'],
      //   [0.859, '#a9dc33'],
      //   [0.875, '#b4dd2c'],
      //   [0.891, '#bfdf25'],
      //   [0.906, '#cae01f'],
      //   [0.922, '#d4e21a'],
      //   [0.938, '#dfe318'],
      //   [0.953, '#e9e41a'],
      //   [0.969, '#f3e61e'],
      //   [1, '#fde725']
      // ]
      colorscale: [
        [0.000, '#000000'],
        [0.016, '#040404'],
        [0.031, '#080808'],
        [0.047, '#0c0c0c'],
        [0.062, '#101010'],
        [0.078, '#141414'],
        [0.094, '#181818'],
        [0.109, '#1c1c1c'],
        [0.125, '#202020'],
        [0.141, '#242424'],
        [0.156, '#282828'],
        [0.172, '#2c2c2c'],
        [0.188, '#303030'],
        [0.203, '#343434'],
        [0.219, '#383838'],
        [0.234, '#3c3c3c'],
        [0.250, '#404040'],
        [0.266, '#444444'],
        [0.281, '#484848'],
        [0.297, '#4c4c4c'],
        [0.312, '#505050'],
        [0.328, '#545454'],
        [0.344, '#585858'],
        [0.359, '#5c5c5c'],
        [0.375, '#606060'],
        [0.391, '#646464'],
        [0.406, '#686868'],
        [0.422, '#6c6c6c'],
        [0.438, '#707070'],
        [0.453, '#747474'],
        [0.469, '#787878'],
        [0.484, '#7c7c7c'],
        [0.500, '#808080'],
        [0.516, '#848484'],
        [0.531, '#888888'],
        [0.547, '#8c8c8c'],
        [0.562, '#909090'],
        [0.578, '#949494'],
        [0.594, '#989898'],
        [0.609, '#9c9c9c'],
        [0.625, '#a0a0a0'],
        [0.641, '#a4a4a4'],
        [0.656, '#a8a8a8'],
        [0.672, '#acacac'],
        [0.688, '#b0b0b0'],
        [0.703, '#b4b4b4'],
        [0.719, '#b8b8b8'],
        [0.734, '#bcbcbc'],
        [0.750, '#c0c0c0'],
        [0.766, '#c4c4c4'],
        [0.781, '#c8c8c8'],
        [0.797, '#cccccc'],
        [0.812, '#d0d0d0'],
        [0.828, '#d4d4d4'],
        [0.844, '#d8d8d8'],
        [0.859, '#dcdcdc'],
        [0.875, '#e0e0e0'],
        [0.891, '#e4e4e4'],
        [0.906, '#e8e8e8'],
        [0.922, '#ececec'],
        [0.938, '#f0f0f0'],
        [0.953, '#f4f4f4'],
        [0.969, '#f8f8f8'],
        [1, '#ffffff'],
      ]
    });
  }

  private clear() {
    this.buffer = [];
    if (this.graphConfig?.data[0] !== undefined) {
      this.graphConfig.data[0].z = [this.scopeService.generateAzimuths()];
    }
  }

  private refresh(data: SpectrogramData) {
    this.secondsSinceLastActivity = 0;
    while (this.buffer.length >= ScopeService.WATERFALL_FRAMES_BUFFER) {
      this.buffer.shift();
    }
    this.buffer.push(data);
    this.graphConfig.data[0].x = [];
    this.graphConfig.data[0].z = [];
    const limitTime = data.startTime - ScopeService.WATERFALL_FRAMES_BUFFER * ScopeService.FRAME_DURATION_MS;
    const graphConfig = this.graphConfig;
    this.buffer.forEach(frame => {
      if (frame.values.length !== 0 && frame.startTime > limitTime) {
        graphConfig.data[0].x.push(frame.startTime);
        graphConfig.data[0].z.push(frame.values);
      }
    });
  }

  private updateXAxis(staticModeEpochSecond?: number) {
    if (staticModeEpochSecond === undefined) {
      return;
    }
    const xaxisRange = [(staticModeEpochSecond - 150) * 1000, (staticModeEpochSecond + 150) * 1000];
    const tickInterval = 60;
    const xStart = Math.round(xaxisRange[0] / 1000);
    let ticksTimestamps: number[];
    if (xStart % tickInterval === 0) {
      ticksTimestamps = [
        xStart,
        xStart + tickInterval,
        xStart + tickInterval * 2,
        xStart + tickInterval * 3,
        xStart + tickInterval * 4,
        xStart + tickInterval * 5,
        xStart + tickInterval * 6
      ];
    } else {
      const ticksStart = xStart + tickInterval - xStart % tickInterval;
      ticksTimestamps = [
        ticksStart,
        ticksStart + tickInterval,
        ticksStart + tickInterval * 2,
        ticksStart + tickInterval * 3,
        ticksStart + tickInterval * 4,
        ticksStart + tickInterval * 5
      ];
    }
    this.graphConfig.layout.xaxis.range = xaxisRange.map(date => new Date(date));
    const ticks = ticksTimestamps
      .map(timestamp => new Date(timestamp * 1000));
    this.graphConfig.layout.xaxis.tickvals = ticks;
    this.graphConfig.layout.xaxis.ticktext = ticks.map(date => `${date.getHours().toString().padStart(2, '0')}:` +
      `${date.getMinutes().toString().padStart(2, '0')}:` +
      `${date.getSeconds().toString().padStart(2, '0')}`
    );
  }

}
