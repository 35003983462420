<app-nav-bar activatedItem="stations"></app-nav-bar>

<div class="page-container container">
  <div class="row">
    <div class="col">
      <mat-card *ngIf="!!ready">
        <mat-card-title>Station {{station | stationName}}</mat-card-title>
        <div class="container-fluid">

          <div class="row">
            <h4>Sensor location (Precision +/- 10m)</h4>
          </div>
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Latitude</mat-label>
              <input matInput [(ngModel)]="stationCoordinates.latitude">
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Longitude</mat-label>
              <input matInput [(ngModel)]="stationCoordinates.longitude">
            </mat-form-field>
            <mat-form-field class="col-4">
              <mat-label>Altitude</mat-label>
              <input matInput [(ngModel)]="stationCoordinates.altitude">
            </mat-form-field>
          </div>

          <div class="row">
            <h4>Sensors coordinates (Precision +/- 0.1m)</h4>
          </div>
          <div class="row sensor-headers">
            <h5 class="col-3">#</h5>
            <h5 class="col-3">X (m)</h5>
            <h5 class="col-3">Y (m)</h5>
            <h5 class="col-3">Z (m)</h5>
          </div>
          <div class="row sensor sensor-1">
            <p class="col-3">1</p>
            <p class="col-3">0</p>
            <p class="col-3">0</p>
            <p class="col-3">0</p>
          </div>
          <div class="row sensor sensor-2" *ngIf="station.nbSensors >= 2">
            <p class="col-3">2</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor2X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor2Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor2Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-3" *ngIf="station.nbSensors >= 3">
            <p class="col-3">3</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor3X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor3Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor3Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-4" *ngIf="station.nbSensors >= 4">
            <p class="col-3">4</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor4X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor4Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor4Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-5" *ngIf="station.nbSensors >= 5">
            <p class="col-3">5</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor5X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor5Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor5Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-6" *ngIf="station.nbSensors >= 6">
            <p class="col-3">6</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor6X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor6Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor6Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-7" *ngIf="station.nbSensors >= 7">
            <p class="col-3">7</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor7X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor7Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor7Z">
            </mat-form-field>
          </div>
          <div class="row sensor sensor-8" *ngIf="station.nbSensors >= 8">
            <p class="col-3">8</p>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor8X">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor8Y">
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="stationCoordinates.sensor8Z">
            </mat-form-field>
          </div>

          <div class="row justify-content-end" style="margin-top: 16px">
            <button mat-raised-button color="primary" (click)="save()">Save</button>
          </div>

        </div>
      </mat-card>
    </div>
  </div>
</div>
