<app-nav-bar activatedItem="infrasonic-event-examples"></app-nav-bar>

<div class="container-fluid page-container p-2">
  <div class="row">
    <div class="col-md-2">
      <mat-card>
        <mat-card-title>Event examples</mat-card-title>
        <div *ngFor="let replayStation of replayStations">
          <mat-divider></mat-divider>
          <div matRipple class="replayStationListItem" [ngClass]="{selected: replayStation === selectedReplayStation}"
               (click)="selectedReplayStation = replayStation">
            {{replayStation.name}}
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-md-10" *ngIf="selectedReplayStation">
      <mat-card>
        <mat-card-title>{{selectedReplayStation.name}}</mat-card-title>
        <mat-card-subtitle>{{selectedReplayStation.description}}</mat-card-subtitle>
        <app-livescopesession [station]="selectedReplayStation.station"
                              [infrasonicEventExampleMode]="true"></app-livescopesession>
      </mat-card>
    </div>
  </div>
</div>
