import {Injectable} from '@angular/core';
import {webSocket} from 'rxjs/webSocket';
import {StationPacket} from './packets/StationPacket';
import {SpectrogramPacket} from './packets/SpectrogramPacket';
import {ConfigurationService} from '../../../../services/configuration.service';
import {LiveScopeWebSocket} from './livescope-websocket';
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private readonly websocketUrl: string;

  private ws: WebSocketSubject<any>;

  constructor(configurationService: ConfigurationService) {
    this.websocketUrl = configurationService.configuration.serverUrl
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')
      + '/ws';
  }

  createLiveScopeWebSocket(stationId: number): LiveScopeWebSocket {
    return new LiveScopeWebSocket(webSocket<any>({url: this.websocketUrl}), stationId);
  }

  isSpectrogramPacket(data: any): boolean {
    try {
      const spectrogramPacket = (data as SpectrogramPacket);
      if (spectrogramPacket.spectrogramData.length === 0 || spectrogramPacket.spectrogramData.length === undefined) {
        return false;
      }
      return spectrogramPacket.stationId !== undefined &&
        spectrogramPacket.spectrogramData[spectrogramPacket.spectrogramData.length - 1].startTime !== undefined &&
        spectrogramPacket.spectrogramData[spectrogramPacket.spectrogramData.length - 1].values !== undefined &&
        spectrogramPacket.spectrogramData[spectrogramPacket.spectrogramData.length - 1].values.length !== 0;
    }
    catch (e) {
      return false;
    }
  }

  isStationPacket(data: any, pointsPerSec: number): boolean {
    try {
      const dataStationPacket = (data as StationPacket);
      if (dataStationPacket.timeTraces === undefined) {
        return false;
      }
      let isStationPacket = true;
      dataStationPacket.timeTraces.forEach(timeTrace =>
        isStationPacket = isStationPacket && timeTrace.sensor !== undefined &&
          timeTrace.signal !== undefined &&
          timeTrace.signal.length === pointsPerSec
      );
      isStationPacket = isStationPacket && dataStationPacket.stationId !== undefined;
      isStationPacket = isStationPacket && dataStationPacket.timeStamp !== undefined;
      return isStationPacket;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

}

