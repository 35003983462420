<svg [attr.height]="sectorConfig.height" [attr.width]="sectorConfig.width + 2">
  <circle [attr.r]="sectorConfig.height / 2 - 3" [attr.cx]="sectorConfig.width / 2 + 4"
          [attr.cy]="sectorConfig.height / 2" fill="transparent" stroke="black"></circle>
  <path [attr.d]="describeArc(event.azimuthStart, event.azimuthEnd)"
        [attr.fill]="filterService.getPrognosisProperty('color', event.prognosis)"
        [attr.stroke]="filterService.getPrognosisProperty('color', event.prognosis)"
        stroke-linecap="round" stroke-width="1"></path>
  <path [attr.d]="sectorConfig.arrowPath" fill="red"></path>
  <text x="0" [attr.y]="sectorConfig.yText" [attr.font-size]="sectorConfig.fontSize">
    <tspan>N</tspan>
  </text>
</svg>
