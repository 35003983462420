import {Injectable} from '@angular/core';
import {ArfangWsService} from 'src/app/services/arfang-ws/arfang-ws.service';

@Injectable({
  providedIn: 'root'
})
export class AppWsService {

  constructor(private wsClientService: ArfangWsService) {
  }

  private url(relativeUrl: string): string {
    return '/api/app' + relativeUrl;
  }

  async appInfos(): Promise<AppInfos> {
    return await this.wsClientService.get<AppInfos>(this.url('/app-infos'));
  }

}

export interface AppInfos {
  appVersion: string;
  appVersionShort: string;
  appVersionNumber: string;
}
