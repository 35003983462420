<app-nav-bar activatedItem="livescope"></app-nav-bar>

<div class="container-fluid page-container p-2">
  <div class="row">
    <div class="col-md">
      <mat-card>
        <mat-card-title>Spectrogram & DOA</mat-card-title>
        <span>This viewer is used to rapidly browse the records from a selected device, and visualize the presence of possibly interesting sound patterns</span>
        <mat-form-field class="card-top-right stationSelect">
          <mat-label>Select device</mat-label>
          <mat-select placeholder="Select device" [(value)]="station" (selectionChange)="stationSelectionChanged()">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let station of stations" [value]="station">
              {{station | stationName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="station" class="static-mode-controls">
          <button mat-button (click)="advance(-60*60*24)">-1d</button>
          <button mat-button (click)="advance(-60*60)">-1h</button>
          <button mat-button (click)="advance(-300)">-5m</button>
          <button mat-button (click)="advance(-60)">-1m</button>
          <span class="current-position">{{currentPositionDate() | date:'dd.MM.yyyy HH:mm:ss'}}</span>
          <button mat-button (click)="advance(60)">+1m</button>
          <button mat-button (click)="advance(300)">+5m</button>
          <button mat-button (click)="advance(60*60)">+1h</button>
          <button mat-button (click)="advance(60*60*24)">+1d</button>
        </div>
        <app-livescopesession *ngIf="station" [station]="station" [staticMode]="true"
                              [staticModeEpochSecond]="staticModeEpochSecond"
                              [staticCoherenceSpectrogramDatas]="staticCoherenceSpectrogramDatas"
                              [staticAzimuthCorrelogramDatas]="staticAzimuthCorrelogramDatas"></app-livescopesession>
      </mat-card>
    </div>
  </div>
</div>

