import {LngLatUtils} from 'src/app/pages/shot-detection/LngLatUtils';
import {RealShot} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';

export class DtSwMb {

  static dtLines(realShot: RealShot, v: number, spaceBetweenLinesM: number): DtLine[] {
    const targetXY = LngLatUtils.dLngLat2dXYm(
      realShot.targetLng - realShot.shooterLng,
      realShot.targetLat - realShot.shooterLat,
      realShot.shooterLat);
    const targetDistance = Math.sqrt(Math.pow(targetXY[0], 2) + Math.pow(targetXY[1], 2));
    const yRes = 2;
    const yLengthM = 150;
    const yLength = Math.ceil(yLengthM / yRes);
    const xRes = 0.1;
    const xLengthM = Math.ceil(targetDistance) * 4;
    const xLength = Math.ceil(xLengthM / xRes);
    const targetAngle = -Math.atan2(targetXY[1], targetXY[0]);
    const dtLines: DtLine[] = [];
    const previousXMinDtDiffIForYi = new Array(yLength).fill(0);
    for (let xiLine = 0; xiLine < xLength && xiLine * xRes < targetDistance; xiLine += Math.ceil(spaceBetweenLinesM / xRes)) {
      const xLine = xiLine * xRes;
      const xLineDt = xiLine === 0 ? 0 : this.dt(v, targetDistance, xLine, 0);
      let coordinatesXY: [number, number][] = [];
      for (let yi = 0; yi < yLength; yi += 1) {
        const y = yi * yRes;
        let xMinDtDiffI = previousXMinDtDiffIForYi[yi];
        let xMinDtDiff = Number.MAX_VALUE;
        for (let xi = xMinDtDiffI + 1; xi < xLength; xi++) {
          const x = xi * xRes;
          const xDt = this.dt(v, targetDistance, x, y);
          const xDtDiff = Math.abs(xDt - xLineDt);
          if (xDtDiff <= xMinDtDiff) {
            xMinDtDiff = xDtDiff;
            xMinDtDiffI = xi;
          } else {
            break;
          }
        }
        if (xMinDtDiffI < xLength - 1) {
          coordinatesXY.push([xMinDtDiffI * xRes, y]);
        }
        previousXMinDtDiffIForYi[yi] = xMinDtDiffI;
      }
      coordinatesXY = this.mirrorCoordinates(coordinatesXY);
      const coordinatesLngLat: [number, number][] = coordinatesXY.map(xy => {
        const x = xy[0];
        const y = xy[1];
        const rotatedX = x * Math.cos(targetAngle) + y * Math.sin(targetAngle);
        const rotatedY = -x * Math.sin(targetAngle) + y * Math.cos(targetAngle);
        const dLngLat = LngLatUtils.dXYm2dLngLat(rotatedX, rotatedY, realShot.shooterLat);
        return [realShot.shooterLng + dLngLat[0], realShot.shooterLat + dLngLat[1]];
      });
      dtLines.push({
        dtSwMbMs: Math.round(xLineDt * 1000),
        lngLats: coordinatesLngLat
      });
    }
    return dtLines;
  }

  private static dt(v: number, targetDistance: number, x: number, y: number): number {
    const c = 340;
    // Shockwave times
    const mu = Math.asin(c / v); // Mach angle
    let dFermat = x - Math.tan(mu) * Math.abs(y); // Distance to Fermat point (in the rotated x-axis)
    dFermat = Math.min(targetDistance, Math.max(0, dFermat)); // Fermat point is restricted to the bullet trajectory
    const dAir = Math.sqrt(Math.pow(x - dFermat, 2) + Math.pow(y, 2)); // Distance in the air (from Fermat to point xy)
    const tSW = dFermat / v + dAir / c;
    // Muzzle blast times
    const tMB = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) / c;
    // Time between SW and MB
    return tMB - tSW;
  }

  private static mirrorCoordinates(coordinatesXY: [number, number][]): [number, number][] {
    const mirroredCoordinatesXY = coordinatesXY.filter((_, i) => i > 0)
      .map(xy => [xy[0], -xy[1]])
      .reverse();
    return [...mirroredCoordinatesXY, ...coordinatesXY] as [number, number][];
  }

}

export interface DtLine {
  dtSwMbMs: number;
  lngLats: [number, number][];
}
