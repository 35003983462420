import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {ScopeService} from '../scope.service';
import {StationPacket} from '../../websocket-service/packets/StationPacket';

@Component({
  selector: 'app-sensor-scope',
  templateUrl: './sensor-scope.component.html',
  styleUrls: ['./sensor-scope.component.scss']
})
export class SensorScopeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() private sensorID: number;
  @Input() private dataReceived: Observable<StationPacket>;
  @Input() private sensorColor: string;
  @Input() autoscale: boolean;

  private dataReceivedSubscription: Subscription;
  private buffer = [];
  private activityTimerSubscription: Subscription;
  secondsSinceLastActivity = 0;
  graphConfig: { layout: any, data: any[], config: any };

  constructor(readonly scopeService: ScopeService) {
    this.activityTimerSubscription = interval(1000)
      .subscribe(() => this.activityTimer());
  }

  ngOnInit() {
    this.initGraph();
    this.dataReceivedSubscription = this.dataReceived.subscribe(data => this.refreshScope(data));
  }

  ngOnDestroy() {
    this.dataReceivedSubscription.unsubscribe();
    this.activityTimerSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('autoscale')) {
      if (this.graphConfig != null) {
        this.graphConfig.layout.yaxis.autorange = this.autoscale;
        if (!this.autoscale) {
          this.graphConfig.layout.yaxis.range = [-1, 1];
        }
      }
    }
  }

  private activityTimer() {
    this.secondsSinceLastActivity++;
    if (this.secondsSinceLastActivity >= 10) {
      this.graphConfig.data[0].marker.color = 'rgba(141,141,141,0.53)';
    }
  }

  private initGraph() {
    this.graphConfig = {
      layout: {},
      data: [],
      config: this.scopeService.getPlotlyConfig()
    };
    const font = {
      size: 13
    };
    const gridColor = 'eee';
    this.graphConfig.layout = {
      showlegend: false,
      title: {
        text: `Microphone n°${this.sensorID + 1}`,
        xref: 'paper',
        font
      },
      height: 100,
      margin: {l: 60, r: 25, t: 20, b: 25, autoexpand: false},
      xaxis: {
        fixedrange: true,
        autorange: false,
        // type: 'date',
        tickvals: [],
        ticktext: [],
        zeroline: false,
        mirror: 'ticks',
        showline: true,
        showgrid: true,
        linecolor: gridColor,
        gridcolor: gridColor,
      },
      yaxis: {
        title: {
          text: '',
          font
        },
        fixedrange: true,
        autorange: this.autoscale,
        range: [-1, 1],
        showticklabels: true,
        zeroline: false,
        mirror: 'ticks',
        showline: true,
        showgrid: true,
        linecolor: gridColor,
        gridcolor: gridColor,
      },
      font
    };
    this.graphConfig.data = [{
      x: [],
      y: [],
      type: 'scatter'
    }];
  }

  private refreshScope(data: StationPacket): void {
    this.secondsSinceLastActivity = 0;
    while (this.buffer.length >= ScopeService.SENSOR_FRAMES_BUFFER) {
      this.buffer.shift();
    }
    const timeTrace = data.timeTraces[this.sensorID];
    this.buffer.push({
      x: this.scopeService.generateTimesArray(data.timeStamp).map(value => new Date(value)),
      y: timeTrace.signal
    });
    let newX = [];
    let newY = [];
    this.buffer.forEach(frame => {
      newX = newX.concat(frame.x);
      newY = newY.concat(frame.y);
    });
    this.graphConfig.data = [{
      x: newX,
      y: newY,
      type: 'scatter',
      marker: {color: this.sensorColor}
    }];
    const xaxisRange = [data.timeStamp - ScopeService.FRAME_DURATION_MS * (ScopeService.SENSOR_FRAMES_BUFFER - 1),
      data.timeStamp + ScopeService.FRAME_DURATION_MS];
    this.graphConfig.layout.xaxis.range = xaxisRange.map(date => new Date(date));
    const ticks = this.scopeService.generateSensorScopeXTicks(data.timeStamp);
    this.graphConfig.layout.xaxis.tickvals = ticks;
    this.graphConfig.layout.xaxis.ticktext = ticks.map(date => `${date.getHours().toString().padStart(2, '0')}:` +
      `${date.getMinutes().toString().padStart(2, '0')}:` +
      `${date.getSeconds().toString().padStart(2, '0')}`
    );
  }

}
