<app-nav-bar></app-nav-bar>

<div class="container page-container">

  <div class="row" *ngIf="!!arfangEvent && displayButtonsNextPrevious">
    <div class="col-md">
      <mat-card>
        <button mat-icon-button (click)="previous()" [disabled]="eventsList.indexOf(eventID) == 0" style="float: left">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button (click)="next()" [disabled]="eventsList.indexOf(eventID) == eventsList.length - 1"
                style="float: right">
          <mat-icon>chevron_right</mat-icon>
        </button>
        <h3 style="text-align: center; margin: 10px">Event {{arfangEvent.eventSummary.id}}</h3>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="!!arfangEvent">
    <div class="col-md-6">
      <mat-card class="detection-card">
        <h3>Detection prognosis</h3>
        <div class="sector-magnitude">
          <app-svg-sector [event]="arfangEvent.eventSummary"></app-svg-sector>
          <app-svg-magnitude [event]="arfangEvent.eventSummary"></app-svg-magnitude>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">Station:</div>
            <div class="col-md-9">{{arfangEvent.station | stationName}}</div>
          </div>
          <div class="row">
            <div class="col-md-3">Date:</div>
            <div class="col-md-9">{{arfangEvent.eventSummary.dateTime | date:'YYYY-MM-dd HH:mm:ss.SSS' }}</div>
          </div>
          <div class="row">
            <div class="col-md-3">Duration:</div>
            <div class="col-md-9">{{arfangEvent.eventSummary.duration}} s</div>
          </div>
          <div class="row">
            <div class="col-md-3">Prognosis:</div>
            <div class="col-md-9">{{arfangEvent.eventSummary.prognosis}}</div>
          </div>
          <div class="row">
            <div class="col-md-3">DOA:</div>
            <div class="col-md-9">{{arfangEvent.event.azimuthStart | number:'1.0-1'}}°</div>
          </div>
          <div class="row">
            <div class="col-md-3">Notification:</div>
            <div class="col-md-9">{{arfangEvent.eventSummary.userWasNotified ? "Broadcasted" : "Not broadcasted"}}</div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-md-6">
    </div>
  </div>

  <div class="row" *ngIf="!!arfangEvent">
    <div class="col-md">
      <mat-card>
        <app-livescopesession [station]="arfangEvent.station"
                              [staticMode]="true"
                              [staticCoherenceSpectrogramDatas]="arfangEvent.coherenceSpectrogramDatas"
                              [staticAzimuthCorrelogramDatas]="arfangEvent.azimuthCorrelogramDatas"></app-livescopesession>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="!!arfangEvent && debugService.enabled">
    <div class="col-md">
      <app-debug-tools [arfangEvent]="arfangEvent"></app-debug-tools>
    </div>
  </div>

  <div class="row" *ngIf="!!arfangEvent">
    <div class="col-md">
      <app-map [eventToFocusReceived]="eventToFocusSubject"
               [stationsToDisplayReceived]="stationsToDisplaySubject"></app-map>
    </div>
  </div>
</div>
