import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.authenticated) {
      if (this.hasAcceptedConditions() || next.url[0]?.path === 'restricted-access-conditions') {
        return true;
      } else {
        this.router.navigate(['restricted-access-conditions'], {queryParams: {returnUrl: state.url}});
        return false;
      }
    }
    this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  private hasAcceptedConditions() {
    return localStorage.getItem('conditions-accepted') === 'true';
  }

}
