import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ArfangEventSummary} from '../../../common/events/arfang-event-model';
import {Subject} from 'rxjs';
import {ScopeService} from '../../livescope/live-scope-session/scopes/scope.service';
import {FilterService} from '../../../common/events-filter/service/filter.service';
import {Router} from '@angular/router';
import moment from 'moment';
import {PlotlyModule} from 'angular-plotly.js';

@Component({
  selector: 'app-plotter',
  templateUrl: './plotter.component.html',
  styleUrls: ['./plotter.component.scss']
})
export class PlotterComponent implements OnInit, OnDestroy {

  readonly PLOT_DIV_ID: string = 'eventsplot';

  @Input() newXAxisRangeReceived: Subject<moment.Moment[]>;
  @Input() newEventsToPlotReceived: Subject<ArfangEventSummary[]>;
  @Output() simpleClicked = new EventEmitter<number>();

  eventsToDisplay: ArfangEventSummary[];
  focusedEventPoint: ArfangEventSummary;

  plotlyConfig = {
    displayModeBar: false,
    responsive: true,
    scrollZoom: false,
    staticPlot: false,
    locale: 'fr-ch'
  };

  plotLayout = {
    height: 300,
    showlegend: false,
    margin: {l: 90, r: 20, b: 60, t: 25},
    xaxis: {
      title: 'Time',
      type: 'date',
      autorange: false,
      fixedrange: true,
      range: [] as Date[],
    },
    yaxis: {
      title: 'DOA',
      autorange: false,
      fixedrange: true,
      range: [0, 359],
      tickvals: [0, 90, 180, 270, 359],
      ticktext: ['FWD', 'L', 'BWD', 'R', 'FWD'],
    },
  };

  plotData = [{
    x: [] as Date[],
    y: [] as number[],
    type: 'scatter',
    ids: [] as string[],
    text: [] as string[],
    mode: 'markers',
    opacity: 1.0,
    marker: {
      color: [] as string[],
      line: {
        width: [] as number[],
        color: [] as string[]
      },
      size: [] as number[]
    }
  }];

  constructor(readonly filterService: FilterService,
              private router: Router) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.newXAxisRangeReceived.subscribe(range => this.refreshAxis(range));
      this.newEventsToPlotReceived.subscribe(events => this.refreshPlot(events));
    }, 300);
  }

  refreshAxis(range: moment.Moment[]): void {
    this.plotLayout.xaxis.range = range.map(m => m.toDate());
  }

  refreshPlot(events: ArfangEventSummary[]): void {
    this.eventsToDisplay = events;
    this.plotData = [{
      x: events.map(event => new Date(event.dateTime)),
      y: events.map(event => event.azimuthStart),
      type: 'scatter',
      ids: events.map(event => this.getUID(event)),
      text: events.map(event => this.generateHoverInfos(event)),
      mode: 'markers',
      opacity: 1.0,
      marker: {
        color: events.map(event => this.filterService.getPrognosisProperty('color', event.prognosis)),
        line: {
          width: events.map(() => 1),
          color: events.map(() => '#000000')
        },
        size: events.map(event => event.magnitude * 8)
      }
    }];
  }

  generateHoverInfos(event: ArfangEventSummary): string {
    return `comment: ${event.observationComment}<br>id: ${event.id}<br>azimuths: start: ${event.azimuthStart}; end: ${event.azimuthEnd}<br>station ID: ${event.stationId}`;
  }

  onPointSelected(plotlyClick: { event: MouseEvent, points: any[] }): void {
    const eventIndex = this.plotData[0].ids.indexOf(plotlyClick.points[0].id);
    const arfangEvent = this.eventsToDisplay[eventIndex];
    if (this.focusedEventPoint === arfangEvent) {
      this.unfocusCurrentPoint();
    } else if (this.focusedEventPoint === undefined) {
      this.focusPoint(arfangEvent);
    } else {
      this.unfocusCurrentPoint();
      this.focusPoint(arfangEvent);
    }
    PlotlyModule.plotlyjs.restyle(this.PLOT_DIV_ID, this.plotData[0].marker);
    this.simpleClicked.emit(arfangEvent.id);
  }

  unfocusCurrentPoint(): void {
    const eventIndex = this.plotData[0].ids.indexOf(this.getUID(this.focusedEventPoint));
    if (this.focusedEventPoint !== undefined) {
      this.plotData[0].marker.line.color[eventIndex] = '#000000';
      this.plotData[0].marker.line.width[eventIndex] = 1;
      this.focusedEventPoint = undefined;
    }
  }

  getUID(event: ArfangEventSummary): string {
    return `${event.id}`;
  }

  focusPoint(arfangEvent: ArfangEventSummary): void {
    const eventIndex = this.plotData[0].ids.indexOf(this.getUID(arfangEvent));
    this.focusedEventPoint = arfangEvent;
    this.plotData[0].marker.line.color[eventIndex] = ScopeService.EVENT_FOCUS_COLOR;
    this.plotData[0].marker.line.width[eventIndex] = 4;
  }

  ngOnDestroy() {
    try {
      this.newEventsToPlotReceived.unsubscribe();
    } catch (e) {
    }
  }

}
