import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ShotDetectionSummary} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';
import {ShotDetectionWsService} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws.service';
import {saveAs} from 'file-saver';
import {ShotDetectionsFilters} from 'src/app/pages/list/shot-detections-filters';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  shotDetectionsFilters: ShotDetectionsFilters;
  shotDetections: ShotDetectionSummary[] = [];
  tpPoo: number | null = null;
  tpTraj: number | null = null;

  constructor(private router: Router,
              private shotDetectionWsService: ShotDetectionWsService,
              private activatedRoute: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    const filtersData = await this.shotDetectionWsService.getFiltersData();
    this.shotDetectionsFilters = new ShotDetectionsFilters(filtersData);
    if (Object.keys(this.activatedRoute.snapshot.queryParams).length === 0) {
      const storedQueryParams = localStorage.getItem('shotDetectionsList-storedQueryParams');
      if (storedQueryParams) {
        this.shotDetectionsFilters.loadFromQueryParams(JSON.parse(storedQueryParams));
      }
    } else {
      this.shotDetectionsFilters.loadFromQueryParams(this.activatedRoute.snapshot.queryParams);
    }
    await this.updateShotDetections();
  }

  async clearFilters(): Promise<void> {
    this.shotDetectionsFilters.clear();
    await this.updateShotDetections();
  }

  async updateShotDetections(): Promise<void> {
    this.shotDetections = [];
    this.tpPoo = null;
    this.tpTraj = null;
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: this.shotDetectionsFilters.buildQueryParams(),
      replaceUrl: true
    });
    const queryParams = this.shotDetectionsFilters.buildQueryParams();
    this.shotDetections = await this.shotDetectionWsService.getShotDetections(queryParams);
    localStorage.setItem('shotDetectionsList-storedQueryParams', JSON.stringify(queryParams));
    this.tpPoo = this.shotDetections
      .filter(shotDetection => shotDetection.shooterAngle !== null)
      .length;
    this.tpTraj = this.shotDetections
      .filter(shotDetection => shotDetection.shooterToTargetAngle !== null)
      .length;
  }

  async exportData(): Promise<void> {
    const response = await this.shotDetectionWsService.downloadShotDetections(this.shotDetectionsFilters.buildQueryParams());
    saveAs(response.body, 'shot-detections.json');
  }

  rowClicked(index: number) {
    this.router.navigate(['/shot-detection'], {
      queryParams: {
        shotDetectionIndex: index,
        ...this.shotDetectionsFilters.buildQueryParams()
      }
    });
  }

}
