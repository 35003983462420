<h1 *ngIf="displayTitle" mat-dialog-title> {{success ? 'Success' : 'Error'}}</h1>
<div mat-dialog-content *ngIf="!success">
  <div *ngIf="!success">
    <p *ngIf="!!data.message">An error occured while communicating with the server.</p>
    <p *ngIf="data.message"> {{data.message}}</p>
    <p>Code: {{data?.error.code}}</p>
    <p>Message: {{data?.error.message}}</p>
  </div>
</div>

<div mat-dialog-content *ngIf="success">
  <p *ngIf="data.message">{{data.message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
</div>
