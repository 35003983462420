import {Pipe, PipeTransform} from '@angular/core';
import {ArfangStation} from '../common/stations/arfang-station';
import {AuthService} from '../services/auth/auth.service';

@Pipe({
  name: 'stationName'
})
export class StationNamePipe implements PipeTransform {

  constructor(private authService: AuthService) {
  }

  transform(station: ArfangStation): string {
    if (this.authService.isAdmin) {
      const serial = station.streamName.replace(/\.stream$/, '');
      return `${station.name} (${serial})`;
    } else {
      return station.name;
    }
  }

}
