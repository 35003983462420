<app-nav-bar activatedItem="information"></app-nav-bar>
<div class="main-wrapper">
  <div class="container">

    <div class="row">
      <div class="col main-visual">
        <img class="isr_logo" src="/assets/home/ISR_red_white.png"/>
        <img class="varia" src="/assets/home/varia.png"/>
        <img class="an16" src="/assets/home/an16.png"/>
        <img class="tetra" src="/assets/home/tetra.png"/>
        <h1>Exova</h1>
        <a class="login" href="/list">
          <span>Access to field data</span>
        </a>
      </div>
    </div>

    <div class="row screenshot-row">
      <div class="col-md-7 block-padding">
        <gallery #gallery gallerize="gallery" id="gallery" [thumb]="false" [autoPlay]="false"
                 [counter]="false"></gallery>
      </div>
      <div class="col-md-5 block-padding">
        <p>
          This restricted access website is a research frontend for accessing and visualising pre-processed field data
          from the AN16 or TETRA experimental devices.
        </p>
      </div>
    </div>

    <div class="row footer justify-content-around">
      <div class="col-12 block-padding">
        <h4>CONTACT US</h4>
        IAV Technologies SARL
        <br>
        ISR Products Division
        <br>
        Chemin des Couleuvres 4A
        <br>
        1295 Tannay
        <br>
        SWITZERLAND
        <br>
        Tel: <a href="tel:+41229601104">+41 (0)22 960 11 04</a>
        <br>
        Email: <a href="mailto:isr@iav.ch">isr@iav.ch</a>
      </div>
      <div class="col-12 block-padding copyright">
        Copyright IAV Engineering SARL 2013 | <a href="/legal-notice">Legal Notice</a>
      </div>
    </div>

  </div>
</div>
