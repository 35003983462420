import {interval, Observable, Subscription} from 'rxjs';
import {LiveScopeControlMessage} from './live-scope-control-message';
import {Actions} from './Actions';
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject';

export class LiveScopeWebSocket {

    private keepAliveSubscription: Subscription;

    constructor(private webSocketSubject: WebSocketSubject<any>,
                private stationId: number) {
    }

    observable(): Observable<any> {
        return this.webSocketSubject;
    }

    open() {
        this.keepAliveSubscription = interval(20000).subscribe(() => this.sendMessage(
            new LiveScopeControlMessage(
                '',
                this.stationId,
                Actions.KEEP_ALIVE,
                0))
            , error => console.error('keepalive error : ', error));
    }

    close() {
        this.keepAliveSubscription.unsubscribe();
        this.webSocketSubject.complete();
    }

    sendMessage(message: LiveScopeControlMessage): void {
        this.webSocketSubject.next(message);
    }

    get closed() {
        return this.webSocketSubject.closed;
    }

    get isStopped() {
        return this.webSocketSubject.isStopped;
    }

}
