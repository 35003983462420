<div class="container page-container">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-8 col-sm-9">
      <mat-card>
        <mat-card-title>Exova login</mat-card-title>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="container-fluid">
            <div class="row">
              <mat-form-field class="col-md">
                <mat-label>Username</mat-label>
                <input matInput placeholder="Username" formControlName="username">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md">
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="Password" formControlName="password">
              </mat-form-field>
            </div>
            <div class="row justify-content-end">
              <a mat-button color="primary" href="/">Cancel</a>
              <button mat-raised-button color="primary" type="submit"
                      [disabled]="loading">
                Login
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
