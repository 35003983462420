<app-nav-bar></app-nav-bar>

<div class="page-container container">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Notifications</mat-card-title>
        <div class="container-fluid" *ngIf="ready">

          <div class="row">
            <div class="col-4">
              <h4>Event prognosis</h4>
              <mat-checkbox [(ngModel)]="notificationsSettings.avalanche">Avalanche</mat-checkbox>
              <br>
              <mat-checkbox [(ngModel)]="notificationsSettings.firing">Firing</mat-checkbox>
              <br>
              <mat-checkbox [(ngModel)]="notificationsSettings.other">Other</mat-checkbox>
            </div>
            <div class="col-4">
              <h4>Min. event magnitude</h4>
              <mat-form-field>
                <mat-select [(ngModel)]="notificationsSettings.magnitudeMin">
                  <mat-option [value]="1">1</mat-option>
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="3">3</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-4">
              <h4>Min. event duration (s)</h4>
              <mat-form-field>
                <input matInput [(ngModel)]="notificationsSettings.durationMin">
              </mat-form-field>
            </div>
          </div>

          <div class="row" style="margin-top: 16px">
              <h4 class="col">Notification type</h4>
          </div>
          <div class="row align-items-center">
            <mat-checkbox class="col" [(ngModel)]="notificationsSettings.emailNotification">
              Send E-mail to {{authService.email}}
            </mat-checkbox>
          </div>
          <div class="row sms-row">
            <div class="col">
              <mat-checkbox [(ngModel)]="notificationsSettings.smsNotification">Send SMS to</mat-checkbox>
              <mat-form-field>
                <mat-label>Cell phone number</mat-label>
                <input matInput [(ngModel)]="notificationsSettings.mobilePhone">
              </mat-form-field>
            </div>
          </div>

          <div class="row justify-content-end" style="margin-top: 16px">
            <button mat-raised-button color="primary" (click)="save()">Save</button>
          </div>

        </div>
      </mat-card>
    </div>
  </div>
</div>
