import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StationWsService} from '../../services/arfang-ws/station-ws.service';
import {ArfangStation, ArfangStationCoordinates} from '../../common/stations/arfang-station';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent implements OnInit {

  stationId: number;
  station: ArfangStation;
  stationCoordinates: ArfangStationCoordinates;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private stationWsService: StationWsService,
              private alertService: AlertService) {
    this.stationId = Number(activatedRoute.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.loadStation();
  }

  async loadStation() {
      try {
        this.station = await this.stationWsService.getStation(this.stationId);
        this.stationCoordinates = await this.stationWsService.getStationCoordinates(this.stationId);
      } catch (arfangWsError) {
        this.alertService.error(arfangWsError);
      }
  }

  get ready() {
    return this.station != null && this.stationCoordinates != null && this.station.id === this.stationId;
  }

  async save() {
    try {
      await this.stationWsService.editStationCoordinates(this.stationId, this.stationCoordinates);
      await this.router.navigateByUrl('/stations');
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
