import { Component, OnInit } from '@angular/core';
import {WavReplayWsService} from '../../services/arfang-ws/wav-replay-ws.service';
import {AlertService} from '../../services/alert.service';
import {WavReplayStation} from '../../services/arfang-ws/wav-replay-model';

@Component({
  selector: 'app-infrasonic-event-example',
  templateUrl: './infrasonic-event-example.component.html',
  styleUrls: ['./infrasonic-event-example.component.scss']
})
export class InfrasonicEventExampleComponent implements OnInit {

  replayStations: WavReplayStation[] = [];
  selectedReplayStation: WavReplayStation;

  constructor(private wavReplayWsService: WavReplayWsService,
              private alertService: AlertService) {
  }

  async ngOnInit() {
    await this.retrieveReplayStations();
    if (this.replayStations.length > 0) {
      this.selectedReplayStation = this.replayStations[0];
    }
  }

  private async retrieveReplayStations() {
    try {
      this.replayStations = await this.wavReplayWsService.getReplayStations();
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
