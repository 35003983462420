import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  enabled = false;

  constructor() { }

  toggle() {
    this.enabled = !this.enabled;
  }
}
