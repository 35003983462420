<form [formGroup]="accountForm" (ngSubmit)="create()">
  <div class="container">
    <div class="row">
      <div class="col-7">
        <mat-form-field>
          <mat-label>New account name</mat-label>
          <input matInput formControlName="name" placeholder="New account name">
        </mat-form-field>
      </div>
      <div class="col-5">
          <button mat-raised-button color="primary" type="submit" style="margin-top: 8px">Create</button>
      </div>
    </div>
  </div>
</form>

<div class="table-wrapper">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
