import {Component, OnInit} from '@angular/core';
import {UserWsService} from '../../services/arfang-ws/user-ws.service';
import {AuthService} from '../../services/auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  passwordForm = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', Validators.required),
    newPassword1: new UntypedFormControl('', Validators.required),
    newPassword2: new UntypedFormControl('', Validators.required)
  });

  constructor(private userWsService: UserWsService,
              readonly authService: AuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  async save(): Promise<void> {
    if (!this.passwordForm.valid) {
      this.alertService.success('Please complete all the fields');
      return;
    } else if (this.passwordForm.controls.newPassword1.value !== this.passwordForm.controls.newPassword2.value) {
      this.alertService.success('Please check your new password');
      return;
    }
    try {
      await this.userWsService.putNewPassword({
        currentPassword: this.passwordForm.controls.currentPassword.value,
        newPassword: this.passwordForm.controls.newPassword1.value
      });
      this.alertService.success('Password changed');
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
