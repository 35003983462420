import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {AlertService} from '../../services/alert.service';
import {StationWsService} from '../../services/arfang-ws/station-ws.service';
import {ArfangStation} from '../../common/stations/arfang-station';
import {MatSort} from '@angular/material/sort';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
})
export class StationsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<ArfangStation>([]);
  columns = ['name', 'accountName', 'nbSensors', 'latitude', 'longitude', 'altitude', 'actions'];

  constructor(private stationWsService: StationWsService,
              private alertService: AlertService,
              readonly authService: AuthService) {
  }

  async ngOnInit() {
    await this.refreshTable();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  async refreshTable() {
    try {
      this.dataSource.data = await this.stationWsService.getStations();
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
