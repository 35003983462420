import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthWsService} from '../arfang-ws/auth-ws.service';
import {ArfangToken} from './arfang-token';
import {AlertService} from '../alert.service';
import {ArfangWsError} from '../arfang-ws/arfang-ws.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private decodedToken: ArfangToken;
  private helper = new JwtHelperService();

  constructor(private alertService: AlertService, private authWsService: AuthWsService) {
    const token = AuthService.localToken;
    this.decodeToken(token);
  }

  private decodeToken(token: string) {
    this.decodedToken = token === null ? null : this.helper.decodeToken(token) as ArfangToken;
  }

  async login(username: string, password: string) {
    const response = await this.authWsService.login(username, password);
    localStorage.setItem('arfangUserToken', response.accessToken);
    this.decodeToken(response.accessToken);
  }

  impersonate(token: string): void {
    localStorage.setItem('arfangUserToken', token);
    this.decodeToken(token);
  }

  logout(): void {
    localStorage.removeItem('arfangUserToken');
  }

  get authToken() {
    return AuthService.localToken;
  }

  get arfangToken() {
    this.decodeToken(AuthService.localToken);
    return this.decodedToken;
  }

  private static get localToken(): string {
    return localStorage.getItem('arfangUserToken');
  }

  get authenticated() {
    const token = AuthService.localToken;
    if (token === null) {
      return false;
    }
    if (this.helper.getTokenExpirationDate(token).valueOf() < new Date().valueOf()) {
      this.logout();
      this.alertService.error(new ArfangWsError(null, 'Session expired, please log in again.'));
      return false;
    }
    return true;
  }

  get userId() {
    this.decodeToken(AuthService.localToken);
    return Number(this.decodedToken.sub);
  }

  get email() {
    this.decodeToken(AuthService.localToken);
    return this.decodedToken.email;
  }

  get rank(): ArfangRank {
    const token = AuthService.localToken;
    this.decodeToken(token);
    if (this.decodedToken.admin) {
      return ArfangRank.ADMIN;
    } else if (this.decodedToken.manager) {
      return ArfangRank.MANAGER;
    } else {
      return ArfangRank.USER;
    }
  }

  get isAdmin() {
    const token = AuthService.localToken;
    this.decodeToken(token);
    return this.decodedToken?.admin;
  }

  get isManager() {
    const token = AuthService.localToken;
    this.decodeToken(token);
    return this.decodedToken?.manager;
  }

}

export enum ArfangRank {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER'
}

