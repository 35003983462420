export class BallisticUtils {

  private static readonly SOUND_SPEED = 340;

  static v(v0: number, k: number, kSubsonic: number, d: number): number {
    const tAtSoundSpeed = this.t(v0, k, this.SOUND_SPEED);
    const dAtSoundSpeed = this.d(v0, k, tAtSoundSpeed);
    if (d < dAtSoundSpeed) {
      const t = this.tWithD(v0, k, d);
      return this.vWithV0KT(v0, k, t);
    } else {
      const t = this.tWithD(this.SOUND_SPEED, kSubsonic, d - dAtSoundSpeed);
      return this.vWithV0KT(this.SOUND_SPEED, kSubsonic, t);
    }
  }

  private static vWithV0KT(v0: number, k: number, t: number): number {
    return v0 / (1 + k * t);
  }

  private static t(v0: number, k: number, v: number): number {
    return (v0 - v) / (v * k);
  }

  private static d(v0: number, k: number, t: number): number {
    return v0 * Math.log(k * t + 1) / k;
  }

  private static tWithD(v0: number, k: number, d: number): number {
    return (Math.exp(k * d / v0) - 1.0) / k;
  }

}
