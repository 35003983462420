import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArfangEvent} from '../../common/events/arfang-event-model';
import {BehaviorSubject} from 'rxjs';
import {ArfangStation} from '../../common/stations/arfang-station';
import {StationService} from '../../common/stations/station.service';
import {ArfangObservation} from './arfang-observation';
import {AlertService} from '../../services/alert.service';
import {EventWsService} from '../../services/arfang-ws/event-ws.service';
import {DebugService} from '../../services/debug.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  arfangEvent: ArfangEvent;
  eventID: number;
  eventsList: number[];
  displayObservationForm = false;
  displayButtonsNextPrevious = false;
  eventToFocusSubject: BehaviorSubject<number>;
  stationsToDisplaySubject = new BehaviorSubject<ArfangStation[]>([]);
  rawData = '';

  constructor(private activatedRoute: ActivatedRoute,
              private eventWsService: EventWsService,
              private readonly stationService: StationService,
              private router: Router,
              private alertService: AlertService,
              readonly debugService: DebugService) {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    this.eventID = +this.router.url.split('/')[2].split('?')[0];
    this.eventsList = [];
    if (paramMap.get('idslist') !== null) {
      this.displayButtonsNextPrevious = true;
      this.eventsList = paramMap.get('idslist').split(',').map(val => +val);
    }
    this.eventToFocusSubject = new BehaviorSubject<number>(this.eventID);
  }

  private async updateEvent(): Promise<void> {
    this.arfangEvent = await this.eventWsService.getEventDetails(this.eventID);
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(async (newURL) => {
      this.eventID = +newURL[1];
      await this.updateEvent();
      this.eventToFocusSubject.next(this.eventID);
      const stations = await this.stationService.getStationsList();
      const arfangStation = stations.find(station => station.id === this.arfangEvent.eventSummary.stationId);
      this.stationsToDisplaySubject.next([arfangStation]);
    });
  }

  async next(): Promise<void> {
    if (this.eventsList.indexOf(+this.eventID) + 1 < this.eventsList.length) {
      await this.router.navigate(['event', this.eventsList[this.eventsList.indexOf(this.eventID) + 1]], {queryParamsHandling: 'preserve'});
    }
  }

  async previous(): Promise<void> {
    if (this.eventsList.indexOf(+this.eventID) - 1 >= 0) {
      await this.router.navigate(['event', this.eventsList[this.eventsList.indexOf(this.eventID) - 1]], {queryParamsHandling: 'preserve'});
    }
  }

  onUpdatedObservation(newObservation: ArfangObservation): void {
    if (newObservation.comment !== this.arfangEvent.eventSummary.observationComment ||
      newObservation.type !== this.arfangEvent.eventSummary.observationType) {
      this.arfangEvent.eventSummary.observationType = newObservation.type;
      this.arfangEvent.eventSummary.observationComment = newObservation.comment;
    }
    this.displayObservationForm = false;
  }

}
