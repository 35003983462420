import {Injectable} from '@angular/core';
import {ArfangWsService} from './arfang-ws.service';
import {ArfangStation, ArfangStationCoordinates, ArfangStationHistoryData} from '../../common/stations/arfang-station';

@Injectable({
  providedIn: 'root'
})
export class StationWsService {

  private readonly ENDPOINT: string = 'station';

  constructor(private arfangWsService: ArfangWsService) {
  }

  getStations(): Promise<ArfangStation[]> {
    return this.arfangWsService.get<ArfangStation[]>(this.ENDPOINT, {});
  }

  getStation(stationId: number): Promise<ArfangStation> {
    return this.arfangWsService.get<ArfangStation>(this.ENDPOINT + '/' + stationId, {});
  }

  getStationCoordinates(stationID: number): Promise<ArfangStationCoordinates> {
    return this.arfangWsService.get<ArfangStationCoordinates>(this.ENDPOINT + '/' + stationID + '/coordinates', {});
  }

  editStationCoordinates(stationID: number, stationCoordinates: ArfangStationCoordinates): Promise<void> {
    return this.arfangWsService.put<void>(this.ENDPOINT + '/' + stationID + '/coordinates', stationCoordinates);
  }

  getHistoryData(stationId: number, epochSecond: number): Promise<ArfangStationHistoryData> {
    return this.arfangWsService.get<ArfangStationHistoryData>(this.ENDPOINT + '/' + stationId + '/historyData', {epochSecond});
  }

}
