<mat-card>
  <!--  <div class="row justify-content-between">-->
  <!--    <mat-form-field>-->
  <!--      <input matInput title="Type to filter table events" (keyup)="dataSource.filter = $event.target.value"-->
  <!--             placeholder="Quick filter">-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <h5 *ngIf="!!eventsToDisplay">Total : {{eventsToDisplay.length}}</h5>
      </div>
      <div class="offset-7 col-md-2">
        <button class="save-as-button" mat-button (click)="exportData.emit()">
          Save table as CSV
        </button>
      </div>
    </div>
    <div class="table-wrapper">
      <table mat-table #eventsSort="matSort" matSort [dataSource]="dataSource">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let event">{{event.id}}</td>
        </ng-container>

        <ng-container matColumnDef="dateTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
          <td mat-cell *matCellDef="let event"
              title="Event start date"> {{event.dateTime | date:'YYYY-MM-dd HH:mm:ss.SSS'}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration [s]</th>
          <td mat-cell *matCellDef="let event" title="Event duration"> {{event.duration}} </td>
        </ng-container>

        <ng-container matColumnDef="prognosis">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="head"> Prognosis</th>
          <td mat-cell *matCellDef="let event"
              [title]="'Event prognosis: ' + filterService.getPrognosisProperty('viewValue', event.prognosis)"
              [ngStyle]="{color: filterService.getPrognosisProperty('color', event.prognosis)}">
            <div class="prognosis">
              {{event.prognosis}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sector">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sector</th>
          <td mat-cell *matCellDef="let event"
              [title]="'Event azimuts (start,end): ' + event.azimuthStart + ',' + event.azimuthEnd">
            <app-svg-sector [event]="event"></app-svg-sector>
          </td>
        </ng-container>

        <ng-container matColumnDef="magnitude">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Magnitude</th>
          <td mat-cell *matCellDef="let event" title="Event magnitude">
            <app-svg-magnitude [event]="event"></app-svg-magnitude>
          </td>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let event">
            <button mat-raised-button title="View this event" (click)="view(event)"> View</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" (click)="view(row)" class="event-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</mat-card>
