import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {interval, Observable, Subscription} from 'rxjs';
import {ScopeService} from '../scope.service';
import {SpectrogramData} from '../../websocket-service/packets/SpectrogramData';
import {SpectrogramPacket} from '../../websocket-service/packets/SpectrogramPacket';
import {ArfangStation} from '../../../../../common/stations/arfang-station';
import {ShotDetectionSummary} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';
import {Router} from '@angular/router';
import {ShotDetectionWsService} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws.service';

@Component({
  selector: 'app-waterfall',
  templateUrl: './waterfall.component.html',
  styleUrls: ['./waterfall.component.scss']
})
export class WaterfallComponent implements OnInit, OnDestroy, OnChanges {

  @Input() station: ArfangStation;
  @Input() dataReceived: Observable<SpectrogramPacket>;
  @Input() infrasonicEventExampleMode = false;
  @Input() staticMode = false;
  @Input() autoscale: boolean;

  private dataReceivedSubscription: Subscription;
  private buffer: SpectrogramData[] = [];
  private activityTimerSubscription: Subscription;
  secondsSinceLastActivity = 0;
  graphConfig: { layout: any, data: any[], config: any };
  shotDetectionsWithPositions: ShotDetectionWithPosition[];
  shotDetectionsQueryParams: any;

  constructor(readonly scopeService: ScopeService,
              private shotDetectionWsService: ShotDetectionWsService,
              private router: Router) {
    this.activityTimerSubscription = interval(1000)
      .subscribe(() => this.activityTimer());
  }

  ngOnInit() {
    this.initGraph();
    this.dataReceivedSubscription = this.dataReceived.subscribe(data => {
      if (this.staticMode) {
        this.clearWaterfall();
        this.updateXAxis(data.staticModeEpochSecond);
      }
      data.spectrogramData.forEach(spectroData => this.refreshWaterfall(spectroData));
      this.loadShotDetections(data.staticModeEpochSecond);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('station') && !this.staticMode) {
      this.clearWaterfall();
    } else if (changes.hasOwnProperty('autoscale')) {
      if (this.graphConfig?.data[0] !== undefined) {
        this.graphConfig.data[0].zauto = this.autoscale;
      }
    }
  }

  ngOnDestroy() {
    this.dataReceivedSubscription.unsubscribe();
    this.activityTimerSubscription.unsubscribe();
  }

  private activityTimer() {
    this.secondsSinceLastActivity++;
  }

  private initGraph() {
    this.graphConfig = {
      layout: {},
      data: [],
      config: this.scopeService.getPlotlyConfig()
    };
    const font = {
      size: 13
    };
    this.graphConfig.layout = {
      showlegend: false,
      showscale: false,
      height: 376,
      margin: {l: 70, r: 25, t: 10, b: 25, autoexpand: false},
      xaxis: {
        fixedrange: true,
        autorange: false,
        range: [ScopeService.WATERFALL_FRAMES_BUFFER - 1, 0],
        type: 'date',
        tickvals: [],
        ticktext: [],
        showticklabels: true
      },
      yaxis: {
        fixedrange: true,
        autorange: false,
        title: {
          text: 'Frequency (Hz)',
          font
        },
        // range: [0, 35],
        range: [0, 8000],
        ticksuffix: '     ',
      },
      font
    };
    this.graphConfig.data.push({
      y: this.scopeService.generateFrequencies(),
      z: [this.scopeService.generateFrequencies()],
      type: 'heatmapgl',
      zauto: this.autoscale,
      zmin: -44, // -50 + 20%
      zmax: 12, // 30 - 10%
      zsmooth: false,
      transpose: true,
      hoverinfo: 'skip',
      showscale: false,
      colorscale: [
        [0.0, '#00008f'],
        [0.25, '#0338ff'],
        [0.5, '#00fff5'],
        [0.75, '#ffff00'],
        [1.0, '#ff0000']
      ]
    });
  }

  private clearWaterfall(staticModeEpochSecond?: number) {
    this.buffer = [];
    if (this.graphConfig?.data[0] !== undefined) {
      this.graphConfig.data[0].z = [this.scopeService.generateFrequencies()];
    }
  }

  private refreshWaterfall(data: SpectrogramData) {
    this.secondsSinceLastActivity = 0;
    while (this.buffer.length >= ScopeService.WATERFALL_FRAMES_BUFFER) {
      this.buffer.shift();
    }
    this.buffer.push(data);
    this.graphConfig.data[0].x = [];
    this.graphConfig.data[0].z = [];
    const limitTime = data.startTime - ScopeService.WATERFALL_FRAMES_BUFFER * ScopeService.FRAME_DURATION_MS;
    this.buffer.forEach(frame => {
      if (frame.values.length !== 0 && frame.startTime > limitTime) {
        this.graphConfig.data[0].x.push(frame.startTime);
        this.graphConfig.data[0].z.push(frame.values);
      }
    });
  }

  private updateXAxis(staticModeEpochSecond?: number) {
    if (staticModeEpochSecond === undefined) {
      return;
    }
    const xaxisRange = [(staticModeEpochSecond - 150) * 1000, (staticModeEpochSecond + 150) * 1000];
    const tickInterval = 60;
    const xStart = Math.round(xaxisRange[0] / 1000);
    let ticksTimestamps: number[];
    if (xStart % tickInterval === 0) {
      ticksTimestamps = [
        xStart,
        xStart + tickInterval,
        xStart + tickInterval * 2,
        xStart + tickInterval * 3,
        xStart + tickInterval * 4,
        xStart + tickInterval * 5,
        xStart + tickInterval * 6
      ];
    } else {
      const ticksStart = xStart + tickInterval - xStart % tickInterval;
      ticksTimestamps = [
        ticksStart,
        ticksStart + tickInterval,
        ticksStart + tickInterval * 2,
        ticksStart + tickInterval * 3,
        ticksStart + tickInterval * 4,
        ticksStart + tickInterval * 5
      ];
    }
    this.graphConfig.layout.xaxis.range = xaxisRange.map(date => new Date(date));
    const ticks = ticksTimestamps
      .map(timestamp => new Date(timestamp * 1000));
    this.graphConfig.layout.xaxis.tickvals = ticks;
    this.graphConfig.layout.xaxis.ticktext = ticks.map(date => `${date.getHours().toString().padStart(2, '0')}:` +
      `${date.getMinutes().toString().padStart(2, '0')}:` +
      `${date.getSeconds().toString().padStart(2, '0')}`
    );
  }

  private async loadShotDetections(staticModeEpochSecond: number): Promise<void> {
    this.shotDetectionsWithPositions = [];
    this.shotDetectionsQueryParams = {
      stations: [this.station.name],
      minDateTime: new Date((staticModeEpochSecond - 150) * 1000).toISOString(),
      maxDateTime: new Date((staticModeEpochSecond + 150) * 1000).toISOString()
    };
    const shotDetections = await this.shotDetectionWsService.getShotDetections(this.shotDetectionsQueryParams);
    this.shotDetectionsWithPositions = shotDetections
        ?.map(shotDetection => ({
          shotDetection,
          position: (Date.parse(shotDetection.shotInstant) / 1000 - staticModeEpochSecond + 150) / 300
        }))
      ?? [];
  }

  shotDetectionClicked(shotDetection: ShotDetectionSummary) {
    const shotDetectionIndex = this.shotDetectionsWithPositions.findIndex(s => s.shotDetection === shotDetection);
    this.router.navigate(['/shot-detection'], {
      queryParams: {
        shotDetectionIndex,
        ...this.shotDetectionsQueryParams
      },
    });
  }

}

interface ShotDetectionWithPosition {
  shotDetection: ShotDetectionSummary;
  position: number;
}
