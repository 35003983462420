export class LngLatUtils {

  private static readonly earthRadiusM = 6371000;

  static dXYm2dLngLat(dX: number, dY: number, latitude: number): [number, number] {
    const dLng = dX / this.earthRadiusM * 180 / Math.PI / Math.cos(latitude * Math.PI / 180);
    const dLat = dY / this.earthRadiusM * 180 / Math.PI;
    return [dLng, dLat];
  }

  static dLngLat2dXYm(dLng: number, dLat: number, latitude: number): [number, number] {
    const dX = dLng * this.earthRadiusM * Math.PI / 180 * Math.cos(latitude * Math.PI / 180);
    const dY = dLat * this.earthRadiusM * Math.PI / 180;
    return [dX, dY];
  }

  static lngLatAtAngleAndDistance(lngLat: [number, number], angle: number, distance: number): [number, number] {
    const angleRad = (90 - angle) * Math.PI / 180.0;
    const dX = distance * Math.cos(angleRad);
    const dY = distance * Math.sin(angleRad);
    const dLngLat = this.dXYm2dLngLat(dX, dY, lngLat[1]);
    return [lngLat[0] + dLngLat[0], lngLat[1] + dLngLat[1]];
  }

}
