import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-password-proxy',
  template: '',
})
export class PasswordProxyComponent {

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    const token = activatedRoute.snapshot.queryParamMap.get('token');
    if (!token) {
      router.navigateByUrl('/');
    }
    if (router.url.includes('createPassword')) {
      router.navigateByUrl(`/password?resetMode=0&token=${token}`);
    } else if (router.url.includes('resetPassword')) {
      router.navigateByUrl(`/password?resetMode=1&token=${token}`);
    } else {
      router.navigateByUrl('/');
    }
  }

}
