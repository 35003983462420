import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {StationWsService} from '../../services/arfang-ws/station-ws.service';
import {ArfangStation} from '../../common/stations/arfang-station';
import {SpectrogramData} from './live-scope-session/websocket-service/packets/SpectrogramData';
import {ArfangRank, AuthService} from '../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './livescope.component.html',
  styleUrls: ['./livescope.component.scss']
})
export class LivescopeComponent implements OnInit {

  stations: ArfangStation[] = [];
  station: ArfangStation;
  readonly isAdmin: boolean;
  staticCoherenceSpectrogramDatas: SpectrogramData[] = [];
  staticAzimuthCorrelogramDatas: SpectrogramData[] = [];
  staticModeEpochSecond: number = null;
  private readonly minEpochSecond = Math.round(Date.UTC(2023, 4, 15, 7, 0) / 1000);
  private readonly maxEpochSecond = Math.round(Date.UTC(2023, 4, 19, 12, 30) / 1000);

  constructor(private stationWsService: StationWsService,
              private alertService: AlertService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              authService: AuthService) {
    this.isAdmin = authService.rank === ArfangRank.ADMIN;
  }

  async ngOnInit(): Promise<void> {
    await this.retrieveStations();
    this.loadStation();
    this.loadStaticModeEpochSecond();
    await this.advance(0);
  }

  private async retrieveStations(): Promise<void> {
    try {
      this.stations = await this.stationWsService.getStations();
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

  stationSelectionChanged() {
    this.updateStaticData();
  }

  private async updateStaticData() {
    const historyData = await this.stationWsService.getHistoryData(this.station.id, this.staticModeEpochSecond);
    this.staticCoherenceSpectrogramDatas = historyData.coherenceSpectrogramDatas;
    this.staticAzimuthCorrelogramDatas = historyData.azimuthCorrelogramDatas;
    this.saveStationAndDateTime();
  }

  async advance(seconds: number) {
    this.staticModeEpochSecond += seconds;
    if (this.staticModeEpochSecond < this.minEpochSecond) {
      this.staticModeEpochSecond = this.minEpochSecond;
    }
    if (this.staticModeEpochSecond > this.maxEpochSecond) {
      this.staticModeEpochSecond = this.maxEpochSecond;
    }
    await this.updateStaticData();
  }

  currentPositionDate() {
    return new Date(this.staticModeEpochSecond * 1000);
  }

  private saveStationAndDateTime(): void {
    localStorage.setItem('livescope.station', this.station.name);
    localStorage.setItem('livescope.dateTime', this.currentPositionDate().toISOString());
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {
        station: this.station.name,
        dateTime: this.currentPositionDate().toISOString()
      },
      replaceUrl: true
    });
  }

  private loadStation(): void {
    const stationName = this.activatedRoute.snapshot.queryParams.station
      ?? localStorage.getItem('livescope.station')
      ?? 'TETRA';
    const station = this.stations.find(s => s.name === stationName);
    this.station = station ?? this.stations[0];
  }

  private loadStaticModeEpochSecond(): void {
    const dateTime = this.activatedRoute.snapshot.queryParams.dateTime
      ?? localStorage.getItem('livescope.dateTime')
      ?? '2023-05-15T11:30:00.000Z';
    this.staticModeEpochSecond = Math.round(Date.parse(dateTime) / 1000);
  }

}
