<form [formGroup]="createForm" (ngSubmit)="create()">
  <div class="container-fluid">
    <div class="row" *ngIf="authService.isAdmin">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Account ID</mat-label>
          <input matInput [attr.disabled]="createForm.controls.admin.value?'':null" formControlName="accountId"
                 placeholder="accountId">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" placeholder="firstName">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" placeholder="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" placeholder="email">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <mat-label>Mobile Phone</mat-label>
          <input matInput formControlName="mobilePhone" type="tel" placeholder="mobilePhone">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-slide-toggle (change)="onManagerToggleChange($event)" formControlName="manager">Manager</mat-slide-toggle>
      </div>
      <div class="col-6" *ngIf="authService.isAdmin">
        <mat-slide-toggle (change)="onAdminToggleChange($event)" formControlName="admin">Admin</mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" type="submit" style="margin-top: 16px">Create</button>
      </div>
    </div>
  </div>
</form>
