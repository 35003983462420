import {Injectable} from '@angular/core';
import {StationService} from '../../../../common/stations/station.service';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  constructor(private readonly stationService: StationService) {
    this.plotlyConfig = {
      displayModeBar: false,
      staticPlot: true,
      responsive: true
    };
    this.connectedByUser = false;
    this.liveScopesPtsPerSec = this.DEFAULT_PTS_PER_SEC;
  }
  static readonly FRAME_DURATION_MS: number = 1000;

  static readonly SENSOR_COLORS: string[] = [
    '#cbc710',    '#43fa11',    '#094aee',    '#f22000',
    '#716f09',    '#1d6c08',    '#041e61',    '#570c01',
    '#cbc710',    '#43fa11',    '#094aee',    '#f22000',
    '#716f09',    '#1d6c08',    '#041e61',    '#570c01'
  ];

  static readonly EVENT_FOCUS_COLOR: string = '#ff0000';

  static readonly WATERFALL_FRAMES_BUFFER: number = 300;

  static readonly SENSOR_FRAMES_BUFFER: number = 6;

  static readonly SPECTROGRAPH_WINDOW: number = 2401;

  readonly DEFAULT_PTS_PER_SEC: number = this.getDefaultPointsPerSec();

  private readonly plotlyConfig: {};

  connectedByUser: boolean;
  liveScopesPtsPerSec: number;

  getPlotlyConfig(): {} {
    return this.plotlyConfig;
  }

  private padded(value: number, width: number = 2) {
    let valueString = value.toString();
    while (valueString.length !== width) {
      valueString = `0${valueString}`;
    }
    return valueString;
  }

  displayElapsedTime(timeInSec: number): string {
    let s = '';
    if (timeInSec >= 86400) {
      s += ` ${this.padded(Math.floor(timeInSec / 86400))}) d`;
      timeInSec %= 86400;
    }
    if (timeInSec >= 3600) {
      s += ` ${this.padded(Math.floor(timeInSec / 3600))} h`;
      timeInSec %= 3600;
    }
    if (timeInSec >= 60) {
      s += ` ${this.padded(Math.floor(timeInSec / 60))} m`;
      timeInSec %= 60;
    }
    s += ` ${this.padded(timeInSec)} s`;
    return s;
  }

  generateFrequencies() {
    const maxFrequency = 8000;
    const length = 800;
    const freqs: Array<number> = [];
    for (let i = 0.0; i < length; i += 1.0) {
      freqs.push(i / length * maxFrequency);
    }
    return freqs;
  }

  generateAzimuths() {
   return new Array<number>(360)
     .fill(0)
     .map((_, i) => i);
  }

  generateTimesArray(startTime: number): number[] {
    const timesArr = new Array<number>();
    for (let t = 0; t < this.liveScopesPtsPerSec; t++) {
      timesArr.push(startTime + ScopeService.FRAME_DURATION_MS / this.liveScopesPtsPerSec * t );
    }
    return timesArr;
  }

  generateSensorScopeXTicks(timeStamp: number): Date[] {
    return [
      timeStamp - 4000,
      timeStamp - 2000,
      timeStamp,
    ].map(date => new Date(date));
  }

  private getDefaultPointsPerSec(): number {
    return innerWidth < 500 ? 40 : 80;
  }
}
