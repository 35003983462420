import {DurationMode} from './duration-mode';
import {FilterService} from '../service/filter.service';
import moment from 'moment';

export class Period {

  private readonly dateFormat = 'YYYY-MM-DD';

  durationMode: DurationMode;
  start: number;
  stop: number;
  valid: boolean;

  constructor() {
    this.stop = moment().endOf('day').valueOf();
    this.durationMode = FilterService.DEFAULT_DURATIONMODE;
    this.setStartFromStop();
  }

  static from(durationMode: string, minDate: string, maxDate: string): Period {
    const period = new Period();
    period.durationMode = durationMode as DurationMode;
    period.start = moment(minDate, period.dateFormat).startOf('day').valueOf();
    period.stop = moment(maxDate, period.dateFormat).endOf('day').valueOf();
    period.updateValidity();
    return period;
  }

  startDate(): string {
    return moment(this.start).format(this.dateFormat);
  }

  stopDate(): string {
    return moment(this.stop).format(this.dateFormat);
  }

  setStartFromStop() {
    switch (this.durationMode) {
      case DurationMode.DAY:
        this.start = moment(this.stop).startOf('day').valueOf();
        break;
      case DurationMode.CUSTOM:
      case DurationMode.WEEK:
        this.start = moment(this.stop).subtract(6, 'days').startOf('day').valueOf();
        break;
      case DurationMode.MONTH:
        this.start = moment(this.stop).subtract(1, 'months').startOf('day').valueOf();
        break;
      case DurationMode.SIXMONTHS:
        this.start = moment(this.stop).subtract(6, 'months').startOf('day').valueOf();
        break;
    }
    this.updateValidity();
  }

  updateValidity(): void {
    this.valid = moment(this.start).isSameOrBefore(moment(this.stop));
  }

  previous(): void {
    switch (this.durationMode) {
      case DurationMode.DAY:
        this.start = moment(this.start).startOf('day').subtract(1, 'day').valueOf();
        this.stop = moment(this.start).endOf('day').valueOf();
        break;
      case DurationMode.WEEK:
        this.start = moment(this.stop).startOf('isoWeek').subtract(7, 'day').valueOf();
        this.stop = moment(this.start).set('day', 7).endOf('day').valueOf();
        break;
      case DurationMode.MONTH:
        this.start = moment(this.stop).startOf('month').subtract(1, 'month').valueOf();
        this.stop = moment(this.start).endOf('month').valueOf();
        break;
      case DurationMode.SIXMONTHS:
        if (moment(this.start).isSame(moment(this.start).startOf('year'))) {
          this.start = moment(`${moment(this.start).get('year') - 1}-06-01`).valueOf();
          this.stop = moment(this.start).endOf('year').valueOf();
        } else if (moment(this.start).isSameOrBefore(moment(`${moment(this.start).get('year')}-06-01`))) {
          this.start = moment(this.start).startOf('year').valueOf();
          this.stop = moment(`${moment(this.start).get('year')}-06-01`).valueOf();
        } else {
          this.start = moment(`${moment(this.start).get('year')}-06-01`).valueOf();
          this.stop = moment(this.start).endOf('year').valueOf();
        }
        break;
      case DurationMode.CUSTOM:
        const offsetSec = moment(this.stop).diff(moment(this.start), 'second');
        this.start = moment(this.start).subtract(offsetSec, 'second').valueOf();
        this.stop = moment(this.stop).subtract(offsetSec, 'second').valueOf();
        break;
    }
  }

  next(): void {
    switch (this.durationMode) {
      case DurationMode.DAY:
        this.start = moment(this.start).startOf('day').add(1, 'day').valueOf();
        this.stop = moment(this.start).endOf('day').valueOf();
        break;
      case DurationMode.WEEK:
        this.start = moment(this.start).startOf('isoWeek').add(7, 'day').valueOf();
        this.stop = moment(this.start).set('day', 7).endOf('day').valueOf();
        break;
      case DurationMode.MONTH:
        this.start = moment(this.start).startOf('month').add(1, 'month').valueOf();
        this.stop = moment(this.start).endOf('month').valueOf();
        break;
      case DurationMode.SIXMONTHS:
        if (moment(this.start).isBefore(moment(`${moment(this.start).get('year')}-06-01`))) {
          this.start = moment(`${moment(this.start).get('year')}-06-01`).valueOf();
          this.stop = moment(this.start).endOf('year').valueOf();
        } else {
          this.start = moment(this.start).set('year', moment(this.start).get('year') + 1).startOf('year').valueOf();
          this.stop = moment(`${moment(this.start).get('year')}-06-01`).valueOf();
        }
        break;
      case DurationMode.CUSTOM:
        const offsetSec = moment(this.stop).diff(moment(this.start), 'second');
        this.start = moment(this.start).add(offsetSec, 'second').valueOf();
        this.stop = moment(this.stop).add(offsetSec, 'second').valueOf();
        break;
    }
  }

}
