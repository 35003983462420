import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {MenuItem} from './menu-item';
import {DebugService} from '../../services/debug.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Input()
  activatedItem = '';
  menuItems: MenuItem[] = [];

  constructor(readonly authService: AuthService,
              readonly debugService: DebugService) {}

  ngOnInit(): void {
    this.menuItems = [];
    this.menuItems.push({key: 'eventsList', name: 'Events list', routerLink: '/list'});
    this.menuItems.push({key: 'livescope', name: 'Signal viewer', routerLink: '/livescope'});
    this.menuItems.push({key: 'information', name: 'Information', routerLink: '/'});
    // this.menuItems.push({key: 'eventsGraph', name: 'Events graph', routerLink: '/graphs'});
  }

}

