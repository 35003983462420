<app-nav-bar activatedItem="users"></app-nav-bar>

<div class="container page-container user-table">
  <div class="row">
    <div class="col-md-7">
      <mat-card>
        <div class="table-wrapper">
          <table mat-table matSort [dataSource]="dataSource">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
              <td mat-cell *matCellDef="let user">{{user.id}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail</th>
              <td mat-cell *matCellDef="let user">{{user.email}}</td>
            </ng-container>
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
              <td mat-cell *matCellDef="let user">{{user.firstName}}</td>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name</th>
              <td mat-cell *matCellDef="let user">{{user.lastName}}</td>
            </ng-container>
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Name</th>
              <td mat-cell *matCellDef="let user">{{user.accountName}}</td>
            </ng-container>
            <ng-container matColumnDef="impersonate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let user">
                <button mat-raised-button (click)="impersonate(user)" *ngIf="!user.admin"> Impersonate</button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
          </table>
        </div>
      </mat-card>
    </div>

    <div class="col-md-5">
      <mat-card *ngIf="authService.rank !== 'USER'">
        <mat-card-title>Create user</mat-card-title>
        <app-user-create (createdSuccess)="refreshTable()"></app-user-create>
      </mat-card>
      <mat-card *ngIf="authService.rank === 'ADMIN'" style="margin-top: 16px;">
        <mat-card-title>Accounts</mat-card-title>
        <app-account></app-account>
      </mat-card>
    </div>
  </div>
</div>
