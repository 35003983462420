import {Injectable} from '@angular/core';
import {ConfigurationService} from '../configuration.service';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ArfangWsService {

  constructor(
    private configurationService: ConfigurationService,
    private httpClient: HttpClient) {
  }

  url(path: string) {
    return this.configurationService.configuration.serverUrl + '/' + path;
  }

  get<T>(path: string, params?: any): Promise<T> {
    return this.httpClient.get<T>(this.url(path), {params}).toPromise().catch((error) => {
      throw this.transformError(error);
    });
  }

  getBlob(path: string, params: any): Promise<HttpResponse<Blob>> {
    return this.httpClient.get(this.url(path), {params, observe: 'response', responseType: 'blob'}).toPromise().catch((error) => {
      throw this.transformError(error);
    });
  }

  post<T>(path: string, body: any, token?: string): Promise<T> {
    return this.httpClient.post<T>(this.url(path),
      body, token ? {headers: {Authorization: `Bearer ${token}`}} : {}).toPromise().catch((error) => {
      throw this.transformError(error);
    });
  }

  put<T>(path: string, body: any): Promise<T> {
    return this.httpClient.put<T>(this.url(path), body).toPromise().catch((error) => {
      throw this.transformError(error);
    });
  }

  transformError(error: any): ArfangWsError {
    try {
      return new ArfangWsError(error.status, error.error.message);
    } catch (e) {
      return new ArfangWsError(error.status, error.message);
    }
  }

}

export class ArfangWsError {
  constructor(public code: number,
              public message: string) {
  }
}
