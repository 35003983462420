import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import mapboxgl from 'mapbox-gl';
import {ArfangEvent} from '../events/arfang-event-model';
import {ArfangStation} from '../stations/arfang-station';
import {FilterService} from '../events-filter/service/filter.service';
import {EventWsService} from '../../services/arfang-ws/event-ws.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  @Input() eventToFocusReceived: BehaviorSubject<number> | Subject<number>;
  @Input() stationsToDisplayReceived: BehaviorSubject<ArfangStation[]>;

  private focusedEvent: ArfangEvent = null;

  private stationsMarkers: mapboxgl.Marker[];
  private eventLine: string;
  private station: ArfangStation;

  map: mapboxgl.Map;
  mapboxToken = 'pk.eyJ1IjoiYWJvbm9ibyIsImEiOiJjandrcTRjbTgwbjFjNDVuc2M1ZHN6bGNhIn0.-9XGGr9GR5my505vb26w8A';
  mapboxStyle = 'mapbox://styles/mapbox/satellite-v9';

  constructor(private filterService: FilterService,
              private eventWsService: EventWsService) {
    this.stationsMarkers = [];
  }

  ngOnInit(): void {
    this.initMap();
    this.map.on('load', () => {
      this.eventToFocusReceived.subscribe(async (arfangEventID: number) => {
        const focusedEvent = await this.eventWsService.getEventDetails(arfangEventID);
        this.station.latitude = focusedEvent.eventSummary.latitude;
        this.station.longitude = focusedEvent.eventSummary.longitude;
        this.updateMapStations([this.station]);
        this.onNewEventToFocus(focusedEvent);
      });
      this.stationsToDisplayReceived.subscribe((stations: ArfangStation[]) => this.station = stations[0]);
      this.map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');
      this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    });

  }

  ngOnDestroy() {
    try {
      // this.map.destroy();
      this.stationsToDisplayReceived.unsubscribe();
      this.eventToFocusReceived.unsubscribe();
    } catch (e) {
    }
  }

  private initMap(): void {
    mapboxgl.accessToken = this.mapboxToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.mapboxStyle,
    });
  }

  private updateMapStations(stations: ArfangStation[]) {
    if (stations.length === 0) {
      return;
    }
    const ne = [stations[0].longitude, stations[0].latitude];
    const sw = [stations[0].longitude, stations[0].latitude];
    for (const station of stations) {
      if (station.latitude > ne[1]) {
        ne[1] = station.latitude;
      }
      if (station.longitude > ne[0]) {
        ne[0] = station.longitude;
      }
      if (station.latitude < sw[1]) {
        sw[1] = station.latitude;
      }
      if (station.longitude < sw[0]) {
        sw[0] = station.longitude;
      }
    }
    this.stationsMarkers.forEach(marker => marker.remove());
    for (const station of stations) {
      const stationInfos = new mapboxgl.Popup()
        .setHTML(this.generateStationInfos(station));
      this.stationsMarkers.push(
        new mapboxgl.Marker({color: '#f22'})
          .setLngLat([station.longitude, station.latitude])
          .setPopup(stationInfos)
          .addTo(this.map));
    }
  }

  private plotEvent(arfangEvent: ArfangEvent): void {
    this.focusedEvent = arfangEvent;

    const vectors = this.getCoords(arfangEvent);
    this.map.addSource(arfangEvent.eventSummary.id.toString(), {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {
          description: this.generateEventDescription(arfangEvent),
        },
        geometry: {
          type: 'LineString',
          coordinates: vectors
        }
      }
    });
    this.map.addLayer({
      id: arfangEvent.eventSummary.id.toString(),
      type: 'line',
      source: arfangEvent.eventSummary.id.toString(),
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': this.filterService.getPrognosisProperty('color', arfangEvent.eventSummary.prognosis),
        'line-width': 4
      }
    });
    this.map.on('click', arfangEvent.eventSummary.id.toString(), (e) => {
      const description = e.features[0].properties.description;
      const position = e.lngLat;
      new mapboxgl.Popup()
        .setLngLat(position)
        .setHTML(description)
        .addTo(this.map);
    });
    this.eventLine = arfangEvent.eventSummary.id.toString();
    const cameraOffset = 0.001;
    this.map.fitBounds([
      [arfangEvent.eventSummary.longitude - cameraOffset, arfangEvent.eventSummary.latitude - cameraOffset],
      [arfangEvent.eventSummary.longitude + cameraOffset, arfangEvent.eventSummary.latitude + cameraOffset]
    ], {animate: false});
  }

  private getCoords(arfangEvent: ArfangEvent): number[][] {
    const vectors: number[][] = [];
    const distanceFromStation = 0.01;
    const eventVectors = arfangEvent.event.vectors;
    for (const vec of eventVectors) {
      vectors.push([arfangEvent.eventSummary.longitude, arfangEvent.eventSummary.latitude]);
      vectors.push([arfangEvent.eventSummary.longitude + distanceFromStation * Math.cos((90 - vec.azimuth) * Math.PI / 180),
        arfangEvent.eventSummary.latitude + distanceFromStation * Math.sin((90 - vec.azimuth) * Math.PI / 180)]);
    }
    return vectors;
  }

  private onNewEventToFocus(event: ArfangEvent) {
    if (this.focusedEvent?.eventSummary.id === event.eventSummary.id) {
      this.unPlotCurrentEvent();
    } else if (this.focusedEvent === null) {
      this.plotEvent(event);
    } else {
      this.unPlotCurrentEvent();
      this.plotEvent(event);
    }
  }

  unPlotCurrentEvent(): void {
    if (this.focusedEvent === null || this.eventLine === '') {
      return;
    }
    this.map.removeLayer(this.eventLine);
    this.map.removeSource(this.eventLine);
    this.eventLine = '';
    this.focusedEvent = null;
  }

  private generateStationInfos(station: ArfangStation): string {
    let infos = '';
    infos += `<h3>${station.name}</h3>`;
    infos += `<ul><li>Sample rate: ${station.sampleRate} Hz</li>`;
    infos += `<li>${station.nbSensors} sensors</li>`;
    infos += `<li>Latitude: ${station.latitude}</li>`;
    infos += `<li>Longitude: ${station.longitude}</li>`;
    infos += `<li>Altitude: ${station.altitude}</li></ul>`;
    return infos;
  }

  private generateEventDescription(event: ArfangEvent): string {
    let s = '';
    s += `<h4>${event.eventSummary.observationComment}</h4>`;
    s += `${new Date(event.eventSummary.date).toLocaleString()}<br>`;
    s += `${event.eventSummary.observationType}<br>`;
    s += `Magnitude: ${event.eventSummary.magnitude}<br>`;
    s += `Prognosis: ${event.eventSummary.prognosis}<br>`;
    return s;
  }
}
