<app-nav-bar></app-nav-bar>

<div id="page-content">
  <app-exova-map></app-exova-map>

  <div id="nav">
    <span class="nav-button pi pi-chevron-left"
          (click)="previousEvent()"
          [class.disable]="this.shotDetectionDetail?.maxResults < 2">
    </span>
    <span>{{this.shotDetectionDetail?.index + 1 | appNumber}}</span>
    /
    <span>{{this.shotDetectionDetail?.maxResults | appNumber}}</span>
    <span class="nav-button pi pi-chevron-right"
          (click)="nextEvent()"
          [class.disable]="this.shotDetectionDetail?.maxResults < 2">
    </span>
  </div>

  <div id="top-left-content" *ngIf="shotDetection">
    <div class="doa-image sw">
      <div class="img-label">High freq. (SW) | {{shotDetection.swDoa | appNumber: '1.0-1'}}°</div>
      <img alt="sw doa image" [src]="swDoaImage">
      <div class="arrow" [style.padding-left]="(shotDetection.swDoa / 360 * 100) + '%'">^</div>
    </div>
    <div class="doa-image mb">
      <div class="img-label">Low freq. (MB) | {{shotDetection.mbDoa | appNumber: '1.0-1'}}°</div>
      <img alt="mb doa image" [src]="mbDoaImage">
      <div class="arrow" [style.padding-left]="(shotDetection.mbDoa / 360 * 100) + '%'">^</div>
    </div>
    <div class="doa-image ref">
      <div class="img-label">Broadband (FOA) | {{shotDetection.refDoa | appNumber: '1.0-1'}}°</div>
      <img alt="ref doa image" [src]="refDoaImage">
      <div class="arrow" [style.padding-left]="(shotDetection.refDoa / 360 * 100) + '%'">^</div>
    </div>
    <div class="mics-image">
      <div class="img-label">Microphones</div>
      <img id="mics-image" alt="mics image" [src]="micsImage" (click)="micsImageVisible = true"/>
      <p-dialog header="Microphones" [(visible)]="micsImageVisible">
        <img [src]="micsImage" alt="microphones">
      </p-dialog>
    </div>
  </div>

  <!--  <div *ngIf="shotDetection">-->
  <!--    <app-livescopesession [station]="shotDetectionDetail.station"-->
  <!--                          [staticMode]="true"-->
  <!--                          [staticModeEpochSecond]="scopeEpochSecond"-->
  <!--                          [staticCoherenceSpectrogramDatas]="shotDetectionDetail.coherenceSpectrogramDatas"-->
  <!--                          [staticAzimuthCorrelogramDatas]="shotDetectionDetail.azimuthCorrelogramDatas"></app-livescopesession>-->
  <!--  </div>-->

  <div id="bottom-right-content" *ngIf="shotDetection">
    <div>
      <div class="tabs">
        <div [class.selected]="currentView === 'SAFE_INFO'"
        (click)="viewSelected('SAFE_INFO')">
          <strong>Single node</strong>
          <br>
          Escape direction
        </div>
        <div [class.selected]="currentView === 'SHOOTER_TRAJ'"
             (click)="viewSelected('SHOOTER_TRAJ')">
          <strong>Single node</strong>
          <br>
          Shooter & Traj.
        </div>
        <div [class.selected]="currentView === 'NETWORK'"
             (click)="viewSelected('NETWORK')">
          <strong>Network of nodes</strong>
          Danger zone
        </div>
      </div>
      <br>
      <strong>
        Scenario {{label}}:
      </strong>
      <br>
      {{fieldTrial.scenario}}, {{fieldTrial.vignette}}
      <br>
      {{fieldTrial.date}} | {{fieldTrial.timeStart}} - {{fieldTrial.timeEnd}} | {{weapon.shotsPerLocation}} shots
      <br>
      Details :
      <ng-container *ngIf="!fieldTrial.details">-</ng-container>
      <ng-container *ngIf="fieldTrial.details">{{fieldTrial.details}}</ng-container>
      <br>
      <br>
      Shooter : {{realShot.shooter}} ({{realShot.shooterLat | appNumber:'.6-6'}}, {{realShot.shooterLng | appNumber:'.6-6'}})
      <br>
      Target : {{realShot.target}}
      <br>
      <span style="margin-left: 71px">({{realShot.targetLat | appNumber:'.6-6'}}, {{realShot.targetLng | appNumber:'.6-6'}})</span>
      <br>
      <br>
      <strong>{{weapon.weaponType}}</strong>
      <ng-container *ngIf="weapon.silencer"> (Silencer)</ng-container>
      <br>
      {{weapon.model}} {{weapon.caliber}}
      <br>
      <a href="javascript:void(0)" (click)="ballisticModelImageVisible = true">Show ballistic model</a>
      <p-dialog header="Balllistic model" [(visible)]="ballisticModelImageVisible">
        <img [src]="ballisticModelImage" alt="balllistic model">
      </p-dialog>
      <br>
      <br>
      <hr>
      <br>
      <strong>Sensor : {{shotDetection.stationName}}</strong>
      <br>
      Position :
      {{shotDetection.sensorLat | appNumber:'.6-6'}}
      {{shotDetection.sensorLng | appNumber:'.6-6'}}
      (Alt : {{shotDetection.sensorAltitude}}m)
      <br>
      Heading : {{shotDetection.sensorHeading | appNumber: '.0-0'}}°,
      Speed : {{shotDetection.sensorSpeed | appNumber: '.0-3'}} km/h
      <br>
      <br>
      <strong>Detection :</strong>
      (<a href="javascript:void(0)" (click)="rawDataVisible = true">Raw data</a>)
      (<a href="javascript:void(0)" (click)="viewOnSignalViewer()">View on signal viewer</a>)
      (<a href="javascript:void(0)" (click)="reportVisible = true">Report</a>)
      <p-dialog header="Raw data" [(visible)]="rawDataVisible">
        <pre [innerHTML]="shotDetection | json"></pre>
      </p-dialog>
      <p-dialog id="report-dialog" header="Report" [(visible)]="reportVisible">
        <iframe title="Report" [src]="reportUrl"></iframe>
      </p-dialog>
      <br>
      Shot time : {{shotDetection.shotInstant | appDateTime}}
      <br>
      {{shotDetection.shotType}}, {{shotDetection.swMbInfo}}
      <br>
      Sensor to Shooter :
      {{shotDetection.shooterAngle | appNumber: '.0-0'}}°
      {{shotDetection.shooterDistance | appNumber: '.0-0'}}m
      (Expected :
      {{shotDetection.expectedShooterAngle | appNumber: '.0-0'}}°
      {{shotDetection.expectedShooterDistance | appNumber: '.0-0'}}m)
      <br>
      Shooter to Target :
      {{shotDetection.shooterToTargetAngle | appNumber: '.0-0'}}°
      (Expected :
      {{shotDetection.expectedShooterToTargetAngle | appNumber: '.0-0'}}°
      {{shotDetection.expectedShooterToTargetDistance | appNumber: '.0-0'}}m)
      <br>
      Shooter to Fermat :
      {{shotDetection.shooterToFermatAngle | appNumber: '.0-0'}}°
      {{shotDetection.shooterToFermatDistance | appNumber: '.0-0'}}m
      <br>
      Shooter to Danger :
      {{shotDetection.shooterToDangerAngle | appNumber: '.0-0'}}°
      {{shotDetection.shooterToDangerDistance | appNumber: '.0-0'}}m
      <br>
      Fermat angle :
      {{shotDetection.fermatAngle | appNumber: '.0-0'}}°
      <br>
      Safe info : {{shotDetection.safeMessage}}
      <br>
      Safe angle / aperture :
      {{shotDetection.safeAngle | appNumber: '.0-0'}}°
      /
      {{shotDetection.safeAperture | appNumber: '.0-0'}}°
      <br>
      STA 1ms max / LTA 20 ms min :
      {{shotDetection.sta1msMax | appNumber: '.0-0'}}
      /
      {{shotDetection.lta20msMin | appNumber: '.0-0'}}
      <br>
      Δt (MB-SW) :
      {{deltaSwMb | appNumber: '.0-0'}} ms
      (Expected : {{expectedDeltaSwMb | appNumber: '.0-0'}} ms)
      <br>
      <br>
      Normalized 10s audio :
      <app-audio-reader [audioUrlForPlay]="normalized10sWavForPlayingUrl"
                        [audioUrlForDownload]="normalized10sWavUrl"
                        [audioFileName]="shotDetection.stationName + '_' + shotDetection.id + '_normalized-10s.wav'">
      </app-audio-reader>
      <br>
      Raw 1s audio (Start : {{shotDetection.frameStartInstant | appTime}}) :
      <app-audio-reader [audioUrlForPlay]="rawFrameWavForPlayingUrl"
                        [audioUrlForDownload]="rawFrameWavUrl"
                        [audioFileName]="shotDetection.stationName + '_' + shotDetection.id + '_raw-frame.wav'">
      </app-audio-reader>
    </div>
  </div>
</div>
