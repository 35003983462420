import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';

@Pipe({
  name: 'appDateTime'
})
export class AppDateTimePipe implements PipeTransform {

  transform(value: Date | string | null): string {
    if (value === null) {
      return '-';
    }
    return formatDate(value, 'dd.MM.yyyy HH:mm:ss.SSS', 'en-us');
  }

}
