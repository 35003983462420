<div class="container-fluid liveScopeSession">
  <div class="row justify-content-end">
    <div class="col-md-4" style="text-align: right">
      <div class="server-connection" *ngIf="!infrasonicEventExampleMode && !staticMode">
        <p>Server connection :</p>
        <div class="indicator" [ngStyle]="{'background-color': this.online ? '#25e02b' : '#f22'}"></div>
      </div>
      <div *ngIf="!this.online && station !== undefined && scopeService.connectedByUser && !staticMode" class="loading">
        <img src="/assets/load.gif" alt="Loading animation">
        <p class="loadingText" style="display: inline">Reconnecting...</p>
      </div>
    </div>
    <!--div class="col-md-2 pointsPerSec">
      <p>Sensors pts/sec : </p>
      <mat-form-field>
        <div class="mat-select-value-text">
          <mat-select [(value)]="scopeService.liveScopesPtsPerSec"
                      (selectionChange)="onPtsPerSecChange()"
                      class="mat-select-value-text">
            <mat-option [value]="10">10</mat-option>
            <mat-option [value]="20">20</mat-option>
            <mat-option [value]="40">40</mat-option>
            <mat-option [value]="80">80</mat-option>
          </mat-select>
        </div>
      </mat-form-field>
    </div>
    <div class="col-md-2 offset-2">
      <button mat-raised-button (click)="connect()"
              [disabled]="station === undefined || online">
        Connect
      </button>
      <button mat-raised-button (click)="disconnect()"
              [disabled]="station === undefined || !online">
        Disconnect
      </button>
    </div-->
  </div>
  <div class="livescope-options">
    <mat-checkbox [(ngModel)]="autoscale">Autoscale</mat-checkbox>
  </div>
  <div class="row spectrogram"
       *ngIf="online && station && displaySpectrogram && !infrasonicEventExampleMode && !staticMode">
    <div class="col-md">
      <app-spectrograph [station]="station" [dataReceived]="spectrographDataReceived"></app-spectrograph>
    </div>
  </div>
  <div class="row waterfall" *ngIf="(online && station) || staticMode">
    <div class="col-md">
      <app-waterfall [station]="station" [dataReceived]="waterfallDataReceived"
                     [infrasonicEventExampleMode]="infrasonicEventExampleMode"
                     [staticMode]="staticMode"
                     [autoscale]="autoscale"></app-waterfall>
    </div>
  </div>
  <div class="row azimuth-correlogram" *ngIf="(online && station) || staticMode">
    <div class="col-md">
      <app-azimuth-correlogram [station]="station" [dataReceived]="waterfallDataReceived"
                               [infrasonicEventExampleMode]="infrasonicEventExampleMode"
                               [staticMode]="staticMode"
                               [autoscale]="autoscale"></app-azimuth-correlogram>
    </div>
  </div>
  <div class="row sensors" *ngIf="online && station && !infrasonicEventExampleMode && !staticMode">
    <div *ngFor="let sensor of sensors" class="col-md-6 sensor">
      <app-sensor-scope
        [sensorID]="sensor.id"
        [dataReceived]="sensor.dataReceived"
        [sensorColor]="sensor.color"
        [autoscale]="autoscale"></app-sensor-scope>
    </div>
  </div>
</div>
