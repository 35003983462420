import { Component, OnInit } from '@angular/core';
import {AccountWsService} from '../../../services/arfang-ws/account-ws.service';
import {ArfangAccount} from '../../../services/arfang-ws/account-model';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {AlertService} from '../../../services/alert.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  accounts: ArfangAccount[];
  dataSource = new MatTableDataSource<ArfangAccount>();
  displayedColumns: string[] = ['id', 'name'];
  accountForm: UntypedFormGroup;

  constructor(private accountWs: AccountWsService,
              private alertService: AlertService,
              private formBuilder: UntypedFormBuilder) {
    this.accountForm = formBuilder.group({
      name: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    await this.refreshTable();
  }

  async refreshTable(): Promise<void> {
    try {
      this.accounts = await this.accountWs.getAccounts();
      this.dataSource.data = this.accounts;
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

  async create(): Promise<void> {
    if (!this.accountForm.valid) {
      return ;
    }
    try {
      const newID = await this.accountWs.create({
        name: this.accountForm.controls.name.value,
      });
      this.alertService.success('Account successfully created ! (ID ' + newID.id + ')');
      await this.refreshTable();
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
