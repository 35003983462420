import {Component, OnInit} from '@angular/core';
import {AppInfos, AppWsService} from 'src/app/services/app-ws.service';
import {AlertDialogService} from 'src/app/services/alert-dialog.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  appInfos?: AppInfos;

  constructor(private appWsService: AppWsService,
              private alertDialogService: AlertDialogService) {
  }

  async ngOnInit(): Promise<void> {
    this.appInfos = await this.appWsService.appInfos();
  }

  displayVersion() {
    if (this.appInfos !== undefined) {
      this.alertDialogService.displayAlert('Version', this.appInfos.appVersion);
    }
  }

}
