import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {WebsocketService} from './websocket-service/websocket.service';
import {Actions} from './websocket-service/Actions';
import {LiveScopeControlMessage} from './websocket-service/live-scope-control-message';
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {SpectrogramData} from './websocket-service/packets/SpectrogramData';
import {ScopeService} from './scopes/scope.service';
import {SpectrogramPacket} from './websocket-service/packets/SpectrogramPacket';
import {StationPacket} from './websocket-service/packets/StationPacket';
import {ArfangStation} from '../../../common/stations/arfang-station';
import {LiveScopeWebSocket} from './websocket-service/livescope-websocket';

@Component({
  selector: ' app-livescopesession',
  templateUrl: './live-scope-session.component.html',
  styleUrls: ['./live-scope-session.component.scss']
})
export class LiveScopeSessionComponent implements OnInit, OnDestroy, OnChanges {

  @Input() station: ArfangStation;
  @Input() infrasonicEventExampleMode = false;
  @Input() staticMode = false;
  @Input() staticModeEpochSecond: number;
  @Input() staticCoherenceSpectrogramDatas: SpectrogramData[] = null;
  @Input() staticAzimuthCorrelogramDatas: SpectrogramData[] = null;
  sensors: { id: number, dataReceived: Subject<StationPacket>, color: string }[];
  spectrographDataReceived: Subject<SpectrogramData>;
  waterfallDataReceived: Subject<SpectrogramPacket>;
  displaySpectrogram = false;
  online: boolean;
  autoscale: boolean;

  private livescopeWebsocketSubscription: Subscription;
  private livescopeWebsocket: LiveScopeWebSocket;

  constructor(private readonly websocketService: WebsocketService,
              readonly scopeService: ScopeService) {
    this.sensors = [];
    this.spectrographDataReceived = new Subject<SpectrogramData>();
    this.waterfallDataReceived = new ReplaySubject<SpectrogramPacket>(1);

    this.online = false;
  }

  async ngOnInit() {
  }

  async ngOnDestroy(): Promise<void> {
    if (this.online) {
      await this.disconnect();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.online) {
      this.disconnect();
    }
    if (this.staticMode && changes.hasOwnProperty('staticCoherenceSpectrogramDatas')) {
      this.waterfallDataReceived.next(new SpectrogramPacket(
        this.station.id,
        this.staticCoherenceSpectrogramDatas,
        this.staticAzimuthCorrelogramDatas,
        this.staticModeEpochSecond
      ));
    } else {
      await this.connect();
      this.updatePlotsNumber();
    }
  }

  async configureWebSocket(): Promise<void> {
    this.livescopeWebsocket = this.websocketService.createLiveScopeWebSocket(this.station.id);
    this.livescopeWebsocket.open();
    this.livescopeWebsocketSubscription = this.livescopeWebsocket.observable().subscribe(
      (data: StationPacket | SpectrogramPacket) => this.readData(data),
      () => {
        console.log('Trying to reconnect');
        this.livescopeWebsocketSubscription.unsubscribe();
        this.online = false;
        setTimeout(async () => await this.configureWebSocket(), 10000);
      },
      () => {
        this.online = false;
      }
    );
    this.online = !this.livescopeWebsocket.closed && !this.livescopeWebsocket.isStopped;
    this.subscribeToStream();
  }

  private subscribeToStream(): void {
    this.livescopeWebsocket.sendMessage(
      new LiveScopeControlMessage(
        '',
        this.station.id,
        Actions.START,
        this.scopeService.liveScopesPtsPerSec
      )
    );
  }

  async connect(): Promise<void> {
    await this.configureWebSocket();
    this.retrieveSpectrogramDataBuffer();
    this.scopeService.connectedByUser = true;
  }

  disconnect(): void {
    this.livescopeWebsocket.sendMessage(
      new LiveScopeControlMessage(
        '',
        this.station.id,
        Actions.STOP,
        0
      ));
    this.scopeService.liveScopesPtsPerSec = this.scopeService.DEFAULT_PTS_PER_SEC;
    this.online = false;
    this.livescopeWebsocketSubscription.unsubscribe();
    this.livescopeWebsocket.close();
    this.scopeService.connectedByUser = false;
  }

  onPtsPerSecChange(): void {
    this.livescopeWebsocket.sendMessage(
      new LiveScopeControlMessage(
        '',
        this.station.id,
        Actions.SET_POINTS_PER_SEC,
        this.scopeService.liveScopesPtsPerSec
      ));
  }

  updatePlotsNumber(): void {
    this.sensors = [];
    for (let i = 0; i < this.station?.nbSensors ?? 0; i++) {
      this.sensors.push({id: i, dataReceived: new Subject<any>(), color: ScopeService.SENSOR_COLORS[i]});
    }
  }

  private readData(data: StationPacket | SpectrogramPacket): void {
    if (this.websocketService.isStationPacket(data, this.scopeService.liveScopesPtsPerSec)) {
      const stationPacket = data as StationPacket;
      if (this.sensors.length === 0) {
        this.updatePlotsNumber();
      }
      for (let i = 0; i < this.station.nbSensors; i++) {
        this.sensors[i].dataReceived.next(stationPacket);
      }
    } else if (this.websocketService.isSpectrogramPacket(data)) {
      const spectrogramPacket = data as SpectrogramPacket;
      this.waterfallDataReceived.next(spectrogramPacket);
      this.spectrographDataReceived.next(spectrogramPacket.spectrogramData[spectrogramPacket.spectrogramData.length - 1]);
    }
  }

  private retrieveSpectrogramDataBuffer(): void {
    this.livescopeWebsocket.sendMessage(new LiveScopeControlMessage(
      '',
      this.station.id,
      Actions.GET_LAST_SPECTRO_DATA,
      ScopeService.WATERFALL_FRAMES_BUFFER
    ));
  }

}
