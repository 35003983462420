import {Injectable, OnDestroy} from '@angular/core';
import {ArfangStation} from './arfang-station';
import {Subscription} from 'rxjs';
import {StationWsService} from '../../services/arfang-ws/station-ws.service';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  stationList: Array<ArfangStation>;
  selectedStation: ArfangStation;

  constructor(private stationWsService: StationWsService) {
    this.stationList = new Array<ArfangStation>();
    this.refreshStations();
  }

  private async refreshStations(): Promise<void> {
    const stations = await this.stationWsService.getStations();
    this.stationList = stations;
    if (this.selectedStation === undefined && this.stationList.length > 0) {
      this.selectedStation = this.stationList[0];
    }
  }

  async getStationsList(): Promise<ArfangStation[]> {
    return this.stationWsService.getStations();
  }

  async getSelectedStation(): Promise<ArfangStation> {
    if (this.selectedStation === undefined) {
      await this.refreshStations();
    }
    return this.selectedStation;
  }

  setSelectedStation(station): void {
    this.selectedStation = station;
  }

}
