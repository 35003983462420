<mat-card class="detection-card">
  <span>[DEBUG]</span>
  <div class="container-fluid">
    <div class="row">
      <h3>Vectors data</h3>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-9 col-md-3">
              <mat-slide-toggle (change)="toggleSpectro($event)" [checked]="spectroVisible">Toggle SpectroData</mat-slide-toggle>
              <mat-slide-toggle (change)="toggleVectors($event)" [checked]="vectorsVisible">Toggle Vectors</mat-slide-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col-md">
              <plotly-plot [data]="graphConfig.data"
                           [layout]="graphConfig.layout"
                           [config]="{displayModeBar: true, responsive: true}"> <!--displayModeBar:  false-->
              </plotly-plot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h3>Event properties</h3>
      </div>
      <div class="col-md-6">
        <h3>Correlogram Threshold value : {{arfangEvent.event.type === 'T' ? arfangEvent.event.tirAzimuthThresholdInCorrelogram : arfangEvent.event.avalancheAzimuthThresholdInCorrelogram}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="container-fluid" style="font-family: monospace">
          <div class="row">
            <div class="col-md-9">sCoh3Energy : 1-5 Hz</div>
            <div
              class="col-md-3">{{arfangEvent.event.sCoh3EnergyIn1To5Hz > 0 ? '+' : ''}}{{arfangEvent.event.sCoh3EnergyIn1To5Hz | number: '2.2-2'}}</div>
          </div>
          <div class="row">
            <div class="col-md-9">sCoh3Energy : 5-10 Hz</div>
            <div
              class="col-md-3">{{arfangEvent.event.sCoh3EnergyIn5To10Hz > 0 ? '+' : ''}}{{arfangEvent.event.sCoh3EnergyIn5To10Hz | number: '2.2-2'}}</div>
          </div>
          <div class="row">
            <div class="col-md-9">sCoh3Energy : 10-20 Hz</div>
            <div
              class="col-md-3">{{arfangEvent.event.sCoh3EnergyIn10To20Hz > 0 ? '+' : ''}}{{arfangEvent.event.sCoh3EnergyIn10To20Hz | number: '2.2-2'}}</div>
          </div>
          <div class="row">
            <div class="col-md-9">sCoh3Energy : 20-40 Hz</div>
            <div
              class="col-md-3">{{arfangEvent.event.sCoh3EnergyIn20To40Hz > 0 ? '+' : ''}}{{arfangEvent.event.sCoh3EnergyIn20To40Hz | number: '2.2-2'}}</div>
          </div>
          <div class="row">
            <div class="col-md-9">sCoh3Energy : 1-40 Hz</div>
            <div
              class="col-md-3">{{arfangEvent.event.sCoh3EnergyIn1To40Hz > 0 ? '+' : ''}}{{arfangEvent.event.sCoh3EnergyIn1To40Hz | number: '2.2-2'}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div style="overflow: auto; max-height: 400px;">
          <table mat-table [dataSource]="arfangEvent.event.vectors" style="width: 100%; ">
            <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef>index</th>
              <td mat-cell *matCellDef="let element; let i = index">{{i}}</td>
            </ng-container>
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef>time</th>
              <td mat-cell *matCellDef="let element">{{element.time}}</td>
            </ng-container>
            <ng-container matColumnDef="azimuth">
              <th mat-header-cell *matHeaderCellDef>azimuth</th>
              <td mat-cell *matCellDef="let element">{{element.azimuth}}</td>
            </ng-container>
            <ng-container matColumnDef="correlation">
              <th mat-header-cell *matHeaderCellDef>correlation</th>
              <td mat-cell *matCellDef="let element">{{element.correlation}}</td>
            </ng-container>
            <ng-container matColumnDef="sCoh3MeanLevel">
              <th mat-header-cell *matHeaderCellDef>sCoh3MeanLevel</th>
              <td mat-cell
                  *matCellDef="let element">{{element.SCoh3MeanLevel >= 0 ? '+' : '-'}}{{math.abs(element.SCoh3MeanLevel) | number: '2.1-1'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row style="height: 24px" *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</mat-card>
