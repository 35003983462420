<div class="primary-navbar-placeholder"></div>
<mat-toolbar color="primary" class="primary-bar mat-elevation-z3">
  <span class="mobile">
    <button mat-icon-button [matMenuTriggerFor]="mainMenu">
      <mat-icon>menu</mat-icon>
    </button>
  </span>

  <img class="main-logo" src="/assets/logo_isr.png" alt="Arfang Logo"/>

  <span class="selected-field-trial">
    Walenstadt 2023
  </span>

  <span class="desktop">
    <button *ngFor="let menuItem of menuItems" [ngClass]="{currentActive: activatedItem === menuItem.key}" mat-button [routerLink]="menuItem.routerLink">{{menuItem.name}}</button>
  </span>

  <span style="flex: 1 1 auto"></span>

  <app-version></app-version>

  <button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
  </button>
  <button mat-icon-button *ngIf="authService.isAdmin || authService.isManager" [matMenuTriggerFor]="settingsMenu">
    <mat-icon>settings</mat-icon>
  </button>
<!--  <button mat-icon-button [matMenuTriggerFor]="helpMenu">-->
<!--    <mat-icon>help_outline</mat-icon>-->
<!--  </button>-->
</mat-toolbar>

<mat-menu #mainMenu="matMenu">
  <button mat-menu-item routerLink="/">Home</button>
  <button *ngFor="let menuItem of menuItems" mat-menu-item [routerLink]="menuItem.routerLink">{{menuItem.name}}</button>
</mat-menu>

<mat-menu #userMenu="matMenu">
  <button mat-menu-item routerLink="/my-account">My account</button>
<!--  <button mat-menu-item routerLink="/notifications">Notifications</button>-->
  <button mat-menu-item routerLink="/" (click)="authService.logout()">Logout</button>
</mat-menu>

<mat-menu #settingsMenu="matMenu">
  <button mat-menu-item routerLink="/stations">Device</button>
  <button mat-menu-item routerLink="/users">Users</button>
<!--  <button mat-menu-item *ngIf="authService.isAdmin" (click)="debugService.toggle()">-->
<!--    <span>{{debugService.enabled ? 'Disable' : 'Enable'}} events debug</span>-->
<!--  </button>-->
</mat-menu>

<mat-menu #helpMenu="matMenu">
  <button mat-menu-item routerLink="/infrasonic-event-examples">Event examples</button>
</mat-menu>

