import { Injectable } from '@angular/core';
import {Configuration} from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  configuration: Configuration;

  constructor() { }

}
