import { Injectable } from '@angular/core';
import {User, UserCreate, UserNotificationsSettings} from './user-model';
import {ArfangWsService} from './arfang-ws.service';
import {AuthResponse} from './auth-ws-model';

@Injectable({
  providedIn: 'root'
})
export class UserWsService {
  private readonly ENDPOINT = 'user';

  constructor(private arfangWs: ArfangWsService) {
  }

  getAll(): Promise<User[]> {
    return this.arfangWs.get<User[]>(this.ENDPOINT, {});
  }

  getMe(): Promise<User> {
    return this.arfangWs.get<User>(this.ENDPOINT + '/me', {});
  }

  create(user: UserCreate): Promise<number> {
    return this.arfangWs.post<number>(this.ENDPOINT, user);
  }

  getNotificationsSettings(userId: number): Promise<UserNotificationsSettings> {
    return this.arfangWs.get<UserNotificationsSettings>(this.ENDPOINT + '/' + userId + '/notificationsSettings', {});
  }

  requestPasswordReset(username: string): Promise<void> {
    return this.arfangWs.post<void>(this.ENDPOINT + '/resetPassword', {username});
  }

  putNotificationsSettings(userId: number, body: UserNotificationsSettings): Promise<void> {
    return this.arfangWs.put<void>(this.ENDPOINT + '/' + userId + '/notificationsSettings', body);
  }

  putNewPassword(body: any): Promise<void> {
    return this.arfangWs.put<void>(this.ENDPOINT + '/updatePassword', body);
  }

  createPassword(body: any, token: string): Promise<void> {
    return this.arfangWs.post(this.ENDPOINT + '/createPassword', body, token);
  }

  impersonate(userID: number): Promise<AuthResponse> {
    return this.arfangWs.post(this.ENDPOINT + '/' + userID + '/impersonate', {});
  }

}
