import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SimpleDialogData} from 'src/app/common/simple-dialog/simple-dialog-data';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent implements OnInit {

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dialogService: DialogService) {
  }

  message = '';

  ngOnInit() {
    const dialogComponent = this.dialogService.dialogComponentRefMap.get(this.dynamicDialogRef);
    const data = dialogComponent?.instance.config.data as SimpleDialogData;
    this.message = data.message;
  }

}
