<div class="container page-container">
  <div class="row justify-content-center">
    <div class="col-lg-10 col-md-12 col-sm-12">
      <mat-card *ngIf="!selectFieldTrial">
        <mat-card-title>Research project – Restricted access - Website under construction</mat-card-title>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md">
            </div>
          </div>
          <div class="row">
            <div class="col-md">
            </div>
          </div>
          <div class="row buttons">
            <button mat-raised-button color="primary" (click)="refuse()">
              I refuse
            </button>
            <button mat-raised-button color="primary" (click)="accept()">
              I agree
            </button>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="selectFieldTrial">
        <mat-card-title>Select your field trial</mat-card-title>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md">
            </div>
          </div>
          <div class="row">
            <div class="col-md">
            </div>
          </div>
          <div class="row buttons">
            <button mat-raised-button color="primary" (click)="walenstadt2023()">
              Walenstadt 2023
            </button>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
