<svg [attr.height]="magnitudeConfig.height" [attr.width]="magnitudeConfig.width + 8">
  <rect [attr.x]="magnitudeConfig.x[0]" [attr.y]="magnitudeConfig.y[0]"
        [attr.width]="magnitudeConfig.rectWidth" [attr.height]="magnitudeConfig.rectHeight[0]" rx="1" ry="1"
        [attr.fill]="event.magnitude >= 1 ?   '#383838':'none'" stroke="black" stroke-width="1"></rect>
  <rect [attr.x]="magnitudeConfig.x[1]" [attr.y]="magnitudeConfig.y[1]"
        [attr.width]="magnitudeConfig.rectWidth" [attr.height]="magnitudeConfig.rectHeight[1]" rx="1" ry="1"
        [attr.fill]="event.magnitude >= 2 ?  '#383838':'none'" stroke="black" stroke-width="1"></rect>
  <rect [attr.x]="magnitudeConfig.x[2]" [attr.y]="magnitudeConfig.y[2]"
        [attr.width]="magnitudeConfig.rectWidth" [attr.height]="magnitudeConfig.rectHeight[2]" rx="1" ry="1"
        [attr.fill]="event.magnitude >= 3 ?  '#383838':'none'" stroke="black" stroke-width="1"></rect>
</svg>
