import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {AlertData} from './alert-data';

@Component({
  selector: 'app-alert',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  static closed = new EventEmitter<void>();
  success: boolean;
  displayTitle: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertData) {
    this.success = data.success;
    this.displayTitle = !data.success;
  }

  ngOnInit(): void {
  }

  onClose(): void {
    AlertDialogComponent.closed.emit();
  }

}
