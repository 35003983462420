<app-nav-bar activatedItem="stations"></app-nav-bar>

<div class="page-container container">
  <div class="row">
    <div class="col">
      <mat-card>
        <div class="table-wrapper">
          <table mat-table matSort [dataSource]="dataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let station">{{station | stationName}}</td>
            </ng-container>
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!authService.isAdmin">Account</th>
              <td mat-cell *matCellDef="let station" [hidden]="!authService.isAdmin">{{station.accountName}}</td>
            </ng-container>
            <ng-container matColumnDef="nbSensors">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sensors</th>
              <td mat-cell *matCellDef="let station">{{station.nbSensors}}</td>
            </ng-container>
            <ng-container matColumnDef="latitude">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitude</th>
              <td mat-cell *matCellDef="let station">{{station.latitude}}</td>
            </ng-container>
            <ng-container matColumnDef="longitude">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitude</th>
              <td mat-cell *matCellDef="let station">{{station.longitude}}</td>
            </ng-container>
            <ng-container matColumnDef="altitude">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Altitude</th>
              <td mat-cell *matCellDef="let station">{{station.altitude}}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let station">
                <a mat-icon-button [routerLink]="['/station/', station.id]">
                  <mat-icon>settings</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
          </table>
        </div>
      </mat-card>
    </div>
  </div>
</div>
