import {ShotDetectionFiltersData} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';
import {Params} from '@angular/router';

export class ShotDetectionsFilters {

  filtersData: ShotDetectionFiltersData;
  minDateTime: Date;
  maxDateTime: Date;

  optionsGroups: OptionsGroup[] = [];
  stationsOptionsGroup: OptionsGroup;
  scenariosOptionsGroup: OptionsGroup;
  weaponsOptionsGroup: OptionsGroup;
  shotOriginsOptionsGroup: OptionsGroup;
  selectedMinDateTime?: Date;
  selectedMaxDateTime?: Date;

  constructor(shotDetectionFiltersData: ShotDetectionFiltersData) {
    this.filtersData = shotDetectionFiltersData;
    this.minDateTime = new Date(this.filtersData.minDateTime);
    this.maxDateTime = new Date(this.filtersData.maxDateTime);
    this.stationsOptionsGroup = new OptionsGroup('Sensors', this.filtersData.stations);
    this.scenariosOptionsGroup = new OptionsGroup('Scenarios', this.filtersData.scenarios);
    this.weaponsOptionsGroup = new OptionsGroup('Weapons', this.filtersData.weapons);
    this.shotOriginsOptionsGroup = new OptionsGroup('Shooter locations', this.filtersData.shotOrigins);
    this.optionsGroups = [
      this.stationsOptionsGroup,
      this.scenariosOptionsGroup,
      this.shotOriginsOptionsGroup,
      this.weaponsOptionsGroup
    ];
    this.selectedMinDateTime = this.minDateTime;
    this.selectedMaxDateTime = this.maxDateTime;
  }

  loadFromQueryParams(queryParams: any) {
    this.stationsOptionsGroup.selectValues(queryParams.stations ?? []);
    this.scenariosOptionsGroup.selectValues(queryParams.scenarios ?? []);
    this.weaponsOptionsGroup.selectValues(queryParams.weapons ?? []);
    this.shotOriginsOptionsGroup.selectValues(queryParams.shotOrigins ?? []);
    if (queryParams.minDateTime) {
      this.selectedMinDateTime = new Date(queryParams.minDateTime);
    }
    if (queryParams.maxDateTime) {
      this.selectedMaxDateTime = new Date(queryParams.maxDateTime);
    }
  }

  buildQueryParams(): any {
    return {
      stations: this.stationsOptionsGroup.selectedValues(),
      scenarios: this.scenariosOptionsGroup.selectedValues(),
      weapons: this.weaponsOptionsGroup.selectedValues(),
      shotOrigins: this.shotOriginsOptionsGroup.selectedValues(),
      minDateTime: (this.selectedMinDateTime ?? this.minDateTime).toISOString(),
      maxDateTime: (this.selectedMaxDateTime ?? this.maxDateTime).toISOString()
    };
  }

  clear(): void {
    this.optionsGroups.forEach(optionsGroup => optionsGroup.clear());
    this.selectedMinDateTime = this.minDateTime;
    this.selectedMaxDateTime = this.maxDateTime;
  }

}


export interface Option {
  value: string;
  selected: boolean;
}

export class OptionsGroup {

  readonly options: Option[];

  constructor(readonly name: string,
              readonly values: string[]) {
    this.options = values.map(station => ({value: station, selected: false}));
  }

  selectValues(valuesToSelect: string[] | string) {
    const valuesToSelectSet = typeof valuesToSelect === 'string' ? new Set([valuesToSelect]) : new Set(valuesToSelect);
    this.options
      .filter(option => valuesToSelectSet.has(option.value))
      .forEach(option => option.selected = true);
  }

  selectedValues(): string[] {
    return this.options
      .filter(option => option.selected)
      .map(option => option.value);
  }

  clear(): void {
    this.options.forEach(option => option.selected = false);
  }

  isEmpty(): boolean {
    return !this.options.some(option => option.selected);
  }

}
