import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GalleryComponent, ImageItem} from 'ng-gallery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('gallery') gallery: GalleryComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.gallery.galleryRef.load([
      '/assets/home/exova-screenshot.png',
      '/assets/home/gallery/Exova_01.PNG',
      '/assets/home/gallery/Exova_02.PNG',
      '/assets/home/gallery/Exova_03.PNG',
      '/assets/home/gallery/Exova_04.PNG',
      '/assets/home/gallery/Exova_06.PNG',
      '/assets/home/gallery/Exova_07.PNG',
      '/assets/home/gallery/Exova_08.PNG',
      '/assets/home/gallery/Exova_09.PNG',
      '/assets/home/gallery/Exova_11.PNG',
      '/assets/home/gallery/Exova_12.PNG']
      .map(url => new ImageItem({src: url, thumb: url})));
  }

}
