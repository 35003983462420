import {Injectable} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {HttpErrorResponse} from '@angular/common/http';
import {SimpleDialogData} from 'src/app/common/simple-dialog/simple-dialog-data';
import {SimpleDialogComponent} from 'src/app/common/simple-dialog/simple-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertDialogService {

  constructor(private dialogService: DialogService) {
  }

  displayAlert(title: string, message: string, closeAction?: () => void, cssWidth = 'calc(min(600px, 80%))'): void {
    const data: SimpleDialogData = {
      message,
    };
    const dialogRef = this.dialogService.open(SimpleDialogComponent, {
      header: title,
      width: cssWidth,
      data
    });
    if (closeAction !== undefined) {
      dialogRef.onClose.subscribe(() => closeAction());
    }
  }

  displayError(error: any): void {
    const data: SimpleDialogData = {
      message: '',
    };
    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        data.message = error.error.message;
      } else {
        const message = error.error?.userMessage;
        if (message != null) {
          data.message = message;
        } else {
          data.message = 'Error : ' + error.status;
        }
      }
    } else if (error instanceof Error) {
      data.message = error.message;
    } else if (typeof error === 'string') {
      data.message = error;
    } else {
      data.message = 'Unknown error';
      console.error(error);
    }
    this.dialogService.open(SimpleDialogComponent, {
      header: 'Error',
      width: 'calc(min(600px, 80%))',
      data
    });
  }

}
