import {Component, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {UserWsService} from '../../services/arfang-ws/user-ws.service';
import {AuthService} from '../../services/auth/auth.service';
import {UserNotificationsSettings} from '../../services/arfang-ws/user-model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notificationsSettings: UserNotificationsSettings;

  constructor(private userWsService: UserWsService,
              readonly authService: AuthService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.loadNotificationsSettings();
  }

  async loadNotificationsSettings() {
    try {
      this.notificationsSettings = await this.userWsService.getNotificationsSettings(this.authService.userId);
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

  get ready() {
    return this.notificationsSettings != null;
  }

  async save() {
    try {
      await this.userWsService.putNotificationsSettings(this.authService.userId, this.notificationsSettings);
      this.alertService.success('Settings saved');
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
