<div class="container eventsFilters">
  <div class="row">
    <div class="col-md">
      <app-properties-selector (filtersUpdated)="propertiesFilterUpdated($event)"></app-properties-selector>
    </div>
  </div>
  <div class="row" style="margin-top: 48px">
    <div class="col-md">
      <app-period-selector (filtersUpdated)="periodFilterUpdated($event)"></app-period-selector>
    </div>
  </div>
</div>
