<form *ngIf="form" [formGroup]="form" class="row">
  <div class="col-sm">
    <mat-form-field>
      <mat-label>Device</mat-label>
      <mat-select multiple formControlName="stations" placeholder="All devices" #stationsMatSelect>
        <mat-button-toggle [checked]="allSelected.stations"
                           (change)="onSelectAll('stations', stationsMatSelect)"
                           class="selectAllButton">
          [All devices]
        </mat-button-toggle>
        <mat-option *ngFor="let station of stations" [value]="station.id"
                    class="mat-select-value-text">
          {{station | stationName}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  </div>

  <div class="col-sm">
    <mat-form-field>
      <mat-label>Prognosis</mat-label>
      <mat-select multiple formControlName="prognosis" #prognosisMatSelect>
        <mat-button-toggle [checked]="allSelected.prognosis"
                           (change)="onSelectAll('prognosis', prognosisMatSelect)"
                           class="selectAllButton">
          [All prognosis]
        </mat-button-toggle>
        <mat-option *ngFor="let prognosis of FilterService.DEFAULT_PROGNOSIS" [value]="prognosis.value"
                    class="mat-select-value-text">
          {{prognosis.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-sm">
    <mat-form-field>
      <mat-label>Magnitude</mat-label>
      <mat-select multiple formControlName="magnitude" #magnitudeMatSelect>
        <mat-button-toggle [checked]="allSelected.magnitude"
                           (change)="onSelectAll('magnitude', magnitudeMatSelect)"
                           class="selectAllButton">
          [All magnitudes]
        </mat-button-toggle>
        <mat-option *ngFor="let magnitude of FilterService.DEFAULT_MAGNITUDE" [value]="magnitude.value"
                    class="mat-select-value-text">
          {{magnitude.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-sm">
    <mat-form-field>
      <mat-label>DOA</mat-label>
      <mat-select multiple formControlName="sector" #sectorMatSelect>
        <mat-button-toggle [checked]="allSelected.sector"
                           (change)="onSelectAll('sector', sectorMatSelect)"
                           class="selectAllButton">
          [All sectors]
        </mat-button-toggle>
        <mat-option *ngFor="let sector of FilterService.DEFAULT_SECTORS" [value]="sector.value"
                    class="mat-select-value-text">
          {{sector.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
