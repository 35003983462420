import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserWsService} from '../../services/arfang-ws/user-ws.service';
import {User} from '../../services/arfang-ws/user-model';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {AuthService} from '../../services/auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {Router} from '@angular/router';
import {AuthResponse} from '../../services/arfang-ws/auth-ws-model';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;

  users: User[];
  dataSource: MatTableDataSource<User>;
  columns = ['lastName', 'firstName', 'accountName', 'email'];

  constructor(private userWs: UserWsService,
              readonly authService: AuthService,
              private userService: UserWsService,
              private alertService: AlertService,
              private router: Router) {
    this.dataSource = new MatTableDataSource<User>();
  }

  async ngOnInit(): Promise<void> {
    if (this.authService.rank === 'ADMIN') {
      this.columns.push('impersonate');
    }
    await this.refreshTable();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  async refreshTable(): Promise<void> {
    try {
      this.users = await this.userWs.getAll();
      this.dataSource.data = this.users;
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

  async impersonate(user: User): Promise<void> {
    let authResponse: AuthResponse;
    try {
      authResponse = await this.userService.impersonate(user.id);
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
      return ;
    }
    if (authResponse.accessToken) {
      this.authService.impersonate(authResponse.accessToken);
      await this.router.navigateByUrl('/');
    }
  }

}
