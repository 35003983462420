import {Injectable} from '@angular/core';
import {ArfangWsService} from 'src/app/services/arfang-ws/arfang-ws.service';
import {
  ShotDetection,
  ShotDetectionDetail,
  ShotDetectionFiltersData,
  ShotDetectionSummary
} from 'src/app/services/arfang-ws/shot-event/shot-detection-ws-model';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ShotDetectionWsService {

  private readonly ENDPOINT: string = 'shot-detection';

  constructor(private arfangWsService: ArfangWsService) {
  }

  getFiltersData(): Promise<ShotDetectionFiltersData> {
    return this.arfangWsService.get(this.ENDPOINT + '/filtersData');
  }

  getShotDetections(queryParams: any): Promise<ShotDetectionSummary[]> {
    return this.arfangWsService.get(this.ENDPOINT, queryParams);
  }

  downloadShotDetections(queryParams: any): Promise<HttpResponse<Blob>> {
    return this.arfangWsService.getBlob(this.ENDPOINT + '/download', queryParams);
  }

  getShotDetection(queryParams: any): Promise<ShotDetectionDetail> {
    return this.arfangWsService.get(this.ENDPOINT + '/detail', queryParams);
  }

  async getShotDetectionsOfOtherDevices(shotDetectionId: string): Promise<ShotDetection[]> {
    return this.arfangWsService.get(this.ENDPOINT + '/sameShot', {shotDetectionId});
  }
}
