<mat-card>
  <plotly-plot
    [config]="plotlyConfig"
    [layout]="plotLayout"
    [data]="plotData"
    (plotlyClick)="onPointSelected($event)"
    [divId]="PLOT_DIV_ID">
  </plotly-plot>
</mat-card>
<div class="eventDetails" *ngIf="!!focusedEventPoint">
  <button mat-raised-button
          routerLink="/event/{{focusedEventPoint.id}}"
          [queryParams]="{idslist: plotData[0].ids.join(',')}">
    Details
  </button>
</div>
