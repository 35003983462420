import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserWsService} from '../../../services/arfang-ws/user-ws.service';
import {UserCreate} from '../../../services/arfang-ws/user-model';
import {AlertService} from '../../../services/alert.service';
import {AuthService} from '../../../services/auth/auth.service';
import {MatLegacySlideToggleChange as MatSlideToggleChange} from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  @Output() createdSuccess = new EventEmitter<void>();
  createForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,
              private userService: UserWsService,
              private alertService: AlertService,
              readonly authService: AuthService) {
    this.createForm = formBuilder.group({
      accountId: [''],
      admin: [false, Validators.required],
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      manager: [false, Validators.required],
      mobilePhone: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onManagerToggleChange(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.createForm.patchValue({admin: false});
    }
  }

  onAdminToggleChange(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.createForm.patchValue({manager: false});
    }
  }

  private isValid(): boolean {
    return this.createForm.valid;
  }

  async create(): Promise<void> {
    if (this.isValid()) {
      let accountId: number;
      if (this.authService.rank === 'MANAGER') {
        accountId = this.authService.arfangToken.account_id;
      } else {
        if (this.createForm.controls.admin.value) {
          accountId = null;
        } else {
          accountId = this.createForm.controls.accountId.value;
        }
      }
      try {
        await this.userService.create({
          accountId,
          admin: this.createForm.controls.admin.value,
          email: this.createForm.controls.email.value,
          firstName: this.createForm.controls.firstName.value,
          lastName: this.createForm.controls.lastName.value,
          manager: this.createForm.controls.manager.value,
          mobilePhone: this.createForm.controls.mobilePhone.value
        } as UserCreate);
        this.alertService.success('User successfuly created !');
        this.createForm.reset();
        this.createdSuccess.emit();
      } catch (arfangWsError) {
        this.alertService.error(arfangWsError);
      }
    }
  }

}
