<app-nav-bar activatedItem="eventsGraph"></app-nav-bar>

<div class="container page-container">

  <div class="row">
    <div class="col-md">
      <mat-card>
        <app-arfang-events-filters (eventsFilters)="filtersUpdated($event)"></app-arfang-events-filters>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <app-plotter (simpleClicked)="onEventSimpleClick($event)"
                   [newXAxisRangeReceived]="xAxisRangeSubject"
                   [newEventsToPlotReceived]="eventsSubject">
      </app-plotter>
    </div>
  </div>

</div>
