import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DurationMode} from '../filters/duration-mode';
import {Period} from '../filters/period';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ActivatedRoute} from '@angular/router';
import {FilterService} from '../service/filter.service';
import moment, {Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {debounceTime} from 'rxjs/operators';
import {ArfangEventFilters} from '../filters/arfang-event-filters';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT}
  ],
})
export class PeriodSelectorComponent implements OnInit {

  period: Period = new Period();
  @Output()
  filtersUpdated = new EventEmitter<ArfangEventFilters>();

  periodForm: UntypedFormGroup;
  readonly FilterService: typeof FilterService = FilterService;
  readonly DurationMode: typeof DurationMode = DurationMode;

  constructor(private formBuilder: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute,
              private filterService: FilterService) {
    this.periodForm = this.formBuilder.group({
      start: ['', Validators.required],
      stop: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    const paramsAvailable = this.activatedRoute.snapshot.queryParamMap !== undefined
      && ['periodType', 'minDate', 'maxDate'].every(param => paramMap.has(param));
    if (paramsAvailable) {
      const periodType = paramMap.get('periodType');
      const minDate = paramMap.get('minDate');
      const maxDate = paramMap.get('maxDate');
      this.period = Period.from(periodType, minDate, maxDate);
    } else {
      const arfangEventFilters = this.filterService.arfangEventFilters;
      const periodType = arfangEventFilters.periodType;
      const minDate = arfangEventFilters.minDate;
      const maxDate = arfangEventFilters.maxDate;
      this.period = Period.from(periodType, minDate, maxDate);
    }
    this.periodForm.patchValue({
      start: new Date(this.period.start),
      stop: new Date(this.period.stop)
    });
    this.emitPeriod();
    this.periodForm.valueChanges
      .pipe(debounceTime(200))
      .subscribe(() => {
        if (this.period.valid && this.periodForm.valid) {
          this.emitPeriod();
        }
      });
  }

  private emitPeriod(): void {
    this.filtersUpdated.emit(
      {
        periodType: this.period.durationMode,
        minDate: this.period.startDate(),
        maxDate: this.period.stopDate()
      });
  }

  onPredefinedPeriodClicked(newMode: DurationMode): void {
    this.period.durationMode = newMode;
    this.period.stop = moment().endOf('day').valueOf();
    this.period.setStartFromStop();
    this.periodForm.patchValue({
      start: new Date(this.period.start),
      stop: new Date(this.period.stop)
    });
  }

  onDateInputChange(event: MatDatepickerInputEvent<Moment>, fromStartDate: boolean): void {
    if (event.value === null) {
      this.period.valid = false;
      return;
    }
    this.period.durationMode = DurationMode.CUSTOM;
    if (fromStartDate) {
      this.period.start = event.value.startOf('day').valueOf();
    } else {
      this.period.stop = event.value.endOf('day').valueOf();
    }
    this.period.updateValidity();
  }

  periodPrevious(): void {
    this.period.previous();
    this.periodForm.patchValue({
      start: new Date(this.period.start),
      stop: new Date(this.period.stop)
    });
  }

  periodNext(): void {
    this.period.next();
    this.periodForm.patchValue({
      start: new Date(this.period.start),
      stop: new Date(this.period.stop)
    });
  }

}
