import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-restricted-access-conditions',
  templateUrl: './restricted-access-conditions.component.html',
  styleUrls: ['./restricted-access-conditions.component.scss']
})
export class RestrictedAccessConditionsComponent {

  private returnUrl: string;
  selectFieldTrial = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || '/list';
    if (this.hasAcceptedConditions()) {
      this.router.navigateByUrl('/list');
    }
  }

  private hasAcceptedConditions() {
    return localStorage.getItem('conditions-accepted') === 'true';
  }

  async accept() {
    this.selectFieldTrial = true;
  }

  async refuse() {
    this.authService.logout();
    window.location.reload();
  }

  async walenstadt2023() {
    localStorage.setItem('conditions-accepted', 'true');
    await this.router.navigateByUrl(decodeURI(this.returnUrl));
  }

}
