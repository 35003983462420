import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {UserWsService} from '../../services/arfang-ws/user-ws.service';
import {AlertDialogComponent} from '../../common/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  returnUrl: string;
  loading = false;
  onReset = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private router: Router,
              private userService: UserWsService,
              private activatedRoute: ActivatedRoute,
              private alertService: AlertService) {
    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || '/list';
    if (this.authService.authenticated) {
      this.router.navigateByUrl('/list');
    }
  }

  async onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    this.loading = true;
    try {
      await this.authService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value);
      localStorage.removeItem('conditions-accepted');
      await this.router.navigateByUrl(decodeURI(this.returnUrl));
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    } finally {
      this.loading = false;
    }
  }

  async onResetClicked(): Promise<void> {
    if (!this.loginForm.controls.username.valid) {
      return;
    }
    try {
      await this.userService.requestPasswordReset(this.loginForm.controls.username.value);
      AlertDialogComponent.closed.subscribe(() => this.router.navigate(['login']));
      this.alertService.success('You will receive an email containing the procedure to reset your password.');
    } catch (arfangWsError) {
      this.alertService.error(arfangWsError);
    }
  }

}
