import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {ArfangEventSummary} from '../../../common/events/arfang-event-model';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import moment from 'moment';
import {FilterService} from '../../../common/events-filter/service/filter.service';
import {ArfangWsService} from '../../../services/arfang-ws/arfang-ws.service';

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.scss']
})
export class EventsTableComponent implements OnInit, OnDestroy {

  @Input() newEventsToPlotReceived: Subject<ArfangEventSummary[]>;
  @Output() exportData: EventEmitter<null>;
  @Output() eventClicked: EventEmitter<{ event: ArfangEventSummary, idslist: number[] }>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('eventsSort') eventsSort: MatSort;

  eventsToDisplay: ArfangEventSummary[];
  dataSource: MatTableDataSource<ArfangEventSummary>;
  columns: string[];

  constructor(public readonly filterService: FilterService,
              private readonly arfangWS: ArfangWsService) {
    this.columns = ['id', 'dateTime', 'duration', 'prognosis', 'sector', 'magnitude'];
    this.dataSource = new MatTableDataSource<ArfangEventSummary>();
    this.eventClicked = new EventEmitter<{ event: ArfangEventSummary, idslist: number[] }>();
    this.exportData = new EventEmitter<null>();
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.eventsSort;
    this.newEventsToPlotReceived.subscribe(events => this.updateTable(events));
  }

  private updateTable(events: ArfangEventSummary[]): void {
    this.eventsToDisplay = events;
    this.dataSource.data = events;
    this.dataSource.sort = this.eventsSort;
    this.dataSource.filterPredicate = (event: ArfangEventSummary, filter: string) => {
      filter = filter.toLowerCase();
      return event.observationComment?.toLowerCase()?.includes(filter) ||
        event.prognosis.toLowerCase().includes(filter) ||
        event.observationType.toLowerCase().includes(filter) ||
        moment(event.date).format('DD/MM/YYYY').includes(filter);
    };
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'sector':
          return item.azimuthStart;
        case 'notification':
          return item.userWasNotified ? 1 : -1;
        default:
          return item[property];
      }
    };
  }

  ngOnDestroy(): void {
    this.newEventsToPlotReceived.unsubscribe();
  }

  view(event: ArfangEventSummary): void {
    this.eventClicked.emit({
      event,
      idslist: this.dataSource.sortData(this.dataSource.data, this.dataSource.sort).map(tableEvent => tableEvent.id)
    });
  }

}
