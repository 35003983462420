import {Injectable} from '@angular/core';
import {Filter} from '../filters/filter';
import {DurationMode} from '../filters/duration-mode';
import {ArfangEventFilters} from '../filters/arfang-event-filters';
import {Period} from 'src/app/common/events-filter/filters/period';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  static readonly DEFAULT_PROGNOSIS: Filter[] = [
    // {value: 'A',  viewValue: 'Avalanche',   color: '#141ddb'},
    {value: 'T', viewValue: 'Shot', color: '#b33e3e'},
    // {value: 'X',  viewValue: 'Other',  color: '#666666'},
    // {value: 'H',  viewValue: 'Hourly Background Noise Stats',  color: '#000000'},
    // {value: 'R',  viewValue: 'Reboot',  color: '#000000'},
  ];
  static readonly DEFAULT_MAGNITUDE: Filter[] = [
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'}
  ];
  static readonly DEFAULT_SECTORS: Filter[] = [
    {value: 'N', viewValue: 'FWD'},
    {value: 'NE', viewValue: 'FWD-L'},
    {value: 'E', viewValue: 'L'},
    {value: 'SE', viewValue: 'BWD-L'},
    {value: 'S', viewValue: 'BWD'},
    {value: 'SW', viewValue: 'BWD-R'},
    {value: 'W', viewValue: 'R'},
    {value: 'NW', viewValue: 'FWD-R'},
  ];
  static readonly DEFAULT_DURATIONMODE: DurationMode = DurationMode.DAY;

  arfangEventFilters: ArfangEventFilters;

  constructor() {
    const period = new Period();
    this.arfangEventFilters = {
      stations: [],
      magnitude: FilterService.DEFAULT_MAGNITUDE.map(filter => filter.value),
      prognosis: FilterService.DEFAULT_PROGNOSIS.map(filter => filter.value),
      sector: FilterService.DEFAULT_SECTORS.map(filter => filter.value),
      periodType: period.durationMode,
      minDate: period.startDate(),
      maxDate: period.stopDate()
    };
  }

  save(filters: ArfangEventFilters): void {
    this.arfangEventFilters = {
      stations: filters.stations || this.arfangEventFilters.stations,
      magnitude: filters.magnitude || this.arfangEventFilters.magnitude,
      prognosis: filters.prognosis || this.arfangEventFilters.prognosis,
      sector: filters.sector || this.arfangEventFilters.sector,
      periodType: filters.periodType || this.arfangEventFilters.periodType,
      minDate: filters.minDate || this.arfangEventFilters.minDate,
      maxDate: filters.maxDate || this.arfangEventFilters.maxDate
    };
  }

  getPrognosisProperty(property: string, prognosis: string): string {
    for (const prog of FilterService.DEFAULT_PROGNOSIS) {
      if (prog.value === prognosis) {
        return prog[property];
      }
    }
  }

}
