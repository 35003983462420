<app-nav-bar activatedItem="eventsList"></app-nav-bar>

<div class="container page-container">
  <mat-card class="top-card" *ngIf="shotDetectionsFilters">
    <h4>This page displays the information of all the HFI* events of the trial event, and as each is processed by a
      single device (no triangulation). *At the current stage, the extraction of Y/N presence of UAV is not
      available.
    </h4>
    <div class="filters">
      <div class="date-filters">
        <label for="startDateTime">Start date time :</label>
        <p-calendar inputId="startDateTime"
                    dateFormat="dd.mm.yy"
                    [showTime]="true"
                    [hideOnDateTimeSelect]="false"
                    [readonlyInput]="true"
                    [defaultDate]="shotDetectionsFilters.minDateTime"
                    [showSeconds]="true"
                    [minDate]="shotDetectionsFilters.minDateTime"
                    [maxDate]="shotDetectionsFilters.maxDateTime"
                    [(ngModel)]="shotDetectionsFilters.selectedMinDateTime"
                    [showClear]="shotDetectionsFilters.selectedMinDateTime !== shotDetectionsFilters.minDateTime"
                    (onClear)="shotDetectionsFilters.selectedMinDateTime = shotDetectionsFilters.minDateTime"
                    (ngModelChange)="updateShotDetections()"
        ></p-calendar>
        <label for="endDateTime">End date time :</label>
        <p-calendar #endDateTime
                    inputId="endDateTime"
                    dateFormat="dd.mm.yy"
                    [showTime]="true"
                    [hideOnDateTimeSelect]="false"
                    [readonlyInput]="true"
                    [defaultDate]="shotDetectionsFilters.maxDateTime"
                    [showSeconds]="true"
                    [minDate]="shotDetectionsFilters.minDateTime"
                    [maxDate]="shotDetectionsFilters.maxDateTime"
                    [(ngModel)]="shotDetectionsFilters.selectedMaxDateTime"
                    [showClear]="shotDetectionsFilters.selectedMaxDateTime !== shotDetectionsFilters.maxDateTime"
                    (onClear)="shotDetectionsFilters.selectedMaxDateTime = shotDetectionsFilters.maxDateTime"
                    (ngModelChange)="updateShotDetections()"
        ></p-calendar>
      </div>
      <div class="filters-columns">
        <div *ngFor="let optionsGroup of shotDetectionsFilters.optionsGroups" class="checkboxs-filter">
          <div>
            <span>{{optionsGroup.name}} :</span>
            <a class="clear-options-link"
               href="javascript:void(0)"
               [style.visibility]="optionsGroup.isEmpty() ? 'hidden' : 'initial'"
               (click)="optionsGroup.clear(); updateShotDetections()"
            >Clear</a>
          </div>
          <div class="checkboxs">
            <p-checkbox *ngFor="let option of optionsGroup.options"
                        [(ngModel)]="option.selected"
                        [label]="option.value"
                        [binary]="true"
                        (onChange)="updateShotDetections()">
            </p-checkbox>
          </div>
        </div>
      </div>
      <div class="results-count">
        <strong>{{shotDetections?.length | appNumber:'.0-0'}} results :</strong>
        <br>
        P.O.O. : (TP={{tpPoo | appNumber:'.0-0'}} on {{shotDetections?.length | appNumber:'.0-0'}})
        <br>
        TRAJ : (TP={{tpTraj | appNumber:'.0-0'}} on {{shotDetections?.length | appNumber:'.0-0'}})
        <br>
        <br>
        <a href="javascript:void(0)" (click)="exportData()">Export</a>
        <br>
        <a href="javascript:void(0)" (click)="clearFilters()">Clear</a>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-table">
    <p-table [value]="shotDetections"
             [scrollable]="true"
             [virtualScroll]="true"
             [virtualScrollItemSize]="35"
             scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr>
          <th class="sensor">Sensor</th>
          <th class="vignette">Vignette</th>
          <th class="dateTime">Date time</th>
          <th class="shotType">Shot type</th>
          <th class="shooterAngle">P.O.O.<br>Detected</th>
          <th class="shooterAngleExpected">P.O.O.<br>Expected</th>
          <th class="shooterAngle">P.O.O.<br>Error</th>
          <th class="shooterToTargetAngle">TRAJ<br>Detected</th>
          <th class="shooterToTargetAngle">TRAJ<br>Expected</th>
          <th class="shooterToTargetAngle">TRAJ<br>Error</th>
          <th class="swMbInfo">SW / MB</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shotDetection let-index="rowIndex">
        <tr style="height:35px" (click)="rowClicked(index)">
          <td class="sensor">{{shotDetection.station}}</td>
          <td class="vignette">{{shotDetection.vignette}}</td>
          <td class="dateTime">{{shotDetection.shotInstant | appDateTime}}</td>
          <td class="shotType">{{shotDetection.shotType}}</td>
          <td class="shooterAngle">
            {{shotDetection.shooterAngle | appNumber:'.0-0'}}°
            {{shotDetection.shooterDistance | appNumber: '.0-0'}}m
          </td>
          <td class="shooterAngleExpected">
            {{shotDetection.expectedShooterAngle | appNumber:'.0-0'}}°
            {{shotDetection.expectedShooterDistance | appNumber: '.0-0'}}m
          </td>
          <td class="shooterAngle">
            {{shotDetection.errorShooterAngle | appNumber:'.0-0'}}°
            {{shotDetection.errorShooterDistance | appNumber:'.0-0'}}m
          </td>
          <td class="shooterToTargetAngle">
            {{shotDetection.shooterToTargetAngle | appNumber:'.0-0'}}°
          </td>
          <td class="shooterToTargetAngle">
            {{shotDetection.expectedShooterToTargetAngle | appNumber:'.0-0'}}°
          </td>
          <td class="shooterToTargetAngle">
            {{shotDetection.errorShooterToTargetAngle | appNumber:'.0-0'}}°
          </td>
          <td class="swMbInfo">{{shotDetection.swMbInfo}}</td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </mat-card>

</div>
