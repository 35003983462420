import { Injectable } from '@angular/core';
import {ArfangWsService} from './arfang-ws.service';
import {ArfangAccount, ArfangAccountCreate} from './account-model';

@Injectable({
  providedIn: 'root'
})
export class AccountWsService {

  private readonly ENDPOINT: string = 'account';

  constructor(private arfangWsService: ArfangWsService) { }

  getAccounts(): Promise<ArfangAccount[]> {
    return this.arfangWsService.get<ArfangAccount[]>(this.ENDPOINT, {});
  }

  create(account: ArfangAccountCreate): Promise<{id: number}> {
    return this.arfangWsService.post<{id: number}>(this.ENDPOINT, account);
  }
}
