<div class="container page-container">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-8 col-sm-9">
      <mat-card>
        <mat-card-title *ngIf="resetMode === 0">Create your password</mat-card-title>
        <mat-card-title *ngIf="resetMode === 1">Reset your password</mat-card-title>
        <form [formGroup]="resetForm" (ngSubmit)="submit()">
          <div class="container-fluid">
            <div class="row">
              <mat-form-field class="col-md">
                <input matInput type="password" formControlName="newPassword1"
                       [placeholder]="resetMode === 1 ? 'New password' : 'Password'">
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col-md">
                <input matInput type="password" formControlName="newPassword2" placeholder="Password confirmation">
              </mat-form-field>
            </div>
            <div class="row justify-content-end">
              <button mat-raised-button color="primary" type="submit"
                      [disabled]="resetForm.controls.newPassword1.value !== resetForm.controls.newPassword2.value">
                {{resetMode ? 'Reset password' : 'Activate account'}}
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
