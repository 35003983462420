import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {ScopeService} from '../scope.service';
import {SpectrogramData} from '../../websocket-service/packets/SpectrogramData';
import {ArfangStation} from '../../../../../common/stations/arfang-station';

@Component({
  selector: 'app-spectrograph',
  templateUrl: './spectrogram.component.html',
  styleUrls: ['./spectrogram.component.scss']
})
export class SpectrogramComponent implements OnInit, OnDestroy {

  @Input() station: ArfangStation;
  @Input() dataReceived: Observable<SpectrogramData>;

  private dataReceivedSubscription: Subscription;
  private activityTimerSubscription: Subscription;
  secondsSinceLastActivity = 0;
  graphConfig: { layout: any, data: any[], config: any };

  constructor(readonly scopeService: ScopeService) {
    this.activityTimerSubscription = interval(1000)
      .subscribe(() => this.activityTimer());
  }

  ngOnInit() {
    this.initGraph();
    this.dataReceivedSubscription = this.dataReceived.subscribe(data => this.refreshSpectrogram(data));
  }

  ngOnDestroy() {
    this.dataReceivedSubscription.unsubscribe();
    this.activityTimerSubscription.unsubscribe();
  }

  private activityTimer() {
    this.secondsSinceLastActivity++;
    if (this.secondsSinceLastActivity >= 10) {
      this.graphConfig.data[0].marker.color = 'rgba(141,141,141,0.53)';
    }
  }

  private initGraph() {
    this.graphConfig = {
      layout: {},
      data: [],
      config: this.scopeService.getPlotlyConfig()
    };
    this.graphConfig.layout = {
      showlegend: false,
      height: 150,
      margin: {l: 75, r: 20, b: 25, t: 35},
      xaxis: {
        range: [0, this.station.sampleRate / 2],
        autoscale: false,
        showticklabels: true
      },
      yaxis: {
        title: 'value [dB]',
        range: [-50, 30]
      },
      font: {size: 12}
    };
    this.graphConfig.data.push({
      x: this.scopeService.generateFrequencies(),
      y: [],
      type: 'scatter',
      mode: 'markers',
      marker: {
        cmin: -50,
        cmax: 30,
        colorscale: [
          [0.0,    '#00008f'],
          [0.2,    '#01019a'],
          [0.35,    '#0338ff'],
          [0.5,    '#00fff5'],
          [0.7,    '#ffff00'],
          [0.9,     '#ff0000'],
          [1.0,     '#ff0000']
        ]
      }
    });
  }

  private refreshSpectrogram(data: SpectrogramData): void {
    this.secondsSinceLastActivity = 0;
    this.graphConfig.layout.title = `${new Date(data.startTime)}`;
    this.graphConfig.data[0].y = data.values;
    this.graphConfig.data[0].marker.color = data.values;
  }

}
