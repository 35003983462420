import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ArfangEvent, ArfangEventVector} from '../../../common/events/arfang-event-model';
import {ScopeService} from '../../livescope/live-scope-session/scopes/scope.service';
import {MatLegacySlideToggleChange as MatSlideToggleChange} from '@angular/material/legacy-slide-toggle';
import {SpectrogramData} from '../../livescope/live-scope-session/websocket-service/packets/SpectrogramData';
import moment from 'moment';

@Component({
  selector: 'app-debug-tools',
  templateUrl: './debug-tools.component.html',
  styleUrls: ['./debug-tools.component.scss']
})
export class DebugToolsComponent implements OnInit, OnChanges {
  @Input() arfangEvent: ArfangEvent;

  displayedColumns = [
    'index',
    'time',
    'correlation',
    'azimuth',
    'sCoh3MeanLevel'];

  math = Math;
  private endOfTimeWindow: number;

  graphConfig: { layout: any, data: any[], config: any };
  spectroVisible = true;
  vectorsVisible = true;

  constructor(readonly scopeService: ScopeService) {
    this.initGraph();
  }

  ngOnInit() {
    this.endOfTimeWindow = this.arfangEvent.azimuthCorrelogramDatas[this.arfangEvent.azimuthCorrelogramDatas.length - 1].startTime;
    this.plotAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.endOfTimeWindow = this.arfangEvent.azimuthCorrelogramDatas[this.arfangEvent.azimuthCorrelogramDatas.length - 1].startTime;
    this.plotAll();
  }

  private plotAll(): void {
    this.clearGraph();
    this.arfangEvent.azimuthCorrelogramDatas.forEach(data => this.refresh(data));
    this.arfangEvent.event.vectors.forEach(vector => this.refresh(vector));
  }

  private clearGraph(): void {
    this.graphConfig.data.forEach(plot => {
      plot.x = [];
      plot.y = [];
    });
  }

  private initGraph() {
    this.graphConfig = {
      layout: {},
      data: [],
      config: this.scopeService.getPlotlyConfig()
    };
    const font = {
      size: 13
    };
    this.graphConfig.layout = {
      showlegend: false,
      showscale: false,
      height: 198,
      margin: {l: 60, r: 25, t: 10, b: 25, autoexpand: false},
      xaxis: {
        fixedrange: false, // true
        autorange: false, // true
        range: [ScopeService.WATERFALL_FRAMES_BUFFER - 1, 0],
        type: 'date',
        tickvals: [],
        ticktext: [],
        showticklabels: true
      },
      yaxis: {
        fixedrange: false, // true
        autorange: false, // true
        title: {
          text: `Direction of event<br>     in VLF Band`,
          font
        },
        range: [0, 180],
        tickvals: [0, 45, 90, 135, 180],
        ticktext: ['N', 'E', 'S', 'W', 'N'],
      },
      font
    };
    this.graphConfig.data.push({
      x: [],
      y: [],
      mode: 'markers',
      type: 'scatter',
      hoverinfo: 'text',
      marker: {line: {width: 1}, size: 1},
      text: [],
      showscale: false
    });
    this.graphConfig.data.push({
      x: [],
      y: [],
      mode: 'lines+markers',
      marker: {line: {width: 1}, size: 5},
      type: 'scatter',
      hoverinfo: 'text',
      text: [],
      showscale: false
    });
  }

  toggleSpectro(event: MatSlideToggleChange): void {
    this.spectroVisible = event.checked;
    this.plotAll();
  }

  toggleVectors(event: MatSlideToggleChange): void {
    this.vectorsVisible = event.checked;
    this.plotAll();
  }

  private refresh(data: ArfangEventVector | SpectrogramData) {
    const graphConfig = this.graphConfig;
    if ((data as SpectrogramData).startTime !== undefined) {
      if (!this.spectroVisible) {
        return;
      }
      data = data as SpectrogramData;
      const startTime = moment.utc(data.startTime).valueOf();
      const azimuth = data.values.indexOf(Math.max.apply(Math, data.values));
      const correlation = data.values[azimuth];
      this.graphConfig.data[0].x.push(startTime);
      this.graphConfig.data[0].y.push(data.values.indexOf(Math.max.apply(Math, data.values)));
      this.graphConfig.data[0].text.push(`
        Time: ${moment(startTime).format('YYYYMMDD-HH:mm:ss')}<br>
        Azimuth: ${azimuth}<br>
        Correlation: ${correlation}
     `);
    } else {
      if (!this.vectorsVisible) {
        return;
      }
      data = data as ArfangEventVector;
      const vectortime = moment.utc(data.date + 'T' + data.time).valueOf();
      this.graphConfig.data[1].x.push(vectortime);
      this.graphConfig.data[1].y.push(data.azimuth / 2);
      this.graphConfig.data[1].text.push(`
        Time: ${moment(vectortime).format('YYYYMMDD-HH:mm:ss')}<br>
        Azimuth: ${data.azimuth}<br>
        Correlation: ${data.correlation}
     `);
    }
    const xaxisRange = [this.endOfTimeWindow - ScopeService.FRAME_DURATION_MS * ScopeService.WATERFALL_FRAMES_BUFFER,
      this.endOfTimeWindow + ScopeService.FRAME_DURATION_MS];
    const tickInterval = 60;
    const xStart = Math.round(xaxisRange[0] / 1000);
    let ticksTimestamps: number[];
    if (xStart % tickInterval === 0) {
      ticksTimestamps = [
        xStart,
        xStart + tickInterval,
        xStart + tickInterval * 2,
        xStart + tickInterval * 3,
        xStart + tickInterval * 4,
        xStart + tickInterval * 5
      ];
    } else {
      const ticksStart = xStart + tickInterval - xStart % tickInterval;
      ticksTimestamps = [
        ticksStart,
        ticksStart + tickInterval,
        ticksStart + tickInterval * 2,
        ticksStart + tickInterval * 3,
        ticksStart + tickInterval * 4
      ];
    }
    graphConfig.layout.xaxis.range = xaxisRange.map(date => new Date(date));
    const ticks = ticksTimestamps
      .map(timestamp => new Date(timestamp * 1000));
    graphConfig.layout.xaxis.tickvals = ticks;
    graphConfig.layout.xaxis.ticktext = ticks.map(date => `${date.getHours().toString().padStart(2, '0')}:` +
      `${date.getMinutes().toString().padStart(2, '0')}:` +
      `${date.getSeconds().toString().padStart(2, '0')}`
    );
  }
}
