import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {debounceTime, tap} from 'rxjs/operators';
import {FilterService} from '../service/filter.service';
import {ArfangEventFilters} from '../filters/arfang-event-filters';
import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';
import {ArfangStation} from 'src/app/common/stations/arfang-station';
import {StationWsService} from 'src/app/services/arfang-ws/station-ws.service';
import {AlertService} from 'src/app/services/alert.service';

@Component({
  selector: 'app-properties-selector',
  templateUrl: './properties-selector.component.html',
  styleUrls: ['./properties-selector.component.scss']
})
export class PropertiesSelectorComponent implements OnInit {

  readonly FilterService: typeof FilterService = FilterService;

  @Output()
  filtersUpdated = new EventEmitter<ArfangEventFilters>();

  stations: ArfangStation[] = [];
  form?: UntypedFormGroup;
  filtersCandidate?: ArfangEventFilters;
  allSelected = {stations: false, prognosis: false, magnitude: false, sector: false};

  constructor(private formBuilder: UntypedFormBuilder,
              private stationWsService: StationWsService,
              private filterService: FilterService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private alertService: AlertService) {
  }

  static areValidEventFilters(params: ParamMap): boolean {
    return params !== null &&
      params.has('stations') && params.getAll('stations').every(station => station !== '') &&
      params.has('prognosis') && params.getAll('prognosis').every(prog => prog !== '') &&
      (params.has('magnitude') && params.getAll('magnitude').every(mag => mag !== '')) &&
      (params.has('sector') && params.getAll('sector').every(mag => mag !== ''));
  }

  private updateAllSelected(): void {
    this.allSelected = {
      stations: this.filtersCandidate.stations.length === this.stations.length,
      prognosis: this.filtersCandidate.prognosis.length === FilterService.DEFAULT_PROGNOSIS.length,
      magnitude: this.filtersCandidate.magnitude.length === FilterService.DEFAULT_MAGNITUDE.length,
      sector: this.filtersCandidate.sector.length === FilterService.DEFAULT_SECTORS.length,
    };
  }

  async ngOnInit(): Promise<void> {
    try {
      this.stations = await this.stationWsService.getStations();
    } catch (error) {
      this.alertService.error(error);
      return;
    }
    this.form = this.formBuilder.group({
      stations: [''],
      prognosis: ['', Validators.required],
      magnitude: ['', Validators.required],
      sector: ['', Validators.required],
    });
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    if (PropertiesSelectorComponent.areValidEventFilters(paramMap)) {
      const newFilters = {
        stations: paramMap.getAll('stations'),
        magnitude: paramMap.getAll('magnitude'),
        sector: paramMap.getAll('sector'),
        prognosis: paramMap.getAll('prognosis')
      };
      this.updateEventForm(newFilters);
    } else {
      this.updateEventForm(this.filterService.arfangEventFilters);
    }
    this.filtersCandidate = {
      stations: this.parseStations(this.form.controls.stations.value),
      prognosis: this.form.controls.prognosis.value,
      magnitude: this.form.controls.magnitude.value,
      sector: this.form.controls.sector.value,
    } as ArfangEventFilters;
    this.updateAllSelected();
    this.filtersUpdated.emit(this.filtersCandidate);
    this.form.valueChanges
      .pipe(
        tap(() => {
          if (this.form.valid) {
            this.filtersCandidate = {
              stations: this.parseStations(this.form.controls.stations.value),
              prognosis: this.form.controls.prognosis.value,
              magnitude: this.form.controls.magnitude.value,
              sector: this.form.controls.sector.value,
            } as ArfangEventFilters;
            this.updateAllSelected();
          }
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        if (this.form.valid && this.filtersCandidate !== undefined) {
          this.filtersUpdated.emit(this.filtersCandidate);
        }
      });
  }

  private updateEventForm(fields: ArfangEventFilters): void {
    this.form.patchValue({
      stations: fields.stations,
      prognosis: fields.prognosis,
      magnitude: fields.magnitude,
      sector: fields.sector,
    });
  }

  parseStations(stations: string | string[]): string[] {
    if (stations === '' || stations.length === 0) {
      return this.stations.map(station => station.id.toString());
    }
    return stations as string[];
  }

  onSelectAll(filter: string, matSelect: MatSelect): void {
    this.allSelected[filter] = !this.allSelected[filter];
    if (this.allSelected[filter]) {
      matSelect.options.forEach(item => item.select());
    } else {
      matSelect.options.forEach(item => item.deselect());
    }
  }

}
