import {Component, Input} from '@angular/core';
import {ArfangEventSummary} from '../../events/arfang-event-model';
import {FilterService} from '../../events-filter/service/filter.service';
import {SVGConfigs} from '../../../pages/list/events-table/SVG-configs';

@Component({
  selector: 'app-svg-sector',
  templateUrl: './svg-sector.component.html',
  styleUrls: ['./svg-sector.component.scss']
})
export class SvgSectorComponent {

  @Input() event: ArfangEventSummary;
  sectorConfig = SVGConfigs.sectorSmall;

  constructor(readonly filterService: FilterService) {
  }

  private polarToCartesian(centerX, centerY, radius, angleInDegrees): { x: number, y: number } {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  describeArc(startAngle, endAngle): string {
    const x = this.sectorConfig.width / 2 + 4;
    const y = this.sectorConfig.height / 2;
    const radius = this.sectorConfig.height / 2 - 5;

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    return [
      'M', start.x, start.y, 'L', this.sectorConfig.width / 2 + 4, this.sectorConfig.height / 2,
      'L', end.x, end.y,
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
  }

}
