import { Injectable } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ArfangWsError} from './arfang-ws/arfang-ws.service';
import {AlertDialogComponent} from '../common/alert-dialog/alert-dialog.component';
import {AlertData} from '../common/alert-dialog/alert-data';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private opened = false;

  constructor(private dialog: MatDialog) {
    AlertDialogComponent.closed.subscribe(() => this.closeDialog());
  }

  error(arfangWsError: ArfangWsError): void {
    if (!this.opened) {
      this.dialog.open(AlertDialogComponent, {data: {success: false, error: arfangWsError} as AlertData});
      this.opened = true;
    }
  }

  success(message: string): void {
    if (!this.opened) {
      this.dialog.open(AlertDialogComponent, {data: {success: true, message} as AlertData});
      this.opened = true;
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
    this.opened = false;
  }
}
