import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ArfangEventFilters} from './filters/arfang-event-filters';
import {FilterService} from './service/filter.service';
import {EventWsFilter} from 'src/app/common/events-filter/filters/event-ws-filter';

@Component({
  selector: 'app-arfang-events-filters',
  templateUrl: './arfang-events-filters.component.html',
  styleUrls: ['./arfang-events-filters.component.scss']
})
export class ArfangEventsFiltersComponent {

  @Output()
  eventsFilters = new EventEmitter<EventWsFilter>();

  private propertiesFilterReceived = false;
  private periodFilterReceived = false;

  constructor(private filterService: FilterService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  propertiesFilterUpdated(arfangEventFilters: ArfangEventFilters) {
    this.propertiesFilterReceived = true;
    this.filterUpdated(arfangEventFilters);
  }

  periodFilterUpdated(arfangEventFilters: ArfangEventFilters) {
    this.periodFilterReceived = true;
    this.filterUpdated(arfangEventFilters);
  }

  private filterUpdated(arfangEventFilters: ArfangEventFilters) {
    this.filterService.save(arfangEventFilters);
    if (this.propertiesFilterReceived && this.periodFilterReceived) {
      this.updateURLParams(this.filterService.arfangEventFilters);
      this.eventsFilters.emit(this.eventWsFilter(this.filterService.arfangEventFilters));
    }
  }

  private eventWsFilter(arfangEventFilters: ArfangEventFilters): EventWsFilter {
    return {
      stationsIds: arfangEventFilters.stations.join(','),
      prognosis: arfangEventFilters.prognosis.join(','),
      magnitudes: arfangEventFilters.magnitude.join(','),
      sector: arfangEventFilters.sector.join(','),
      minDate: arfangEventFilters.minDate,
      maxDate: arfangEventFilters.maxDate
    };
  }

  updateURLParams(arfangEventFilters: ArfangEventFilters): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: arfangEventFilters,
        queryParamsHandling: 'merge'
      });
  }
}
