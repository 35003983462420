import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-audio-reader',
  templateUrl: './audio-reader.component.html',
  styleUrls: ['./audio-reader.component.scss']
})
export class AudioReaderComponent implements AfterViewInit, OnChanges {

  @ViewChild('audio')
  audioDiv: ElementRef;

  @Input()
  audioUrlForDownload: string;

  @Input()
  audioUrlForPlay: string;

  @Input()
  audioFileName: string;

  waveSurfer?: WaveSurfer;

  ngAfterViewInit(): void {
    this.waveSurfer = WaveSurfer.create({
      container: this.audioDiv.nativeElement,
      normalize: false,
      height: 50,
      cursorColor: '#aaa',
      waveColor: '#aaa',
      progressColor: 'black',
      dragToSeek: true
    });
    this.loadAudio();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('audioUrlForPlay') && this.waveSurfer) {
      this.loadAudio();
    }
  }

  private async loadAudio(): Promise<void> {
    if (this.waveSurfer && this.audioUrlForPlay) {
      await this.waveSurfer.load(this.audioUrlForPlay);
      this.waveSurfer.setTime(0);
    }
  }

  download(): void {
    saveAs(this.audioUrlForDownload, this.audioFileName);
  }

}
