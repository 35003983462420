import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserWsService} from '../../services/arfang-ws/user-ws.service';
import {AlertService} from '../../services/alert.service';
import {AlertDialogComponent} from '../../common/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  resetMode: number;
  resetForm: UntypedFormGroup;
  token: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private userWsService: UserWsService,
              private alertService: AlertService,
              formBuilder: UntypedFormBuilder) {
    this.resetForm = formBuilder.group({
      newPassword1: ['', Validators.required],
      newPassword2: ['', Validators.required]
    });
    this.resetMode = +this.activatedRoute.snapshot.queryParamMap.get('resetMode');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (!this.token) {
      this.router.navigateByUrl('/');
    }
  }

  async submit(): Promise<void> {
    if ( this.resetForm.controls.newPassword1.value !== this.resetForm.controls.newPassword2.value || !this.resetForm.valid) {
      return;
    }
    await this.userWsService.createPassword({password: this.resetForm.controls.newPassword2.value}, this.token);
    AlertDialogComponent.closed.subscribe(() => this.router.navigate(['home']));
    this.alertService.success(`Successfuly ${this.resetMode ? 'resetted' : 'configured'} password !`);
  }

}
